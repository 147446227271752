.arrowbck svg {
    font-size: 15px;
}

.arrowbck {
    background: var(--clr-bcg4);
    border-radius: 50%;
    display: flex;
    padding: 8px;
    width: fit-content;
    margin: 15px 0;
}

.palylistimg {
    position: relative;
}

.palylistimg img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.palylistimg svg {
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.plsjist {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.pls-h {
    /* font-family: Inter; */
    font-size: 60px;
    font-weight: 700;
    line-height: 72.61px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.frstname {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.lib-ply {
    display: flex;
    padding: 2px;

}

.lib-ply img {
    width: 48px;
    height: 48px;
}

.lib-play {
    background: var(--clr-bcg6);
    padding: 3px 10px;
    margin: 10px;
    border-radius: 8px;
}

.name-li {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 5px;
    color: var(--clr-font);

}

.newfolder-svg {
    display: flex;
    margin: 3px;
    background: #303030;
    padding: 15px;
}

.edtdet {
    /* font-family: Inter; */
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.mdoel-inpu input,
.mdoel-text textarea {
    padding: 10px;
    color: var(--clr-font);
}

.mdoel-inpu .MuiFormControl-root {
    border: 1px solid var(--clr-font);
    border-radius: 6px;
    margin: 5px;
}

.mdoel-text .MuiFormControl-root {
    border: unset;
    background: var(--clr-bcg4);
}

/* .modal-style .MuiModal-backdrop {
    background-color: unset !important;
} */

.sve button {
    background: var(--clr-font) !important;
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    letter-spacing: 0.02em;
    text-align: right;
    color: var(--clr-bcg);
    border-radius: 25px;
    padding: 10px;
    text-transform: capitalize;
    margin: 10px 0px;
}

.sve {
    text-align: right;
}

.by-precee {
    /* font-family: Inter; */
    font-size: 10px;
    font-weight: 500;
    line-height: 15.1px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 5px;
}

.chsimg {
    position: absolute;
    left: 18%;
    display: none;
    top: 45%;
    padding: 10px;
    color: var(--clr-font);
    background: var(--clr-bcg);
}

.lbk:hover .chsimg {
    display: block !important;
}


.tablecont-playlist th,
.tablecont-playlist tr,
.tablecont-playlist td {
    border-bottom: unset !important;
    color: var(--clr-font);
    background: unset !important;

}

.tablecont-playlist :first-child td {
    padding: 12px;
}

.tablecont-playlist table {
    border: unset;
}

.tb-img img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.wecant {
    /* font-family: Inter; */
    font-size: 14.96px;
    font-weight: 600;
    line-height: 18.11px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.inge-nmae {
    /* font-family: Inter; */
    font-size: 13.09px;
    font-weight: 600;
    line-height: 15.84px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #A7A6A4;

}

.all-t-b button.Mui-selected {
    background: var(--clr-font);
    color: var(--clr-bcg);
    text-transform: capitalize;
    border-radius: 25px;
}

.all-t-b button {
    background: var(--clr-bcg4);
    color: var(--clr-font);
    text-transform: capitalize;
    border-radius: 25px;
    min-width: 50px;
    min-height: 30px;
}

.all-t-b .MuiTabs-indicator {
    background: unset;

}

.all-t-b .MuiTabs-flexContainer {
    gap: 10px;

}

/* .plybt {
    position: absolute;
    left: 30%;
    top: 33%;
    font-size: 30px;
}

.plybt-div {
    position: relative;
} */

.plybt,
.plybt-div {
    width: 15px;
    height: 15px;
    font-size: 15px !important;
}

.pad-24 {
    padding: 24px;
}

.cls {
    position: absolute;
    top: 15px;
    right: 15px;
}