.trackpage-bgimg-div img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
}

.trackpage-bgimg-div {
    position: relative;
}

.unfav {
    color: red !important;
    fill: red !important;
    opacity: 100% !important;
}

.repeat {
    color: #ea5aff !important;
    fill: #ea5aff !important;
    opacity: 100% !important;
}

.premium {
    color: #2081e2 !important;
    fill: #2081e2 !important;
    opacity: 100% !important;
    margin-left: 4px;
    font-size: 16px !important;
}

.premiums {
    color: #2081e2 !important;
    fill: #2081e2 !important;
    opacity: 100% !important;
    margin-left: 4px;
    font-size: 16px !important;
}

.Trackpage-title-div {
    /* height: 200px; */
    background-color: var(--clr-bcg);
    border-radius: 10px;
    padding: 15px;
}

.trackpage-grid {
    justify-content: center;
    position: relative;
    top: -250px;
    /* left: 140px; */

    /* margin: auto; */
}

/* .track-cover-img img {
    width: 280px;
    height: 300px;
} */


.track-avatar {
    /* text-align: center; */
    display: flex;
    justify-content: center
}

.Track-genre {
    /* font-family: "Avenir Next LT Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important; */
    color: var(--clr-font);
    padding: 15px 0px;
    font-size: 18px;
    opacity: 40%;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
}

.plays {
    margin-left: 0px !important;
    color: var(--clr-font);
    padding: 15px 0px;
    font-size: 18px;
    opacity: 40%;
    /* text-transform: uppercase; */
    /* letter-spacing: 3px; */
    /* font-weight: 700;   */
}


.Track-title {
    /* font-family: "Avenir Next LT Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important; */
    color: var(--clr-font);
    /* padding: 10px 0px; */
    font-size: 35px;
    opacity: 80%;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
}

.track-artist span {
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: var(--clr-font);
    padding: 15px 0px;
    font-size: 18px;
    opacity: 40%;
    margin-right: 5px;
    text-transform: capitalize;
    /* letter-spacing: 3px; */
    font-weight: 700;
}


.track-artist {
    /* font-family: "Avenir Next LT Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important; */
    color: var(--clr-music-track);
    /* padding: 15px 0px; */
    font-size: 18px;
    /* opacity: 40%; */
    text-transform: lowercase;
    /* letter-spacing: 3px; */
    font-weight: 400;
    cursor: pointer;
}

.play-btn-trackpage button,
.play-btn-trackpage button:hover {
    padding: 5px 19px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background: var(--clr-music-track);
    border-radius: 15px;
}

.play-btn-trackpage button svg {
    background-color: transparent !important;
    font-size: 25px !important;
    color: #fff !important;
}

.play-btn-trackpage {
    padding: 15px 0px;
}

.play-btn-trackpage span {
    color: var(--clr-font);
    font-size: 18px;
    margin-left: 15px;
}


.trackspage-tags span {
    /* margin-left: 0px !important; */
    color: var(--clr-font);
    padding: 15px 0px;
    font-size: 18px;
    opacity: 40%;
}


.trackspage-tags {
    display: flex;
    gap: 30px;
    color: var(--clr-font);
    /* margin: 30px 0px; */
    justify-content: center;
}

.follow-us-div {
    display: flex;
    gap: 30px;
    color: var(--clr-font);
    margin: 30px 0px;
    justify-content: center;
    align-items: center;
}



.trackspage-tags div svg,
.follow-us-div svg {
    /* font-size: 16px !important; */
    fill: var(--clr-font);
    opacity: 40%;
    background: transparent !important;
}

.trackspage-tags div:hover span,
.trackspage-tags div:hover svg {
    color: var(--clr-music-track) !important;
    fill: var(--clr-music-track) !important;
    cursor: pointer;
    opacity: 100%;

}

.follow-us-div div {
    padding: 5px 18px;
    border: 1px solid var(--clr-font);
    opacity: 70%;
    cursor: pointer;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.follow-us-div div:hover,
.follow-us-div div:hover svg {
    background-color: var(--clr-music-track);
    fill: #fff !important;
    color: #fff;
    opacity: 100%;
    border: unset !important;
}

.discription-trackpage {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 20px 30px;
    flex-flow: wrap;
    justify-content: center;
}

.discription-trackpage div {
    color: var(--clr-font);
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    opacity: 60%;
}

.discription-trackpage div span {
    color: var(--clr-font) !important;
    opacity: 100%;
    margin-left: 15px;
    /* color: #000 !important; */
    font-weight: 400 !important;
    text-transform: capitalize;
}

.track-title-content-div {
    text-align: center;
}