.faq-platform {
    text-align: center;
    margin-bottom: 20px;
}

.faq-platform {
    padding-top: 100px;
    color: var(--clr-upload-font);
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 10px;
}

.fanzone-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}