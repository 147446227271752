.discommui {
    /* font-family: Inter; */
    font-size: 64px;
    font-weight: 700;
    line-height: 67.5px;
    text-align: center;
    color: var(--clr-font);
    margin: 15px 0px;
}

.bckban1 img {
    width: 100%;
    height: 295px;
}

.bckban1 {
    margin: 20px 0px;
}

.createorn button {
    background: linear-gradient(90deg, #484CC7 0%, #863FD5 100%);
    padding: 10px 20px;
    text-transform: capitalize;
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 600;
    /* line-height: 45px; */
    letter-spacing: 0.75px;
    color: #fff;
    text-align: center;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
}

.createorn {
    text-align: center;
}

.bcknewTab button.MuiButtonBase-root {
    /* background: var(--clr-font); */

    background: #6C6C6C;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    border-radius: 25px;
    text-transform: capitalize;
    border: 1px solid #FC5AFF;

    /* border-image-source: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%); */


}

.bcknewTab button.MuiButtonBase-root.Mui-selected {
    background: var(--clr-font);
    border: unset;
    color: var(--clr-bcg);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    border-radius: 25px;
}

.bcknewTab .MuiTabs-indicator {
    background-color: unset !important;
    /* font-family: Inter; */


}

.bcknewTab .MuiTabs-flexContainer {
    gap: 10px;
}

.bck-n-card {

    background-color: var(--clr-explore-bg) !important;
    border-radius: 12px !important;

}

.display-4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 25px;

}

.display-1.mini img {
    width: 30px;
    border-radius: 8px;
    height: 30px;
}

.mini-text {
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: left;
    color: var(--clr-font);

}

.mini-text2 {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24.6px;
    padding: 15px 0;
    text-align: left;
    color: var(--clr-font);
}

.mini-text3 {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
}



.bckpassnew,
.bckinner1,
.BckInner2 {
    background: var(--clr-bcg7)
}

.display-1.mini {
    padding: 10px 0px;
}

.bckpage .MuiPagination-ul {
    justify-content: center;
}

.bckpage button.Mui-selected {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);

}


.bckpage button,
.bckpage li div {
    color: var(--clr-font);
}

.bckpage {
    padding: 20px 0px;
}

.answer-banner {
    background: var(--clr-bcg);
    box-shadow: 12px 11px 37.5px 0px #00000033;
    border-radius: 12px;
    margin: 12px;
    text-align: center;
    padding: 10px;
}

.answer-banner img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 25px 0px;
}

.realmen-h {
    /* font-family: Inter; */
    font-size: 26.55px;
    font-weight: 700;
    line-height: 39.83px;
    text-align: center;
    color: #fff;
}

.realmen-d {
    /* font-family: Inter; */
    font-size: 16.16px;
    font-weight: 700;
    line-height: 24.24px;
    text-align: center;
    margin: 5px 0px;
    color: #909090;

}

.ask1 {
    /* font-family: Inter; */
    color: var(--clr-font);
    font-size: 18.47px;
    font-weight: 400;
    line-height: 27.71px;
    text-align: left;
    /* margin: 10px 0px; */
    padding: 10px;
}

.ask-inp {
    /* margin: 10px 0px; */
    padding: 0px 10px;
}


.ask-inp .MuiFormControl-root {
    border: 1px solid #FC5AFF;
    border-radius: 12px;
    width: 100%;
    /* padding-top: 5px; */
}

.ask-inp input,
.numver20 {
    color: var(--clr-font);
}

.joinask button {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-top: 25px;
}

.joinask {
    margin: 0 10px;
}

.mmcv {
    width: 200px !important;
}

.bck-inner-mian {
    background: var(--clr-bck-inner);
    box-shadow: -6px 4px 100px 0px #000000CC;
    padding: 15px;
    border-radius: 12px;
    margin: 10px;
}

.clickyell {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;

}

.hostb {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.thicomm {
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.bck-inner-mian-img img {
    width: 100%;
    height: 150px;
}

.rmr {
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.skllink {
    /* font-family: Inter; */
    font-size: 13px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    color: var(--clr-font);
}

.bckprf {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.otrlink {
    color: var(--clr-font);
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.adm {
    color: #909090;
    font-size: 13px !important;
}

.stck-n-card {
    /* width: 300px; */
    /* height: 250px; */
    border-style: solid;
    border-width: 1px 0px 1px 0px;
    background-color: var(--clr-explore-bg) !important;
    /* border-radius: 15px; */
    border-image: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) 1 stretch;
    margin: 10px;
    /* background-clip: padding-box; */
    justify-content: space-evenly;
}

.stck-n-card2 {
    /* width: 300px; */
    /* height: 250px; */
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    background-color: var(--clr-explore-bg) !important;
    /* border-radius: 15px; */
    border-image: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) 1 stretch;
    margin: 10px;
    /* background-clip: padding-box; */
    justify-content: space-between;
}

.stck-n-card2 .rmr {
    padding: 10px;
}

.divborder {
    background: #FC5AFF;
    height: 45px !important;
    margin: auto 5px !important;
}

.joinmember button {
    /* font-family: Inter; */
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    padding: 15px;

}

.cancel-mem {
    /* font-family: Inter; */
    font-size: 13px;
    font-weight: 400;
    margin: 10px auto;
    line-height: 19.5px;
    text-align: center;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    width: fit-content;
    /* border-radius: 15px; */
    border-image: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) 1 stretch;
}

.bck-inner-mian-img-prof img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.p-rmr {
    /* font-family: Inter; */
    font-size: 26.55px;
    font-weight: 700;
    line-height: 39.83px;
    text-align: center;
    color: var(--clr-font);
    margin: 15px 0px;
}

.p-skllink {
    /* font-family: Inter; */
    font-size: 16.16px;
    font-weight: 700;
    line-height: 24.24px;
    text-align: center;
    color: var(--clr-font);
    margin: 10px 0px;
    color: #909090;

}

.p-bckprf {
    /* font-family: Inter; */
    font-size: 18.47px;
    font-weight: 400;
    line-height: 27.71px;
    text-align: center;
    color: var(--clr-font);
    max-width: 80%;
    margin: auto;
}

.stck-n-prf {
    justify-content: center;
    margin-top: 30px;
}

.prf-dck img {
    width: 70px;
    height: 70px;
}

.dck-relmen {
    /* font-family: Inter; */
    font-size: 20.78px;
    font-weight: 400;
    line-height: 31.17px;
    text-align: left;
    color: var(--clr-font);
    margin: 10px 0;
}

.dckdc {
    /* font-family: Inter; */
    font-size: 18.47px;
    font-weight: 400;
    line-height: 27.71px;
    text-align: left;
    color: #909090;

    margin: 10px 0;
}

.viewn button,
.publish-bac button {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 26px;
    padding: 10px 20px;
    color: #fff;
}

.dcl-main {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    width: fit-content;
    /* border-radius: 15px; */
    border-image: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) 1 stretch;
    margin-bottom: 15px;
}

.dck-jk {
    /* font-family: Inter; */
    font-size: 16.78px;
    font-weight: 400;
    line-height: 31.17px;
    text-align: left;
    color: var(--clr-font);
    margin: 10px 0;
}

.prf-dck-2 img {
    width: 45px;
    height: 45px;
    border-radius: 8px;
}

.dck-relmen-2 {
    /* font-family: Inter; */
    font-size: 14.01px;
    font-weight: 700;
    line-height: 22.51px;
    text-align: left;
    color: var(--clr-font);
    margin: 10px 0;
}

.dckdc-2 {
    /* font-family: Inter; */
    font-size: 15.01px;
    font-weight: 400;
    line-height: 22.51px;
    text-align: left;
    color: #909090;
}


.pwby-d {
    /* font-family: Inter; */
    font-size: 15.01px;
    font-weight: 400;
    line-height: 22.51px;
    text-align: left;
    justify-content: flex-end;
    color: #909090;
}

.tg {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    padding: 12px;
    border-radius: 10px;
}

.tsetgrp {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);

}

.bck-tab button.Mui-selected {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 600;
    /* line-height: 78.31px; */
    text-align: center;

}

.bck-tab button {
    color: var(--clr-font);
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 600;
    /* line-height: 78.31px; */
    text-align: center;

}

.bck-tab .MuiTabs-indicator {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
}

.layerdiv {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 12px;
    padding: 1px;
    /* margin: 20px auto; */
    margin-top: 30px;
}

.commentinput input {
    color: var(--clr-font);
}

.commentinput .MuiFormControl-root,
.commentinput {
    width: 100%;
}


.comment {
    /* margin: 5px; */
    background: var(--clr-bcg);
    border-radius: 12px;
    padding: 10px
}

.innertab-com {
    margin-top: 20px;
    padding: 15px 0;
}

/* 
.bcknewTab.cmd button {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 25px;
    padding: 1px;
    border: unset;
}

.bcknewTab.cmd button 

.tab-bor {
    background: var(--clr-bcg);
} */

.startofspec {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin-top: 15px;
    padding: 15px 0;
}


.bckcmt-img img {
    width: 100%;
    height: 250px;
}

.uplaodNullImage {
    background: linear-gradient(274.4deg, rgb(23 90 255 / 33%) 2.38%, rgb(252 90 255 / 26%) 97.31%);
    text-transform: capitalize;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
}

.tg-mian {
    padding: 15px;
    margin-top: 20px;
}

.cmt-rit {
    text-align: left;
    padding: 15px 0;
}

.setting {
    text-align: center;
}

.setting button {
    background: linear-gradient(90deg, #484CC7 0%, #863FD5 100%);
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.75px;
    text-align: center;
    color: #fff !important;
    width: fit-content;
    margin: 25px auto;
    padding: 0px 40px;
    border: 1px solid #FFFFFF
}

.bck-tab2 button.Mui-selected,
.change-btn label {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    color: #fff;
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    border-radius: 6px;
    text-transform: capitalize;
    border: unset;
}

.change-btn label {
    width: -webkit-fill-available;
}

.bck-tab2 .MuiTabs-indicator {
    background: unset;
}

.bck-tab2 button {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    border-radius: 6px;
    text-transform: capitalize;
    border: 1px solid #FC5AFF;
    /* border-style: solid;
    border-image: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%); */
}

/* .bck-tab2 .MuiTabs-flexContainer {} */

.bck-tab2 .MuiTabs-flexContainer,
.bck-tab .MuiTabs-flexContainer {
    justify-content: flex-end;
    gap: 15px;
}

.dashboard-head {
    /* font-family: Inter; */
    font-size: 35.94px;
    font-weight: 700;
    line-height: 37.91px;
    text-align: left;
    margin-top: 20px;
    padding: 15px 0;
    color: var(--clr-font);

}

.dashboard-head span {
    /* font-family: Inter; */
    font-size: 35.94px;
    font-weight: 400;
    line-height: 37.91px;
    text-align: left;
    color: var(--clr-font);
}

.dsh-statu {
    background: var(--clr-bcg);
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    height: 100%;
    padding: 15px;
    width: 320px;
    height: 280px;
}

.dsh-statu img {
    width: 100px;
    height: 100px;
}

.dsh-num {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 35.94px;
    font-weight: 700;
    line-height: 37.91px;
    text-align: left;

}

.Pai-members {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 700;
    line-height: 37.91px;
    text-align: left;
    color: var(--clr-font);
}

/* .layerdiv {} */

.creatchat button {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
    text-transform: capitalize;
    background: #414141ea !important;
    padding: 15px;
    border-radius: 15px;
    margin: 25px 0px;
}

.comchat {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B9B9B9;
    padding: 15px;
    margin-top: 15px;
    padding-left: 0;
}

.up1 img,
.up2 img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}


.up1 {
    background: #414141ea !important;
    width: 128px;
    height: 128px;
    border-radius: 8px;
}

.up2 {
    background: #414141ea !important;
    width: 268px !important;
    /* 368 */
    height: 200px !important;
    border-radius: 8px;
}

.Icon {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.reso {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF80;
    margin: 10px 0px;
}

.border-lye {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 6px;
    padding: 1px;
    width: fit-content;
    margin: 25px auto;
}

.border-lye button {
    background: var(--clr-bcg);
    padding: 10px 20px;
    border-radius: 6px;
    color: var(--clr-font);
    width: 200px;
}

.border-lye button:hover {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
}

.border-lye button:hover .border-lye {
    background: transparent !important;
}

.pricedrp {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: centeri;
    color: var(--clr-font);
    margin: 15px 0px;
}

.setval button {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    padding: 20px 50px;
    border-radius: 10px;
    margin-top: 15px;
    width: 100%;
}

.comment2 {
    background: #111;
    border-radius: 12px;
    padding: 0px;
}

.commentinput .MuiInputBase-root {
    color: var(--clr-font) !important;
}

.commentinput2 {
    background: var(--clr-bcg3);
    padding: 12px;
    border-radius: 12px;
}

.cmt2 input,
.cmt2 textarea {
    color: var(--clr-font);
    padding: 10px;
}

.cmt2 .MuiInputBase-root {
    padding: 0px;
}

.cmt2,
.cmt2 .MuiFormControl-root {
    width: 100%;
}

.upgrae {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin: 15px;
}

.upgrae span {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text !important;
    margin-left: 5px;
    -webkit-text-fill-color: transparent;
}

.pvt-tx {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.pvt-des {
    /* font-family: Inter; */
    font-size: 10px;
    font-weight: 500;
    line-height: 18.5px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.pvt {
    background: var(--clr-bcg4);
    border-radius: 12px;
    padding: 15px;
    width: fit-content;
    /* margin: 10px; */
    height: 200px;
    width: 153px;
    cursor: pointer;
}

.layerdiv2 {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 12px;
    padding: 1px;
    /* margin-top: 20px; */
}

.pbu-radio [data-testid="RadioButtonUncheckedIcon"] {
    fill: #cc0fe0 !important;
}

.pbu-radio [data-testid="RadioButtonCheckedIcon"] {
    fill: #b83ec5 !important;
}

.pvt-radio [data-testid="RadioButtonUncheckedIcon"] {
    fill: #68256e !important;
}

.pvt-radio [data-testid="RadioButtonCheckedIcon"] {
    fill: #b42a96 !important;
}

.pvt-tx.pub {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text !important;
    /* margin-left: 5px; */
    -webkit-text-fill-color: transparent;
}

.subsc {
    /* font-family: Inter; */
    font-size: 35.94px;
    font-weight: 700;
    line-height: 37.91px;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.subsc-p {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0px;
}

.crrprice button {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    background: #00C255 !important;
    padding: 10px;
}

.crrprice2 button {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    background: #175AFF !important;
    padding: 10px;
}

.rmve button {
    background: #FF4728 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    padding: 10px;
}

.adpr {
    margin-top: 0px !important;
}

.adpr button {
    height: 60px !important;
    border-radius: 10px !important;
    text-transform: uppercase;
    font-weight: 600;
}

.tses-dc {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF80;
    margin: 5px 0;
}

.askmem {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.q1 {
    /* font-family: Inter; */
    font-size: 23.62px;
    font-weight: 700;
    line-height: 28.58px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.add-qurst {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    padding: 25px 15px;
    border-radius: 10px;
    margin: 10px 0px;
    border: 2.5px dashed #ffffff7c;
}

.textgrp {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin: 20px 0;
}

.test-vido {
    background: var(--clr-bcg5);
    width: 100%;
    height: 400px;
    border-radius: 12px;
    margin: 15px 0;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-font);

}

.abt-stk-text {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--clr-font);
}

.lb-name {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 35.943px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.908px;
    /* 105.469% */
}

.artist-bg {
    border-radius: 6px;
    background: linear-gradient(94deg, #B5179E -13.04%, #7209B7 124.22%);
    color: #FFF;
    /* font-family: Inter; */
    width: fit-content;
    font-size: 16px;
    margin-top: 5px;
    padding: 5px 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: capitalize;
}

.lvl-txt,
.lvl {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: capitalize;
}

.lvl-mem {
    color: #9A9A9A;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}

.lvl-main {
    gap: 15px;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
}

.lvl-layer {
    /* width: fit-content; */
    border-radius: 8px;
    /* margin: 5px; */
    display: flex;
    flex-wrap: wrap;
}

.lvl {
    padding: 10px 15px;
}

.lvl-layer:hover .lvl {
    border-radius: 6px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    padding: 10px 15px;
}

.points-toup {
    color: #B8B8B8;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: capitalize;
}

/* .rund-cls {} */

.reco {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: capitalize;
    text-align: center;
}

.bckpasscreate .MuiTabScrollButton-horizontal.MuiTabs-scrollButtons {
    width: fit-content;
    padding: 5px;
}

.sct-div.margin-top .MuiStack-root {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.display-2.flex-c .MuiStack-root {
    margin-left: 18px;
}

.dashboard-body .display-1.flex-c {
    gap: 30px;
}

.General .upload-div {
    gap: 39px;
}

.up1 p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-transform: capitalize;
}

.up1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-flex {
    gap: 25px;
    justify-content: flex-start;
}

.group-inputs {
    width: 89%;
}

.pvt .display-1 .PrivateSwitchBase-root.MuiRadio-root {
    padding: 0 !important;
}

/* .margin-top.display-1 {
    justify-content: center;
} */