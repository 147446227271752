.explore .explore-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 33.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding: 20px;
    /* 136.867% */
    color: #7227e4 !important;
    background-color: var(--clr-explore-head);

}


.explore-box {
    background: var(--clr-explore-bg) !important;
}


.explore-content-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 27.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding-top: 10px;
    /* 136.867% */
    color: #7227e4 !important;

}

.explore-box2 .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);

}

.explore-box2 .MuiGrid-root {
    padding: 10px;
    margin: 25px 0px;
    align-items: center !important;
    justify-content: center !important;
}

.description-explore {
    color: #858199;
    font-size: 14px;
    padding: 10px;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}


.explore-card1 {
    background-color: #13C65A !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card2 {
    background-color: #9ca0fe !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}


.explore-card3 {
    background-color: #e171e3 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card4 {
    background-color: #8854c7 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card5 {
    background-color: #1cd9b8 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card6 {
    background-color: #d6437b !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card7 {
    background-color: #ff7633 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card8 {
    background-color: #e700cb !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card9 {
    background-color: #c82cf7 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card10 {
    background-color: #4e66ff !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}

.explore-card11 {
    background-color: #36de89 !important;
    justify-content: space-between;
    padding: 10px;
    transition: all .18s ease-in-out !important;
}


.explore-card1:hover,
.explore-card2:hover,
.explore-card3:hover,
.explore-card4:hover,
.explore-card5:hover,
.explore-card6:hover,
.explore-card7:hover,
.explore-card8:hover,
.explore-card9:hover,
.explore-card10:hover,
.explore-card11:hover,
.playlist-card1:hover,
.playlist-card2:hover,
.playlist-card3:hover,
.playlist-card4:hover,
.playlist-card5:hover,
.love-playlist:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}

/* .explore-card3, .explore-card5, .explore-card7, .explore-card9, .explore-card11{
    width: calc(33.33% - 16px);
    
}
.explore-card2, .explore-card4, .explore-card6, .explore-card8, .explore-card10{
    width: calc(120.66% - 10px);
} */
.playlist-card1 {
    background-color: #6fbaff !important;
    transition: all .18s ease-in-out !important;
}

.playlist-card2 {
    background-color: #6568ff !important;
    transition: all .18s ease-in-out !important;
}

.playlist-card3 {
    background-color: #f9b776 !important;
    transition: all .18s ease-in-out !important;
}

.playlist-card4 {
    background-color: #23b8a8 !important;
    transition: all .18s ease-in-out !important;
}

.playlist-card5 {
    background-color: #e360ea !important;
    transition: all .18s ease-in-out !important;
}

.content-heading {
    color: #282828;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 23.867px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    /* 134.075% */
    letter-spacing: 0.33px;
    text-transform: uppercase;
}

.content-para {
    color: #282828;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 12.625px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    /* 182.178% */
    letter-spacing: 0.2px;
}

.playlist-text {
    color: #282828;
    text-align: center;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.20);
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 300% */
    letter-spacing: 0.25px;
}

.emoji-div {
    text-align: center;
}

.love-playlist {
    background-color: var(--clr-explore-head) !important;
    color: var(--clr-track-time) !important;
    text-align: center;
    transition: all .18s ease-in-out !important;
}

.love-display {
    display: flex;
    justify-content: space-evenly;
}


.love-display img {
    padding-right: 5px;
}


.explore-more-btn {
    display: flex;
    justify-content: center;
}


.explore-more-btn button {
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #CC0FE0;
    color: #fff;
}

.explore-more-btn button:hover {
    background: #CC0FE0 !important;
    color: #fff;
    box-shadow: 0px 0px 7px #7c859a;
}


.cirle-img img {
    clip-path: circle(45% at 50% 50%) !important;
    width: 100%;
    height: 240px;
}

.heading-container {
    background-color: var(--clr-explore-head);
    position: sticky;
    top: 58px;
    z-index: 999;

}

.love-playlist.explore {
    display: block !important;
}