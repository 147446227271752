hr.divider {
    border: 1px solid;
    border-image-source: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    border-image-slice: 1;
    width: 85%;
    margin: auto;
}

.discover {
    /* font-family: Inter; */
    font-size: 19px;
    font-weight: 600;
    line-height: 22.99px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
    padding: 10px 15px;
}


.newsidetext {
    margin: 25px 20px !important;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
}

.toolbar-new {
    background-color: var(--clr-bcgnew) !important;
    justify-content: space-between;
}

.new-side {
    padding: 0px 25px;
}

.btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #B8B8B8;
    /* border: 3px solid rgb(252, 70, 100); */
    cursor: pointer;
    position: relative;
    background-color: transparent;
    text-decoration: none;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    font-family: inherit;
    /* transition: transform 0.3s ease-in-out; */

}

.btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    transform: translateX(-100%);

    transition: all .6s;
    z-index: -1;
}

.btn:hover::before {
    transform: translateX(0%);
    /* transform: scale(1.1); */
    padding-left: 30px;
    /* color: var(--clr-font);
    font-weight: 900 !important; */
}

.btn:hover,
.newsideactive .btn {
    color: #fff;
    font-size: 17px;
    font-weight: 900 !important;

}

.btn:hover svg path,
.newsideactive svg path {
    stroke: #fff;

}

.btn:hover svg,
.newsideactive svg {
    width: 20px;
    height: 22px;
}

.btn:hover .ava-grp {
    /* background: inherit !important; */
    border-radius: 50%;
}

.group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    /* margin-top: 20px; */
    /* max-width: 190px; */
}

.input2 {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    height: 45px !important;
    padding-left: 2.5rem;
    box-shadow: 0 0 0 1.5px #2b2c37, 0 0 25px -17px #000;
    border: 0;
    border-radius: 36px;
    background: #414141A6;

    outline: none;
    color: #bdbecb !important;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: text;
    z-index: 0;
}

.input2::placeholder {
    color: #bdbecb;
}

.input2:hover {
    box-shadow: 0 0 0 2.5px #2f303d, 0px 0px 25px -15px #000;
}

.input2:active {
    transform: scale(0.95);
}

.input2:focus {
    box-shadow: 0 0 0 2.5px #2f303d;
}

.search-icon {
    position: absolute;
    left: 1rem;
    fill: #bdbecb;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    z-index: 1;
}

.setting-btn {
    width: 40px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: rgb(129, 110, 216);
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 0px 2px rgb(212, 209, 255);
}

.bar {
    width: 50%;
    height: 2px;
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2px;
}

.bar::before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: rgb(126, 117, 255);
    position: absolute;
    border-radius: 50%;
    border: 2px solid white;
    transition: all 0.3s;
    box-shadow: 0px 0px 5px white;
}

.bar1::before {
    transform: translateX(-4px);
}

.bar2::before {
    transform: translateX(4px);
}

.setting-btn:hover .bar1::before {
    transform: translateX(4px);
}

.setting-btn:hover .bar2::before {
    transform: translateX(-4px);
}



.topartist-recent {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
    padding: 15px 0px;
    margin-top: 15px;
    text-transform: capitalize;
}

.recente-head-div {
    padding: 15px;
    flex-direction: row !important;
    /* width: 300px; */
}

.recent-head-artist {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 8px 0px;
    width: max-content;
    color: var(--clr-font);
}

.recent-head-domain {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.newsideactive .newsidetext {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%) !important;
}

/* .sidebarnew .MuiDrawer-paper {
    height: 90vh;
} */

.logins button {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%) !important;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 25px;
    color: #fff;
    border-radius: 10px;
    font-weight: 700;
}