.albmc-img img {
    width: 200px;
    height: 200px;
}

.melod {
    /* font-family: Inter; */
    font-size: 22.84px;
    font-weight: 600;
    line-height: 27.64px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--clr-font);
}

.dser {
    /* font-family: Inter; */
    font-size: 19.99px;
    font-weight: 600;
    line-height: 24.19px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #767676;
}

.n-song {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--clr-font);

}

.ablmcov {
    background: var(--clr-bcg8);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 18.7px 65.46px 0px var(--clr-bcg3);

}

.albPlu {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    border-radius: 50%;
    padding: 15px;
    display: flex;
}

.svgsz svg {
    font-size: 35px;
}

.concet-tex {
    /* font-family: Inter; */
    font-size: 18.7px;
    margin: 15px 0px;
    font-weight: 600;
    line-height: 22.63px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.cont-mn {
    background: var(--clr-bcg8);
    width: 350px;
    border-radius: 8px;
}

.conet img {
    width: 105px;
    height: 105px;
}

.ontour {
    /* font-family: Inter;  */
    font-size: 11.22px;
    font-weight: 600;
    line-height: 13.58px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 5px 0;
    color: #AFADA4;

}

.ontour-tx {
    /* font-family: Inter; */
    font-size: 18.7px;
    font-weight: 600;
    line-height: 22.63px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin: 10px 0;
}

.seeall button {
    /* font-family: Inter; */
    font-size: 11.22px;
    font-weight: 600;
    line-height: 13.58px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    color: #000;
    background: #fff;
    border-radius: 25px;
    padding: 15px 25px;
    margin: 5px 0;
}

tr.alb:hover {
    background: linear-gradient(274.4deg, rgba(23, 90, 255, 0.5) 2.38%, rgba(252, 90, 255, 0.5) 97.31%) !important;
}

tr.alb td:first-child {
    border-radius: 10px 0 0 10px;
}

tr.alb td:last-child {
    border-radius: 0 10px 10px 0;
}

.mnt button {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    /* font-family: Inter; */
    font-size: 10.78px;
    font-weight: 500;
    padding: 8px 20px;
    line-height: 13.04px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
}