.feed .feed h2 {
    text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20);
    font-size: 32.906px;
    font-style: normal;
    font-weight: 900;
    background: linear-gradient(296deg, #5B23E1 0%, #A22FEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.feeds{
display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.feeds h2 {
    text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20);
    font-size: 32.906px;
    font-style: normal;
    font-weight: 900;
    background: linear-gradient(296deg, #5B23E1 0%, #A22FEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.back-svg-div{
    margin: 20px;
}

.feed .feed-head {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    background-color: var(--clr-explore-head);
    /* position: fixed;
    width: 83%; */
    /* z-index: 999; */
}

.feed-tab .active-feed.Mui-selected {
    border-radius: 8px;
    border: 1px solid #7E1BCC;
    background: #7E1BCC;
    color: #fff;
    font-weight: 600;
    border: none;
}

.feed-tab .MuiTabs-indicator {
    background: none !important;
}

.feed-tab .active-feed {
    min-height: 0 !important;
    border-radius: 8px;
    border: 1px solid #C2C0CC;
    margin-left: 15px;
    padding: 5px 10px;
    color: #AAA7B8;
    font-weight: 600;
}

.feed .MuiContainer-maxWidthMd {
    max-width: 1014px;
}

.feed .feed-bg {
    background: var(--clr-explore-bg) !important;
}

.feed .music-detail {
    display: flex;
    gap: 20px;
}

.feed .album-detail div {
    color: #858199;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 5px;
    cursor: pointer;
}

.feed .link-badge a {
    font-weight: 500;
}

.feed .album-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feed .link-badge {
    display: flex;
    gap: 10px;
}

.feed .link-badge img {
    width: 20px;
}

.feed .loop-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

.feed .MuiSvgIcon-fontSizeMedium {
    font-size: 15px;
}

.feed .repost-loop p {
    font-weight: 500 !important;
    color: #C2C0CC;
}

.feed .share-btn {
    color: #c2c0cc;
    display: flex;
    gap: 25px;
}

.feed .share-btn .MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
}

.feed .feed-music-box {
    border-radius: 8px;
    background-color: var(--clr-explore-head);
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);
    padding: 11px 16px;
}

.feed .music-choice-box {
    margin-bottom: 20px;
}

.feed .tab-cnt {
    margin-top: 100px;
    padding-bottom: 150px;
}

.feed .music-timing p {
    margin: 0;
}

.album-img {
    height: 125px;
    width: 125px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);

}

.album-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    /* border-radius: 8px; */
    word-break: break-all;
}

.feed .loop-times {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feed .music-time {
    display: flex;
    justify-content: space-between;
}

.no-music {
    display: flex;
    justify-content: center;
}

.feed .like-dot {
    display: flex;
    justify-content: space-between;
}

.feed .music-timing p {
    color: #C2C0CC;
}

.feed .music-timing .MuiSvgIcon-fontSizeMedium {
    color: #C2C0CC;
}

.accord-feed-page {
    border-radius: 0px 0px 8px 8px !important;
    z-index: inherit !important;
    margin: 0px !important;
}

.more-tracks-inner-div {
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px 15px 15px 60px;
    font-size: 12px;
    align-items: center;
    cursor: pointer;
}

.more-tracks-inner-div span {
    margin-right: 20px;
}

.music-play {
    display: none;
}

.inside-div:hover .music-play {
    display: flex !important;
    position: absolute;
    left: 25px;
    cursor: pointer;
}

.table-div-feed {
    z-index: inherit;
}

.music-play-main-div {
    display: flex;
    align-items: center;
}

div#panel1-header-feed-album-list,
svg,
.feed-music-main-div .MuiCollapse-wrapper.MuiCollapse-vertical,
td {
    background: var(--clr-bcg);
    color: var(--clr-font) !important;
    /* border-bottom: unset !important; */
    /* border-radius: 0px 0px 8px 8px; */
}

.feed-album-list-info {
    display: block !important;
    position: inherit !important;
}

:focus-visible {
    outline: none !important;
}

div#search-wrap2 {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--clr-search-border);
    border-radius: 10px;
    margin-top: 25px;
}

div#search-wrap2 button {
    padding: 0px;
}

.new-playlist {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 8px 10px;
    margin: 20px 0px;
    border-radius: 4px;
    cursor: pointer;
}

.new-playlist img{
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
}

.new-playlist svg {
    background: transparent !important;
    fill: #A22FEB;
}

.new-playlist:hover {
    background-color: #A22FEB;
    color: #fff;
}


.new-playlist:hover svg {
    background: transparent !important;
    fill: #fff;
}

.created{
    margin: 5px 0px !important;
}

.d-flex{
    display: flex;
}


.grid-height{
    height: 350px;
    overflow: scroll;
}






@media(max-width:767.98px) {
    .feed .feed-head {
        width: 100%;
        display: block;
        text-align: center;
    }

    .feed .tab-cnt {
        margin-top: 170px;
    }
}

@media(max-width:991.98px) {
    .feed .feed-head {
        width: 100%;
    }
}

.spacer-single span.MuiSwitch-track {
    background: var(--clr-font) !important;
}