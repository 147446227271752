@media (max-width: 575.98px) {
  .creator-box {
    height: auto !important;
  }

  .description-library2 {
    font-size: 10px !important;
  }

  .savechanges {
    flex-direction: column-reverse;
  }

  .description-library {
    font-size: 12px !important;
  }

  .start-listen-btn1 button {
    top: 0 !important;
    padding: 6px 20px !important;
    line-height: 14px !important;
    font-size: 12px !important;
  }

  .library-box2 .MuiGrid-root {
    margin: 10px 0px !important;
  }

  .discommui {
    font-size: 40px !important;
  }

  .lib {
    height: 36px !important;
  }

  .lib img {
    width: 100%;
    height: 40px;
  }

  .library-tab-box {
    width: 100% !important;
  }

  .library-head-div {
    /* flex-direction: column !important; */
    padding: 15px;
  }

  .library-tab .MuiTabs-flexContainer {
    justify-content: space-between !important;
  }

  .library-box2 {
    padding: 0px !important;
  }

  .music-stack {
    padding: 10px;
  }

  .library-heading {
    font-size: 28px !important;
  }

  .experience {
    font-size: 3.8rem !important;
    line-height: 50px;
    font-weight: 800 !important;
    letter-spacing: 2px;
    width: auto;
    line-height: 65px;
  }

  .library-top-btn button,
  .library-top-btn button:hover {
    padding: 4px 3px !important;
    font-size: 10px !important;
  }

  .library-tab button,
  .library-tab button.Mui-selected {
    font-size: 10px !important;
  }

  .bg-appbar2 div,
  .milestone,
  .normal-svg,
  .ts-coun {
    font-size: 16px !important;
  }

  .ts-t-time {
    font-size: 12px !important;
  }

  .jsuti {
    font-size: 10px !important;
  }

  div#search-wrap svg {
    font-size: 18px;
  }

  /* .player{
        position: fixed;
        bottom: 0;
        left: 0 !important;
        right: 0;
        z-index: 999;
      } */
  .start-now-btn button {
    padding: 5px 12px;
    font-size: 16px;
  }

  .txt-field input {
    padding: 13px 10px;
  }

  .txt-field button,
  .txt-field button:hover {
    padding: 10px 30px;
  }

  .tips-title {
    flex-direction: column;
  }

  .description,
  .description2 .text,
  .key-desc,
  .dtoken-btn,
  .how-desc,
  .through-c1,
  .through-c2 ul li,
  .how-desc2 {
    font-size: 16px;
  }

  .description,
  .cont2 .txt2 {
    width: 80% !important;
    margin: auto;
  }

  .cont2 .txt2,
  .pdt-1,
  .h-c1-cont {
    font-size: 14px;
  }

  .cont2 .txt1 {
    font-size: 36px;
  }

  .inner-box,
  .withdraw-popup.verify {
    width: 240px !important;
  }

  .copyright p {
    font-size: 13px !important;
  }

  .withdraw-popup {
    width: 318px !important;
    height: auto !important;
  }

  .Change-Password-main-box {
    height: 508px;
    overflow-y: scroll;
  }

  .Change-Password-popup-main.withdraw-popup .accnt-detail {
    padding: 15px 23px 35px 24px !important;
  }

  .change-password-text {
    padding: 30px 23px 0px 23px !important;
  }

  .submit-btn button {
    width: 100% !important;
  }

  .player-platform {
    font-size: 35px !important;
    line-height: 1.2 !important;
    padding-top: 0 !important;
  }

  /* .creator .MuiGrid-root.MuiGrid-container {
        margin-top: 29px !important;
        margin-bottom: 29px !important;
    } */

  .backstage-exclusive {
    font-size: 55px !important;
  }

  .card-participate {
    margin: 10px 0px !important;
    width: 15rem !important;
  }

  .participate-card {
    margin-left: 0px !important;
  }

  .bckstage-card-img img {
    width: 10rem !important;
  }

  .img-card img {
    height: 160px !important;
  }

  .card-participate {
    width: 15rem !important;
  }

  .join-btn {
    padding: 10px !important;
  }

  .backstage-container {
    padding: 25px 15px !important;
  }

  .backstage-pollsurvey {
    font-size: 50px !important;
  }

  .backstage-artist-grid,
  .backstage-artist-grid2 {
    padding: 25px 15px !important;
  }

  /* .earley-access {
         left: 30px !important;
         font-size: 10px !important;
     } */

  .er-txt1 {
    font-size: 17px !important;
  }

  .er-description,
  .music-detail,
  .album-detail,
  .top-stream-mian {
    flex-direction: column !important;
    align-items: center;
    gap: 10px;
  }

  .nft-tab-style button {
    padding: 15px 40px !important;
  }

  .artist-follow-main {
    flex-direction: column;
    gap: 15px;
  }

  .artist-tag {
    margin: 15px auto !important;
  }

  .artist-name-tag,
  .Track-genre,
  .discription-trackpage2 {
    text-align: center !important;
    justify-content: center !important;
  }

  .artist-page-pannel {
    align-items: center !important;
  }

  .artist-pg-tab-main-div {
    margin-top: 30px !important;
  }

  .track-cover-img img {
    width: 200px !important;
    height: 210px !important;
  }

  .how-to,
  .how-works {
    font-size: 40px;
  }

  .through-the,
  .how-works.pdt {
    margin: 0px;
  }

  .new-dp img {
    width: 35px !important;
    height: 35px !important;
    border-radius: 8px;
  }

  .enew-g1-slider,
  .premium-new {
    padding: 0px !important;
  }

  .premium-new {
    font-size: 40px !important;
  }

  .ts-c1-imgdiv img {
    width: 35px !important;
    height: 35px !important;
  }

  .history-new-main {
    flex-direction: column;
  }

  .mx-card-img {
    height: auto !important;
  }

  .mx-c-head {
    font-size: 12px !important;
  }

  .mx-c-para {
    font-size: 10px !important;
  }

  .feed-new-player {
    margin-top: 100px !important;
    flex-direction: column !important;
  }

  .enew-g1-release {
    /* justify-content: space-between; */
    gap: 15px;
    flex-direction: column;
  }

  .history-new-main {
    justify-content: center !important;
  }

  .box-flex {
    display: block !important;
  }

  .virtualmeet-btn {
    flex-direction: column !important;
  }

  .key-box {
    width: 90%;
    margin-top: 120px;
  }

  .key-feture2,
  .key-icon {
    font-size: 30px;
    line-height: 50px;
  }

  .key-feature1 {
    font-size: 55px;
    line-height: 115px;
  }

  .dtoken-txt2 {
    font-size: 40px;
    line-height: 0px !important;
  }

  .dtoken-txt1 {
    font-size: 60px;
    line-height: 100px;
  }

  .key-num-div {
    left: 50%;
    margin-left: -90px;
    width: 180px;
  }

  .key-num {
    font-size: 100px;
  }

  .feat-cont {
    margin-top: 0px !important;
  }

  .how-works.product-title-new,
  .how-works.product-title-new2 {
    line-height: 3rem;
  }

  .artist-page-pannel {
    top: -35px !important;
  }

  .artist-page-pannel .MuiAvatar-circular {
    width: 90px !important;
    height: 90px !important;
  }

  .display-block {
    display: block !important;
  }

  .Track-title {
    font-size: 20px !important;
  }

  .trackspage-tags {
    gap: 5px !important;
    flex-direction: column !important;
  }

  .loop-times,
  .loop-part {
    flex-direction: column;
    gap: 15px !important;
  }

  .repost-loop p {
    margin: 0px;
  }

  .follow-us-div {
    flex-direction: column !important;
    gap: 15px !important;
  }

  .upload .d-create-file,
  .mint-nft-tab {
    width: 100% !important;
  }

  .preview-img .edit1

  /* .profile-banner-size .MuiAvatar-root.MuiAvatar-circular */
    {
    width: 150px !important;
    /* left: 41px !important; */
    height: 150px !important;
  }


  .upload .select-method {
    padding: 0px !important;
  }

  /* .speed-dail {
        width: 30% !important;
    } */
  .flex-media {
    flex-direction: column !important;
    gap: 10px;
  }

  .sk-stack.main {
    justify-content: center !important;
  }

  .lib-stack,
  .lib2 {
    flex-direction: column !important;
    gap: 10px;
    align-items: center;
  }

  .sk-stack.main {
    justify-content: center !important;
  }

  .ts-c1-whole {
    flex-direction: column !important;
    gap: 15px;
  }

  .music-time {
    width: 100%;
  }

  .feed-new-search input {
    width: 230px !important;
  }

  .history-new-right {
    flex-flow: wrap;
    justify-content: center;
  }

  .profile-img .MuiAvatar-circular {
    width: 150px;
    height: 150px;
  }

  .colab-text-input input {
    width: 200px !important;
  }

  .card-participate {
    margin: 10px auto;
  }

  .celebrate-c1-main {
    flex-direction: column;
  }

  .celebrate-c1-btn-div {
    width: 70%;
  }

  .frame-with-mask-group {
    flex-direction: column;
  }

  .l-i-n-e {
    height: 0px !important;
  }

  .f-r-a-m-e3 {
    flex-direction: column !important;
  }

  .div-dropzone-1mxhs-1 button {
    margin-top: 20px !important;
  }

  #stk-respo svg,
  #stk-respo div {
    margin-left: 0px;
  }

  .button {
    width: 170px !important;
  }

  .top-support-main .MuiAvatar-root {
    width: 50px !important;
    height: 50px !important;
  }

  .copy-rights {
    justify-content: center;
    gap: 15px;
  }

  .i-img img {
    width: 300px;
    height: 300px;
  }


  .bck-desc {
    max-width: 85% !important;
    font-size: 19px !important;
  }

  .how-access {
    font-size: 40px !important;
  }

  .exciting-bck {
    font-size: 35x !important;
  }

  .feat-title,
  .i-desc {
    font-size: 20px !important;
  }

  .feat-desc {
    font-size: 18px !important;
  }

  .how-tostart {
    font-size: 50px !important;
    line-height: unset !important;
  }

  .profile-img-outer.minnft {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 85%;
  }

  .profile-img-outer {
    top: 118px !important;
  }

  .stack-cont .MuiGrid-root {
    padding: 0px !important;
  }

  .profile-outer-layer {
    padding: 0px !important;
  }


  .submit-btn button {
    width: 80% !important;
    margin: 10px auto !important;
  }

  .withdraw-popup.new {
    width: 82% !important;
  }

  .new-play-artist,
  .play-new-time,
  .new-play-num {
    font-size: 14px !important;
  }

  .avatar-music2 .MuiAvatar-root {
    width: 35px !important;
    height: 35px !important;
    border-width: 1.5px !important;
  }

  .subtitle1 {
    font-size: 14px !important;
  }

  .dragndrop.cond {
    width: 90% !important;
  }

  .tc {
    flex-direction: column;
  }

  .controlswho,
  .pundesc {
    /* font-family: Inter; */
    font-size: 15px !important;
    max-width: 100% !important;

  }

  .uploadingplayerdiv {
    flex-direction: column !important;
  }

  .sheduletext .MuiFormControl-root {
    min-width: unset !important;
  }

  .sheduletext.in1 .MuiInputBase-root {
    width: 200px !important;
  }

  .bckban1 img {
    width: 100%;
    height: 140px !important;
  }

  .display-4 {
    justify-content: center !important;
  }

  /* .flex-c {
    flex-direction: column !important;
  } */

  .stck-n-card2 .rmr {
    padding: 0 !important;
  }

  .rmr {
    font-size: 13px !important;
  }

  .p-bckprf {
    font-size: 15px !important;
    max-width: 100% !important;
  }

  .dsh-statu {
    width: 250px !important;
  }

  .genr-img img {
    width: 250px !important;
    height: 250px !important;
  }

  .firetex {
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 600 !important;
  }

  .tkdet {
    font-size: 10px !important;
  }

  .trc1 {
    margin: 15px auto !important;
  }

  .meduza {
    font-size: 12px !important;
  }

  .justc-medi {
    justify-content: center;
  }

  .cont-mn {
    width: 100% !important;
  }

  .genre-cov {
    padding: 50px 50px 0 50px !important;
  }

  .bckpasscreate .MuiBox-root {
    padding: 0 !important;
  }

  .bck-inner-mian {
    padding: 0 !important;
    margin: 0 !important;
  }

  .upload-big-img {
    flex-direction: column;
  }

  .upload-big-img .Icon {
    width: 100%
  }

  .General .upload-div {
    gap: 24px !important;
  }

  .level-up {
    flex-direction: column;
  }

  .logenew-inp.MuiFormControl-root,
  .regis-g button,
  .alrdy,
  .regis button {
    width: 100% !important;
  }

  .reg-height .margin-top {
    margin: 5px auto;
  }

  .grd2 {
    padding: 5px !important;
  }

}

@media (max-width: 767.98px) {
  .div-dropzone-1mxhs-1.history.new {
    flex-direction: column !important;
    align-items: flex-start !important;

  }

  .albmc-img img {
    width: 100% !important;
  }

  .melod {
    font-size: 16px !important;
  }

  .dser,
  .n-song {
    font-size: 12px !important;
  }

  .backstage-nft {
    font-size: 55px !important;
  }

  .bck-pass {
    font-size: 70px !important;
  }

  .earley-access {
    left: 48px !important;
    font-size: 10px !important;
  }

  /* .description-library2 {
        font-size: 16px ;
    }

    .description-library {
        font-size: 20px !important;
    }


    .start-listen-btn1 button {
        top: 30 !important;
        padding: 6px 20px !important;
        line-height: 18px !important;
        font-size: 16px !important;

    }

    .library-box2 .MuiGrid-root {
        margin: 20px 0px !important;
    }

    .lib {
        height: 20px !important;
    }
    .lib img {
       width: 100%;
       height: 40px;
    }     */

  .total-per,
  .start-now-offer {
    flex-direction: column;
    align-items: center !important;
  }

  .tips .card img {
    width: 340px;
  }

  .per {
    padding: 0px 0.09px 0.66px 0px !important;
    font-size: 28px !important;
  }

  .player-description {
    line-height: 1.5 !important;
    font-size: 18px !important;
  }

  .player .start-now-offer button {
    padding: 13px 30px !important;
  }

  .offer-left {
    text-align: center;
  }

  .fanzone .process {
    flex-direction: column;
  }

  .discover-fanzone {
    flex-direction: column;
  }

  .fanzone .process-main {
    font-size: 34px !important;
  }

  .fanzone .process {
    gap: 0px !important;
  }

  .discover-fanzone .con {
    font-size: 29px !important;
  }

  .subimg img {
    max-width: -webkit-fill-available !important;
  }

  .brief-instruct {
    margin-top: 14px !important;
  }

  .fanzone .playerBanner {
    width: 100%;
    height: 100vh;
    background-image: url("./img/dster-webp/fanzone-banner-bg.webp");
    position: absolute;
    object-fit: cover;
    background-position: 100% 100%;
    background-size: cover;
    opacity: 75%;
  }

  .experience-process {
    padding-top: 0 !important;
  }

  .fanzone .process-main {
    min-width: auto !important;
  }

  .brief-instruct li,
  .fanzone .process h6 {
    font-size: 20px !important;
  }

  .fanzone .start-now-offer {
    margin-bottom: 0px !important;
  }

  .footer-policy {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    text-align: left;
  }

  .terms {
    flex-direction: column;
  }

  .music-player-grid {
    padding-bottom: 0 !important;
  }

  .discover-backstage {
    flex-direction: column;
    gap: 0px;
  }

  .backstage-participate {
    margin: 0 25px !important;
  }

  .artist-page-pannel {
    flex-direction: column;
  }

  /* .library-tab2{
        width: 75%;
     } */
  .artist-name-tag {
    color: var(--clr-font) !important;
  }

  .artist-follow-btn button,
  .artist-follow-btn button svg {
    color: var(--clr-font) !important;
    fill: var(--clr-font) !important;
  }

  .see-all {
    text-align: center !important;
  }

  .feed-new-whole {
    flex-flow: wrap;
    gap: 20px;
    justify-content: center !important;
  }

  /* .feed-new-search input {
        width: auto !important;
    } */

  .history-new-main {
    gap: 30px;
    padding: 30px !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .stk-dis {
    display: none !important;
  }

  .start-end-time-main {
    flex-direction: column;
  }

  .flex-c {
    flex-direction: column !important;
    gap: 16px;
  }

  .startofspec {
    text-align: center !important;
  }

  .bckpasscreate .display-2 {
    flex-direction: column;
    gap: 20px;
  }

  .sett.text-right svg {
    width: 25px;
  }

  .lb-name {
    font-size: 26.943px !important;
  }

  .artist-bg {
    font-size: 12px !important;
    padding: 3px 16px !important;
  }

  .display-2.flex-c .MuiStack-root {
    margin-left: 0 !important;
  }

  .dashboard-head,
  .subsc {
    font-size: 28.94px !important;
  }

  .dashboard-body .layerdiv {
    margin-top: 0px !important;
  }

  .group-inputs .layerdiv {
    margin-top: 30px !important;
  }

  .subscription .display-1,
  .categories .display-1 {
    flex-direction: column !important;
  }

  .categories .border-lye {
    margin: 0 !important;
  }

  .categories .decision-btn {
    margin-top: 20px;
  }

  .edit-btn,
  .edit-btn .crrprice2 {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .crrprice2 button {
    width: 60%;
  }

  .membership {
    justify-content: center;
  }
}

@media (min-width: 900px) {
  nav.sidebar.MuiBox-root.css-u9txht {
    width: auto !important;
  }


}

@media (max-width: 900px) {

  .logenew-inp.MuiFormControl-root,
  .regis-g button,
  .alrdy,
  .regis button {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .experience {
    font-size: 4rem !important;
    line-height: 80px;
    letter-spacing: 2px;
    width: auto;
  }

  .player-platform {
    font-size: 47px !important;
    line-height: 1.2 !important;
    padding-top: 50px !important;
  }

  .description,
  .cont2 .txt2 {
    width: 80% !important;
    margin: auto;
  }

  .cont2 .txt2 {
    font-size: 14px;
  }

  .cont2 .txt1 {
    font-size: 36px;
  }

  .inner-box,
  .withdraw-popup.verify {
    width: 360px !important;
  }

  .withdraw-popup {
    height: auto !important;
  }

  .Change-Password-main-box {
    height: 500px;
    overflow-y: scroll;
  }

  .backstage-pollsurvey {
    font-size: 60px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .experience {
    font-size: 5rem;
    line-height: 80px;
    letter-spacing: 2px;
    width: auto;
  }

  .description {
    width: 70%;
  }

  .withdraw-popup {
    height: auto !important;
  }

  .Change-Password-main-box {
    height: 500px;
    overflow-y: scroll;
  }

  .fanzone .process {
    gap: 22px !important;
    flex-direction: column;
  }

  .fanzone .process-main {
    font-size: 44px !important;
  }

  .discover-fanzone {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .discover-fanzone .con {
    font-size: 29px !important;
    line-height: 1.3 !important;
  }

  .er-description {
    flex-direction: column !important;
  }

  .settings-tab .MuiTabs-flexContainer {
    justify-content: flex-start !important;
    width: 400px;
  }
}

@media (max-width: 991.98px) {

  .inner-box,
  .withdraw-popup.verify {
    padding: 0px 40px 20px !important;
  }

  .copyright p {
    padding: 0 !important;
  }

  .surprise-offer {
    flex-direction: column;
    gap: 25px;
  }

  .player-platform.pwr-back {
    padding-top: 0 !important;
  }

  .experience-process {
    padding-top: 0 !important;
  }

  .profile-banner-inner img.img-display-after {
    max-width: 100%;
    height: auto;
  }

  .allButton-div {
    flex-direction: column-reverse;
    gap: 20px;
    align-items: center;
  }

  .fanzone .start-now-offer button {
    padding: 12px 35px !important;
  }

  .discover-backstage {
    gap: 30px !important;
  }

  /* .buy-now-act-detail {
        flex-direction: column;
    } */
  .artist-explore-more {
    left: 0 !important;
  }

  .profile-name-new,
  .new-head-profile svg {
    display: none;
  }

  .key-box {
    margin-top: 120px;
  }

  .feat-cont {
    margin-top: 0px !important;
  }

  .product-title-new,
  .product-title-new2 {
    position: relative;
    text-align: center;
    left: 0px;
    padding: 2px;
    margin: 0px;
  }

  .gd-1,
  .gd-2 {
    justify-content: center;
  }

  .dummy-banner {
    position: relative;
  }

  .support-flex {
    height: auto !important;
  }

  .genre-cov {
    justify-content: center;
  }

  .genre-cov .con-cov {
    flex-direction: column !important;
    align-items: center;
  }

  .innermnt {
    margin-left: unset !important;
  }
}

@media (max-width: 900px) {

  .ac-detail-title-verify,
  .ac-detail-title-verify svg,
  .follow-ac-detail,
  .time-n-date-ac-detail,
  .profile-outer-layer-activity-detail .MuiGrid-root,
  .buy-now-act-detail {
    justify-content: center !important;
    text-align: center;
  }
}

@media all and (max-width: 1199.98px) {
  .create-play-grid {
    margin-top: 320px;
  }

  .ind-style,
  .ind-style2 {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .normal-style {
    margin: 40px 0px !important;
  }

  .pr-main {
    justify-content: center !important;
  }

  .previo-title {
    flex-flow: wrap !important;
  }

  .gen-dis {
    display: block !important;
  }

  .back-btn svg {
    position: absolute;
    left: 0%;
    top: -1% !important;
    font-size: 30px;
  }

  .pvt {
    width: auto !important;
  }

  .upload-flex {
    justify-content: space-between !important;
  }

  .group-inputs {
    width: 100% !important;
  }

  .mon-mar {
    margin: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .withdraw-popup {
    height: auto !important;
  }

  .Change-Password-main-box {
    height: 518px;
    overflow-y: scroll;
  }

  .copyright p {
    padding: 0 !important;
  }

  .player .all-services .card {
    min-height: 414px;
  }

  .player .all-services .card.player-fanzone .inner-card-img img {
    position: relative;
    bottom: -30px;
  }

  .discover-fanzone {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .discover-fanzone .con {
    font-size: 29px !important;
  }

  .discover-backstage {
    gap: 60px !important;
  }

  .fanzone .start-now-offer button {
    padding: 12px 35px !important;
  }

  .artist-follow-main {
    flex-direction: column;
    gap: 15px;
  }

  .artist-follow-btn button,
  .artist-follow-btn button svg {
    color: var(--clr-font) !important;
    fill: var(--clr-font) !important;
  }

  .headnew-inter {
    display: none !important;
  }
}

/* @media (min-width:991.98px) and (max-width:1199.98px) {

     .activity-detail-img img {
         width: 350px !important;
         height: 450px !important;
     }

 } */

@media (min-width: 1669px) and (max-width: 1199.98px) {
  .register-page .inner-box {
    height: auto !important;
    overflow-y: visible !important;
  }
}

@media (min-width: 600px) {}

@media (min-width: 1499px) {
  .feed .feed-head {
    width: 100% !important;
  }

  .track-cover-img,
  .track-title-content-div {
    text-align: center !important;
  }

  .discription-trackpage2,
  .follow-us-div2,
  .trackspage-tags {
    justify-content: center !important;
    flex-wrap: wrap !important;
  }

  .profile-img-outer2.minnft {
    position: relative !important;
    left: 0px !important;
    top: 0px !important;
  }

  .imgae-div-edit-grid {
    display: flex;
    justify-content: center;
  }

  .activity-detail-img img {
    width: 400px !important;
    height: 450px !important;
  }

  .activity-detail-img {
    text-align: center;
  }
}

@media (min-width: 575px) and (max-width: 991.98px) {
  .key-feature1 {
    font-size: 100px;
  }

  .key-feture2 {
    font-size: 50px;
  }

  .dtoken-txt1 {
    font-size: 90px;
    line-height: 0px;
  }

  .dtoken-txt2 {
    font-size: 70px;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {

  .bck-tab2 .MuiTabs-flexContainer,
  .bck-tab .MuiTabs-flexContainer {
    justify-content: flex-start !important;
  }

  .gamification .commentinput2 {
    flex-direction: row !important;
  }
}

@media (min-width: 768px) {
  .stk-dis2 {
    display: none !important;
  }
}

@media (max-width: 595.98px) {

  .bck-tab2 .MuiTabs-flexContainer,
  .bck-tab .MuiTabs-flexContainer {
    justify-content: flex-start !important;
  }
}

@media (max-width: 1999.98px) {
  .bck-inner-mian {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) and (max-width: 1375px) {
  .settings-tab .MuiTabs-flexContainer {
    justify-content: flex-start !important;
    width: 400px;
  }
}