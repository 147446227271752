.login-page {
    background: url(../../img/dster-webp/login-bg.webp), lightgray 0px -15.429px / 100% 102.857% no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    /* position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0; */
}

.errorDiv {
    color: red;
    text-align: center;
    font-size: 12px;
}

.outer-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.inner-box {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 10px 15px -3px rgba(35, 32, 61, 0.10);
    padding: 38px 28px;
    outline: unset !important;
    width: 448px;
    /* height: 544px; */
}

.dream-logo img {
    width: 190.002px;
    height: 50px;
}

.dream-logo {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0px;
    z-index: 999;
    background: #fff !important;
    padding: 25px 0px;
    border-radius: 12px;
}

h1.log-head {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    /* padding: 35px 96px 43px 96px; */
    padding: 35px 0;
    margin: 0;
}

.inner-popup p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
}

.inner-popup .MuiFormControl-root {
    border-radius: 12px;
    background: #F3F4F6;
    width: 100%;
    margin: 8px 0;
}

.inner-popup fieldset {
    border: 0;
}

.inner-popup input {
    color: #6B7280;
    /* font-family: DM Sans; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 26px 16px 26px;
}

.inner-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    gap: 6px;
    margin-top: 25px;
}

.inputbox {
    width: 100%;
}

.submit-btn button {
    border-radius: 12px;
    border: 2px solid #FFF;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    width: 100%;
    padding: 13px 0px 14px 0px;
    justify-content: center;
    align-items: center;

    color: #FFF;
    gap: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    text-transform: initial;
}

.submit-btn {
    width: 100%;
    text-align: center;
    margin: 5px 0px;
}

.inputbox>div.pwd-spc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputbox>div.pwd-spc a {
    color: #F70000 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
}

.inputbox>div a:hover {
    text-decoration: underline;
}

.copyright p {
    color: #6B7280;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 30px 0 0;
    margin-bottom: 0;
}

.copyright {
    text-align: center;
    width: 100%;
}

.already-acct {
    text-align: center;
    color: #2d2d2d;
    font-weight: 600;
    margin-top: 5px;
    font-size: 13px;
}

.already-acct a {
    text-decoration: none;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-transform: initial;
    margin-left: 5px;

    background: radial-gradient(912.99% 430.41% at 50% 5.36%, #FC5AFF 5.39%, #175AFF 19.83%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.verify {
    line-height: 0;
    margin-top: 5px;
}



/* Change password */

.withdraw-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 504px;
    border-radius: 15px;
    background: #FFF;
    /* height: 632px; */
    border-bottom-right-radius: 0;
    outline: unset !important;
}


.withdraw-popup.new {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    border-radius: 12px;
    background: #FFF;
    /* height: 632px; */
    padding: 10px 20px;
    /* border-bottom-right-radius: 0; */
    outline: unset !important;
}

.popup {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: #fff;
    box-shadow: 4px 6px 10px 0px rgba(0, 0, 0, 0.50);
    color: #fff;
    cursor: pointer;
}

.withdraw-popup .add-new {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    padding: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.withdraw-popup .add-new p {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 20px;
}

.change-password-text {
    padding: 30px 35px 0px 45px;
}

.Change-Password-popup-main.withdraw-popup .accnt-detail {
    padding: 15px 35px 35px 35px;
}

.change-password-text h5 {
    color: #000;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.change-password-text p {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding-top: 10px;
}

.accnt-detail p {
    font-size: 17px;
    font-weight: 500;
    line-height: 0;
    margin-left: 10px;
}

p.Old-Password-text {
    padding-bottom: 10px;
    padding-top: 15px;
}

.withdraw-popup .inner-popup {
    gap: 0;
}

.popup svg {
    font-size: 18px !important;
    font-weight: 600;
    fill: #000;
}

.submit-btn svg {
    font-size: 19px;
}

.check-agree {
    display: flex;
    align-items: self-start;
    justify-content: center;
}

.check-agree p {
    font-size: 13px;
    line-height: 1.2;
    margin-left: 0;
}

.check-agree p span {
    color: #1f5aff;
}

.check-agree .check-box {
    margin-top: 3px;
}

/* Change password */

/* verifiy */
h6.new-act-head {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    /* margin: 20px 0; */
}

.withdraw-popup.verify {
    padding: 30px 28px;
    border-bottom-right-radius: 12px;
    height: auto;
    width: 448px;
}

.withdraw-popup.verify .inner-popup {
    gap: 18px;
}

/* verifiy */
.verify-bck {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    justify-content: center;
}

.verify-bck svg {
    fill: #000 !important;
}