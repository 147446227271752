.headnew-logo {
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;
}

.discover-new {
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: left;
    color: #7c8db5b8;
    padding: 5px 15px;
}

.navlink-new a.non-active .nav-txt {
    /* font-family: Arial; */
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: left;
    color: #7c8db5b8 !important;
}

.navlink-new a.active1 {
    /* background: linear-gradient(105.94deg, #175AFF 14.29%, #FC5AFF 97.46%);
       */
    color: #fc5aff;
    position: relative;
}

.notification-svg span .MuiBadge-badge {
    background-color: #ea5aff;
}

.navlink-new a.active1 svg path {
    fill: #fc5aff !important;
}

.navlink-new a.active1 svg path.white-svg {
    fill: #fff !important;
}

.navlink-new a {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px 15px;
}

.go-mobile {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    flex-flow: wrap;
}

.go-mobile img {
    width: 100px;
    height: 40px;
}

.sidebar .MuiPaper-root.MuiPaper-elevation,
.sidebar .MuiPaper-root.MuiPaper-elevation {
    /* background: var(--clr-bcg) !important; */
    color: #fff !important;
}

.bg-appbar2,
.bg-appbar2 div {
    background-color: var(--clr-bcg) !important;
    box-shadow: unset !important;
    backdrop-filter: blur(10px);
}

.svg-icon {
    color: var(--clr-font);
}

.exp-new {
    font-family: Arial;
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    letter-spacing: 0.01em;
    text-align: left;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(274.4deg, #175aff 2.38%, #fc5aff 97.31%);
    text-transform: capitalize;
}

@keyframes flip {

    0%,
    100% {
        transform: scaleX(-1);
        /* Initial and final flip state */
    }

    50% {
        transform: scaleX(1);
        /* Midway through the animation, return to original state */
    }
}

.animated-svg {
    animation: flip 5s ease-in-out infinite;
    /* Adjust the animation duration and timing function as needed */
}

.sidebar-btn {
    display: flex;
    justify-content: end;
    height: 10vh;
    flex-direction: column;
    align-items: center;
}

.sidebar-btn img {
    margin-right: 5px;
}

.sidebar-btn a {
    padding: 8px 30px;
    border-radius: 4px;
    background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
    color: #f2f2f2;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    /* 100% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.sidebar-header-div {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
}

/* .responsive-drawer {
      display: none;
  } */

.responsive-drawer div,
.sidebar .MuiDrawer-paper.MuiDrawer-paperAnchorLeft,
.sidebar .MuiDrawer-paper {
    background: var(--clr-bcgnew) !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.responsive-drawer div {
    z-index: 0;
}

/* .responsive-drawer div {
      color: #7C8DB5B8;
  } */

.g-icon {
    transform: rotate(180deg);
}

.sp {
    font-family: Arial;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--clr-font) !important;
    margin-left: 10px;
    background: transparent !important;
    opacity: 60%;
}

.head-title-new {
    display: flex;
    gap: 5px;
    align-items: center;
}

a.active1::after {
    content: "";
    display: block;
    width: 5px;
    height: 35px;
    position: absolute;
    background: #fc5aff;
    border-radius: 10px;
    right: 0;
}

.header-top-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-dp {
    display: flex;
}

.new-dp img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fc5aff !important;
}

.new-head-profile {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: var(--clr-font) !important;
}

.profile-name-new {
    /* font-family: Manrope; */
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
}

.new-oremium {
    /* font-family: Manrope; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 60%;
}

.new-head-profile svg {
    font-size: 25px !important;
}

.header-new-in {
    display: flex;
    align-items: center;
}

#header-middle-text2 a.active {
    color: #fc5aff !important;
}

div#header-middle-text2 {
    justify-content: center !important;
}

div#header-middle-text2 a {
    color: var(--clr-upload-font);
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    padding: 6px 15px;
    text-decoration: none;
}

.gen-dis {
    display: none;
}

.discover-text-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discover-text-main img {
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.notification-svg svg {
    display: block !important;
}