.notify th,
td {
  background: var(--clr-bcg);
  color: var(--clr-font);
  border-bottom: unset !important;
}

.notify td {
  /* font-weight: bold; */
  cursor: pointer;
  /* text-align: center; */
}

.cls-bold td {
  font-weight: bolder !important;
}

.cls-normal td {
  font-weight: 200 !important;
}

.notify .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--clr-font);
}

.read-select {
  border: 1px solid var(--clr-font);
  width: 150px;
}

.notify-pop {
  padding: 10px;
}

.notify-pop div {
  margin: 10px 0px;
}

.notify-pop span {
  margin-left: 20px;
}

.noti-pop-msg {
  display: flex;
  align-items: flex-start;
}

.noti-pop-msg div {
  margin: 0px !important;
  margin-left: 20px;
}

.notify-close {
  position: absolute;
  right: 3px;
}

.notify-pagination ul {
  justify-content: center;
}

.notify-pagination li button {
  color: var(--clr-font);
}

.read-select .MuiSelect-select {
  padding: 10px;
}