.explr2 {
    /* font-family: Inter; */
    font-size: 35px;
    font-weight: 500;
    line-height: 42.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    margin: 15px 0;
}

.mint-c {
    background: var(--clr-bcg4);
    border-radius: 8px;
    width: 180px;
    margin: 15px auto;
}

.min-cimg img {
    width: 180px;
    height: 160px;
    border-radius: 8px 8px 0 0;
}

.dilit {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 11.343px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.445px;
    /* 171.429% */
    margin: 5px 0;
    text-transform: uppercase;
}

.orgm {
    color: var(--clr-font);
    font-size: 11.343px;
    font-style: italic;
    font-weight: 100;
    line-height: 19.445px;
    /* 171.429% */
    margin: 5px 0;
}


.dether {
    /* font-family: Inter; */
    font-size: 12.343px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.445px;
    /* 171.429% */
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 5px 0;
}

.mnt2 button {
    border-radius: 7px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    color: #FFF;
    text-align: center;
    /* font-family: Inter; */
    font-size: 11.343px;
    font-style: normal;
    font-weight: 500;
    line-height: 32.409px;
    /* 285.714% */
}