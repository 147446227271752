.genre-cov {
    /* background: linear-gradient(144.26deg, #FC5AFF -22.62%, rgba(23, 90, 255, 0) 51.59%); */
    padding: 100px 100px 0 100px;
    /* Rectangle 4624 */
    background: linear-gradient(144.26deg, #FC5AFF -18.46%, rgba(23, 90, 255, 0) 55.76%);
}

.genr-img img {
    width: 290px;
    height: 300px;
}

.firetex {
    /* font-family: Inter; */
    font-size: 28px;
    font-weight: 400;
    line-height: 37.91px;
    text-align: left;
    color: var(--clr-font);
    margin: 10px;

}

.meduza {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    /* margin: 10px; */
}

.tkdet {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
}