.fanzone .playerBanner2 {
    background: url('../../img/dster-webp/backstage.webp');
    width: 100%;
    /* height: 110vh; */
    /* background: linear-gradient(0deg, rgba(17, 17, 17, 0.83) 0%, rgba(17, 17, 17, 0.83) 100%), linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0.00) 52.23%, #111 100%), url(../../img/player-banner-bg.png), lightgray 50% / cover no-repeat; */
    /* position: absolute; */
    object-fit: cover;
    background-position: center;
    background-size: cover;
    /* opacity: 75%; */
    background-repeat: no-repeat;
    padding: 100px 0;
}

.playerBanner3 {
    background: url('../../img/dster-webp/backstage2.webp');
    width: 100%;
    /* height: 100vh; */
    /* background: linear-gradient(0deg, rgba(17, 17, 17, 0.83) 0%, rgba(17, 17, 17, 0.83) 100%), linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0.00) 52.23%, #111 100%), url(../../img/player-banner-bg.png), lightgray 50% / cover no-repeat; */
    /* position: absolute; */
    object-fit: cover;
    background-position: center;
    background-size: cover;
    /* opacity: 75%; */
    background-repeat: no-repeat;
    padding: 100px 0;

}


.backstage-artist-grid {
    background: var(--clr-bckstage-bg) !important;
    padding: 25px 30px;
    border-radius: 8px;
    /* box-shadow: 6px 4px 63px 0px #000; */
    height: 450px;
    overflow: scroll;
    margin-top: 50px !important;
    /* margin-left: 40px !important; */
}

.backstage-artist-grid2 {
    background: var(--clr-bckstage-bg);
    padding: 25px 30px;
    border-radius: 8px;
    box-shadow: 6px 4px 63px 0px #000 !important;
    /* height: 450px; */
    /* overflow: scroll; */
    margin-top: 50px !important;
    /* margin-left: 40px !important; */
}

.backstage-artist-grid2.new {
    background: var(--clr-bcg) !important;
}

.backstage-artist-grid::-webkit-scrollbar-thumb {
    background-color: #b35bff !important;

}


.backstage-artist-grid::-webkit-scrollbar {
    width: 8px !important;
    /* height: 178px !important; */
}



.card-backstage {
    background-color: var(--clr-bckstage-bg) !important;
    color: var(--clr-track-time) !important;
    text-align: center;
    padding: 10px;
}

.explore-more-btn2 button {
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #CC0FE0;
    color: #fff;
    text-transform: uppercase;
    margin-top: 10px;
}

.explore-more-btn2 button:hover {
    background: #CC0FE0 !important;
    color: #fff;
    box-shadow: 0px 0px 7px #7c859a;
}



.discover-backstage {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 150px;
    /* margin: 45px 0; */
    position: relative;
}

.discover-backstage.exclusive {
    justify-content: center !important;
}


.discover-backstage svg path {
    fill: #CC0FE0;
}

.discover-backstage div svg {
    background: transparent !important;
}

.backstage-body div,
.backstage-body h1 {
    color: #fff !important;
}

.backstage-body h1 span svg {
    margin-bottom: 20px !important;
    padding: 8px;
    background: unset !important;
}

.backstage-pollsurvey {
    padding-top: 15px;
    color: #fff;
    text-align: center;
    font-size: 72px;
    font-family: Anton;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    margin-bottom: 50px;
}

.backstage-exclusive {
    /* padding-top: 15px; */
    color: #fff;
    text-align: right;
    font-size: 72px;
    font-family: Anton;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    margin-bottom: 50px;
    /* margin-right: 50px; */
}


.backstage-participate {
    border-radius: 50px 0px 0px 50px;
    border: 3px solid rgb(140 89 254);
    background: linear-gradient(90deg, #161616 0%, rgba(22, 22, 22, 0.00) 134.69%);
    margin: 0 70px;
    padding: 45px 15px;
}

.backstage-participate div {
    color: #fff;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.16px;
    /* 140.8% */
    letter-spacing: 0.16px;
    width: 59%;
    margin: auto;
    /* margin-bottom: 20px; */
    text-align: center;
}

.backstage-participate button,
.backstage-participate button:hover {
    background-color: #d25aff;
    color: #FFF;
    /* font-family: "DM Sans"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
    padding: 5px 20px;
    margin-top: 30px;
}

.backstage-container {
    max-width: 1700px !important;
    margin: auto !important;
    justify-content: center;
    padding: 25px 30px;
}

.exclusive-img img {
    width: 302px;
    height: 220px;
}

.er-txt1 {
    color: var(--clr-font);
    font-family: "DM Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2.5px;
    /* 142.857% */
}

.er-description {
    display: flex;
    gap: 15px;
    align-items: center;
    /* margin: 10px 0px; */
    margin-top: 30px;
}

.ed-detail {
    border-radius: 6px;
    background: 8080803b;
    color: var(--clr-font);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    padding: 2px 12px 2px 12px;
}

.er-timing {
    display: flex;
    align-items: center;
    gap: 5px;
}


.exclusive-scroll {
    position: absolute;
    top: 30px;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 19.444px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.778px;
    /* 142.857% */

}

.backstage-nft {
    color: #fff;
    text-align: center;
    font-size: 72px;
    font-family: Anton;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    margin-bottom: 50px;
}

[aria-label="basic tabs example nft"] {
    gap: 25px;
    /* border: 1px solid #fff; */
    align-items: center;
    width: fit-content;
    margin: auto;
    padding: 5px;
    /* border-radius: 10px; */
    border-radius: 15px;
    background: #000;
    box-shadow: 4px 4px 4px 0px rgba(149, 44, 161, 0.20) inset;
}

.nft-tab-style span.MuiTabs-indicator {
    display: none;
}

.nft-tab-style button {
    color: #fff !important;
    padding: 5px 70px;
    border-radius: 12px;
    /* border-radius: 12px; */
    background: #111;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}


.nft-tab-style [aria-selected="true"] {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)) !important;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.exclusive-grid {
    background: #000;
}

.owl-grid {
    justify-content: center;
}

.earley-access {
    position: relative !important;
    top: -45px;
    left: 55px;
    border-radius: 4px;
    /* border: 1px solid var(--1, #175AFF) !important; */
    background: #CC0FE0 !important;
    box-shadow: -8px 6px 59px 0px rgba(0, 0, 0, 0.50);
    color: #FFF !important;
    text-align: center;
    font-family: Arial;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 140% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    gap: 10px;
}

.earley-access svg {
    background: unset !important;
}

.selected-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.join-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.join-title {
    text-align: center;
}

.calender-grid-cont {
    padding: 15px;
    justify-content: center;
    gap: 15px;
    margin-top: 50px;
}

.selected-date-grid {
    border-radius: 40px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));

}

.date {
    color: #FFF;
    text-align: center;
    /* font-family: Roboto; */
    font-size: 55.927px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.month {
    color: #FFF;
    text-align: center;
    /* font-family: Roboto; */
    font-size: 15.976px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.date-calender-whole-div {
    margin: 10px 15px;
}

.today {
    color: #FFF;
    /* font-family: Roboto; */
    font-size: 20.146px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.today-inner-cont {
    border-radius: 6.483px;
    background: #1E1E1E;
    padding: 15px;
    margin: 10px 0px;

}

.inner-cont2 {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: center;
    justify-content: space-between;
}

.inner-cont2 img {
    width: 50px;
    height: 50px
}

.calander-song-genre {
    color: #FFF;
    /* font-family: Roboto; */
    font-size: 11.669px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.558px;
    text-align: start;
    /* 133.333% */
}

.country {
    color: #FFFFFF;
    /* font-family: Roboto; */
    font-size: 9.076px;
    font-style: normal;
    font-weight: 300;
    line-height: 12.965px;
    opacity: 60%;
    /* 142.857% */
}

.join {
    color: #F2F2F2 !important;
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 8.363px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 8.363px;
    /* 100% */
    letter-spacing: 0.232px;
    border-radius: 1.858px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    text-transform: capitalize;
}

.remainder {
    color: #F2F2F2 !important;
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 8.363px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 8.363px;
    /* 100% */
    letter-spacing: 0.232px;
    border-radius: 1.858px;
    border: 0.465px solid var(--1, #175AFF) !important;
    text-transform: capitalize;
    margin-top: 6px !important;
}

.remainder:hover {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
}

.calender-timing {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.calender-date-n-time {
    color: #FFF;
    text-align: center;
    font-family: Arial;
    font-size: 10.131px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 5px 3px;
    border-radius: 3.89px;
    border: 1.297px solid rgba(221, 221, 221, 0.10);
    background: rgba(0, 0, 0, 0.10);
}

.card-participate p {
    font-size: 14px !important;
}

.card-participate {
    cursor: pointer;
}

.inner-cont3 {
    display: flex;
    gap: 15px;
}

.today-cont {
    height: 450px;
    overflow: scroll;
}

.back-staking {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    line-height: 30px;
    padding: 10px 40px;
    color: var(--clr-font);
}

.start-now-offer.stak {
    margin-bottom: 0px !important;
}

.backstage-exclusive.stak {
    text-align: center;
}



.inner-cont3.cal {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;

}

.calander-evt-pop-div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.new-height {
    display: flex;
    align-items: center;
    height: 70vh;
    justify-content: center;
}

.join-meet .calander-song-genre {
    font-size: 17px;
}

.join-meet .country {
    font-size: 14px;
    line-height: 26.965px;

}

.join-meet .inner-cont2 {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
}

.join-meet .inner-cont2 img {
    width: 100px;
    height: 100px;
}

.join-meet .inner-cont3 {
    align-items: center;
}

.join-meet .calender-date-n-time,
.join-meet .join {
    font-size: 13px !important;
}