.UploadMusic-box3 .upload-grid {
    background: var(--clr-bcg) !important;
}

.UploadMusic-box3 .MuiContainer-root {
    border-radius: 10px;
    /* background-color: var(--clr-explore-head); */

}

.UploadMusic-box3 .MuiGrid-root {
    padding: 10px;
    margin: 25px auto;

    /* justify-content: center; */
}

.UploadMusic-box3 .upload-grid {
    height: 550px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

.UploadMusic-box3 .upload-grid2 {
    height: 750px;
    /* overflow: scroll; */
}

.Hoisting-box {
    /* background: var(--clr-hoisting-bg) !important; */
    /* height: 100vh; */
    margin-top: 100px;
}



.hoidting-form div {
    color: var(--clr-form-txt);
    /* font-family: "DM Sans"; */
    font-size: 13.493px;
    font-style: normal;
    /* font-weight: 700; */
    line-height: 20.29px;
    /* 140% */
    text-transform: capitalize;
}

.hoidting-form div .MuiInputBase-root {
    color: var(--clr-font);
    /* border: 1px solid var(--clr-font); */
    background: var(--clr-form-input);
}

.hoidting-form div .Mui-focused .MuiOutlinedInput-notchedOutline,
.hoidting-form div .Mui-error .MuiOutlinedInput-notchedOutline {
    border: unset !important;
}


.hoidting-form div .MuiFormControl-root {
    width: 100%;
}

.red {
    color: #D33535;
}

.hoidting-form h5 span {
    font-size: 10.145px;
}

.hoidting-form h5 {
    margin: 10px 3px;
}

.create-room button {
    color: var(--Surface-main, #FFF);
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 15.594px;
    font-style: normal;
    font-weight: 700;
    line-height: 11.594px;
    /* 100% */
    /* width: 100%; */
    padding: 15px 40px;
    text-transform: capitalize;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    margin-top: 20px;
    border-radius: 15px;
}


.create-room {
    text-align: center;
}

[id="outlined-error-helper-text crypto-address"] {
    color: #37C25E !important;
    font-weight: 200 !important;
}

.go-violet.size {
    font-size: 14px;
}


.form-input-main-div-hoisting {
    display: flex;
    align-items: flex-start;
}

.generate button {
    color: var(--Surface-main, #FFF);
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 15.594px;
    font-style: normal;
    font-weight: 700;
    line-height: 11.594px;
    /* 100% */
    /* width: 100%; */
    padding: 15px 50px;
    text-transform: capitalize;
    /* margin-top: 20px; */
    /* border-radius: 72.464px; */
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
}

.generate {
    margin-bottom: 20px;
}

.hoidting-form input {
    padding: 12px 14px !important;
}

/* 
[role="presentation"],
[role="alert"] {
    width: 100%;
} */

/* [role="alert"] {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
} */

.description-UploadMusic.track-timemain {
    width: 80%;
}