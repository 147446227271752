.enew-g1-main {
    padding: 20px;
    background: var(--clr-bcg);
    border-radius: 15px;
    box-shadow: 0px 4px 50px 15px rgba(99, 60, 247, 0.04);
}

.enew-g1-main2 {
    padding: 20px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    border-radius: 15px;
    box-shadow: 0px 4px 50px 15px rgba(99, 60, 247, 0.04);
}

.history-new-left .images {
    cursor: pointer;
}

.nft-item {
    padding: 10px;
    background: var(--clr-bcg);
    border-radius: 15px;
    text-align: center;
    position: relative;
    cursor: pointer;
    border-radius: 12px;
    border: 0.75px solid #f369212e;
}


.nft-item img {
    width: 220px !important;
    height: 220px;
}

.info {
    /* font-family: SF Pro Display; */
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--clr-new-exp);
    padding: 5px 35px;
    ;
    text-align: left;

}

.price {
    /* //styleName: Text 2; */
    /* font-family: SF Pro Display; */
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #7C8DB5BF;
    padding: 5px 35px;
    ;
    text-align: left;


}

/* .owl-item,
.owl-item.active,.owl-item.cloned {
    width: 160px !important;
    height: 204px !important;
} */
/* .enew-g1-img {
    padding: 30px 0px;
} */

.enew-chart {
    padding: 20px;
    /* font-family: SF Pro Display; */
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: left;
    color: var(--clr-new-exp);

}


.play-new img {

    width: 12px !important;
    height: 12px !important;
}

.play-new {
    background: #7C8DB5BF;
    border-radius: 50px;
    width: fit-content;
    padding: 15px;
    position: absolute;
    top: 100px;
    left: 100px;
    display: none;
}


.nft-item:hover .play-new {
    display: block;
}

.enews-gi-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.owl-nav {
    position: absolute;
    top: -75px;
    display: flex;
    right: 0;
    gap: 10px;
}

.owl-prev,
.owl-next {
    font-size: 35px !important;
    opacity: 50%;
}

.owl-prev:hover,
.owl-next:hover {
    background: unset !important;
    color: #000 !important;
    opacity: 100%;
}

.top-stemers {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 19.075px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.top-stream-mian {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.local-global-tab {
    display: flex;
    align-items: center;
    gap: 15px;
    /* padding: 10px; */

    padding: 7px 10px;
    border-radius: 8px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);

}

.local-global-tab button {
    color: #fff;
    text-transform: capitalize;
}

.l-active {
    border-radius: 5px !important;
    background-color: #FFF !important;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.02);

}

.insp {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ts-c1-whole {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: var(--clr-bcg);
    border-radius: 24px;
    margin: 10px 0px;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.02);
}

.ts-c1-imgdiv img {
    width: 60px;
    height: 60px;
}

.ts-c1-imgdiv {
    position: relative;
}

.milestone {
    color: var(--clr-font);
    font-size: 15.26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.jsuti {
    color: var(--neutral-800-night, #AFB6B2);
    font-size: 15.26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ts-c1-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ts-c1-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.ts-c1-right img {
    width: 28px;
    height: 28px;
}

.fav-heart {
    fill: red !important;
}

.normal-svg {
    fill: #ccc !important;
}

.fav-saved {
    fill: var(--clr-font) !important;
}

.saved-svg {
    fill: #FC5AFF !important;
}

.hr {
    width: 95%;
    height: 2px;
    flex-shrink: 0;
    margin: auto;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
}

.stream-exp-btn button {
    text-transform: capitalize;
    /* font-family: Inter; */
    font-size: 15.26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stream-exp-btn {
    text-align: center;
    background: var(--clr-bcg);
}



.stream-iner-display {
    height: auto;
    /* overflow: scroll; */
    transition: height 0.5s ease-out;
}

.exp-tran {
    height: 650px;
    overflow: scroll;
    transition: height 0.5s ease-in;
}

.ts-coun,
.ts-t-time {
    color: var(--clr-font) !important;
}

.new-trending-play {
    color: var(--clr-font);
    text-shadow: 0px 2.081px 3.121px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 23.986px;
    font-style: normal;
    font-weight: 900;
    line-height: 33.292px;
    /* 138.8% */
    letter-spacing: 0.343px;
    padding-right: 210px;
    text-transform: uppercase;
}

.enew-g1-slider {
    color: var(--clr-font);
    text-shadow: 0px 2.081px 3.121px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 13.005px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.929px;
    /* 184% */
    letter-spacing: 0.208px;
    margin: 15px 0px;
    padding-right: 210px;
}

.ntr-mian {
    position: relative;
    height: 210px;
}

.ntr1 {
    position: absolute;
    right: 25px;
    bottom: -5px;
}

.ntr1 img {
    width: 200px;
}

.premium-new {
    color: #F2F2F2;
    text-shadow: 0px 3.718px 5.578px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 44.375px;
    font-style: normal;
    font-weight: 900;
    line-height: 59.495px;
    /* 134.075% */
    letter-spacing: 0.614px;
    text-transform: uppercase;
    /* padding-right: 130px; */
}

.see-all {
    color: #F2F2F2;
    text-shadow: 0px 2.081px 3.121px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 13.135px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.929px;
    /* 182.178% */
    letter-spacing: 0.208px;
    margin: 20px 0px;
    /* text-align: center; */
}

.previ-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.pr-main {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

/* .pr3 {
    position: absolute;
} */

.previo-title {
    display: flex;
    align-items: center;
    /* flex-flow: wrap; */
    justify-content: center;
}

.seeall-new {
    text-align: right;
    font-size: 15.26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.e-news-list {
    border-radius: 24px;
    background: var(--bcg);
    box-shadow: 0px 4px 50px 15px rgba(99, 60, 247, 0.04);

}

.history-new-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 10px;
    margin: 15px 0px 0px 0px;
    border-radius: 10px;
    border-radius: 22px 22px 0px 0px;
    background: var(--clr-bcg);
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.02);
}

.history-new-left {
    display: flex;
    align-items: center;
    gap: 15px;

}

.history-new-left img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.hist-mai {
    color: var(--clr-new-exp);

    /* Headline 2 */
    /* font-family: "SF Pro Display"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
}

.maroon {
    color: var(--Secondary-Color, rgba(124, 141, 181, 0.72));

    /* Text 2 */
    /* font-family: "SF Pro Display"; */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    /* margin: 15px 0px; */
}

.history-new-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.playtime {
    border-radius: 14px;
    padding: 10px 15px;
    background: var(--clr-bcg);
    color: var(--clr-font);
    /* font-family: "SF Pro Display"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    line-height: normal;
    letter-spacing: 1px;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.06);
}

.playtime.feed-repost {
    cursor: pointer;
}

.more-histore svg {
    /* background: rgba(124, 141, 181, 0.72) !important; */
    border-radius: 25px;
}

.feed-repost svg {
    border-radius: 25px;
}

.hist-fav svg path {
    fill: #FC5AFF !important;
}

.img-list li {
    height: auto !important;
}

.img-list {
    position: relative;
}

.enew-g1-release {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-release-mxp {
    /* color: #000; */
    color: var(--clr-font);
    font-size: 23.849px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.new-release-btns {
    color: var(--branding-red-tint-300, #F3777D);
    text-align: right;
    font-size: 15.899px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.nr-artist {
    color: var(--neutral-900-night, #E4E7E5);
    font-size: 15.899px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.nft-item {
    width: 250px !important;
}

.owl-item {
    justify-content: center;
    display: flex;
}

.cirle-img img {
    /* height: auto !important; */
    cursor: pointer !important;
}


.newvolslider span.MuiSlider-rail {
    border-radius: 25.5px;
    border: 0.25px solid rgba(0, 0, 0, 0.50);
    opacity: 0.6;
    background: #175aff;
    width: 100% !important;
}

.newvolslider span.MuiSlider-track {
    border-radius: 25.5px;
    border: 0.25px solid rgba(0, 0, 0, 0.50);
    opacity: 0.6;
    background: #cc0fe0;
    width: 100% !important;
}


.newvolslider span.MuiSlider-thumb {
    background-color: #fff;
}

.mslider {
    position: absolute !important;
    bottom: 0px !important;
    right: 0;
    border-radius: 15px !important;
    padding: 0px !important;
}


.mslider span.MuiSlider-rail {
    border-radius: 25.5px;
    border: 0.25px solid rgba(0, 0, 0, 0.50);
    opacity: 0.6;
    background: #FFF;
    /* width: 10px; */
    height: 3px;
}

.mslider span.MuiSlider-track {
    border-radius: 25.5px;
    border: 0.25px solid rgba(0, 0, 0, 0.50);
    opacity: 0.6;
    background: #FFF;
    /* width: 10px; */
    height: 3px;
}


.mslider span.MuiSlider-thumb {
    display: none !important;
}

/* styles.css */


.content {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    /* height: 100%; */
    /* background-color: lightblue; */
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    display: flex;
    justify-content: center;
}



.hidden {
    animation-name: slideDownAnimation;
}

.visible {
    animation-name: slideUpAnimation;
}

.explore-more-btn2 {
    display: flex;
    /* flex-direction: column; */
    gap: 15px;
    align-items: center;
    justify-content: center;
}


.songs-fitname {
    position: absolute;
    top: 10%;
    left: 15%;
    color: #fff;
}

@keyframes slideDownAnimation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideUpAnimation {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}