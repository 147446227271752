.UploadMusic-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 33.609px;
    font-style: normal;
    font-weight: 900;
    position: sticky;
    top: 0;
    line-height: 46px;
    padding: 20px;
    background: linear-gradient(295.71deg, #5B23E1 0%, #A22FEB 100%);
    /* color: #7227e4 !important; */
    /* background-color: var(--clr-explore-head); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


.UploadMusic-box {
    background: var(--clr-explore-bg) !important;
}


.UploadMusic-content-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 27.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding-top: 10px;
    /* 136.867% */
    color: #7227e4 !important;

}

.UploadMusic-box2 .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);

}

.UploadMusic-box2 .MuiGrid-root {
    padding: 10px;
    margin: 25px 0px;

    justify-content: center;
}

.UploadMusic-box2 .upload-grid {
    padding: 100px 0px;

}

.description-UploadMusic {
    text-align: center;

    font-size: 14px;
    padding: 10px;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}



.upload-btn {
    /* display: flex !important; */
    align-items: center;
    color: var(--clr-font) !important;
    flex-direction: column;
    text-align: center !important;
    justify-content: center;
    width: fit-content;
    background: transparent !important;
    box-shadow: unset !important;
    text-transform: capitalize !important;
}


.upload-btn svg {
    font-size: 50px;
}


.upload-btn2 {
    color: var(--clr-font) !important;
    text-align: center !important;
    gap: 10px;
    width: fit-content;
    background: var(--clr-music-track) !important;
    box-shadow: unset !important;
    text-transform: capitalize !important;
    margin-top: 30px !important;
}

.upload-btn2 svg {
    font-size: 25px;
}

.upload-btn2 div {
    display: flex;
      margin-top: 30px;
}