.library-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 33.609px;
    font-style: normal;
    font-weight: 900;
    position: sticky;
    top: 0;
    line-height: 46px;
    padding: 20px;
    /* 136.867% */
    color: #7227e4 !important;
    background-color: var(--clr-explore-head);

}


.library-box {
    background: var(--clr-explore-bg) !important;
}


.library-content-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 27.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding-top: 10px;
    /* 136.867% */
    color: #7227e4 !important;

}

/* .library-box2 .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);

} */

.library-box2 .MuiGrid-root {
    /* padding: 10px; */
    /* margin: 25px 0px; */
    /* justify-content: center; */
    background-color: var(--clr-explore-head);
    border-radius: 30px;
}

.library-box2 .MuiGrid-root.MuiGrid-container {
    border-radius: 22.593px;
    border: 0.904px solid #CC0FE0;
    /* background: #F2F2F2; */
}

.description-library {
    color: var(--clr-font);
    text-align: center;
    font-family: Arial;
    font-size: 32.533px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.015px;
    padding: 10px 0px;
    margin-top: 50px;
    /* 86.111% */
    /* 125% */
}

.description-library2 {
    color: var(--clr-font);
    text-align: center;
    font-family: Arial;
    font-size: 22.593px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.47px;
    padding: 10px 0px;
    /* 117.162% */
}


.start-listen-btn1 {
    display: flex;
    justify-content: center;
}

.start-listen-btn1 button {
    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 18.593px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.496px;
    /* 104% */
    text-transform: capitalize;
    top: 50px;
    border-radius: 13.556px;
    border: 2.711px solid var(--1, #175AFF);
    background: #FFF !important;
    padding: 10px 25px;
}

.library-top-btn img {
    margin-right: 5px;
    color: var(--clr-font) !important;
}

.library-top-btn button,
.library-top-btn button:hover {
    border-radius: 32px;
    border: 1px solid #E7E6EB;
    background: #F2F2F2;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    text-transform: capitalize;
    color: #000;
    padding: 6px 10px;
    font-size: 12px;
}

.library-head-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
}


.library-btn-color {
    border-radius: 32px;
    border: 1px solid #7118B8 !important;
    background: #7118B8 !important;
    color: #fff !important;
}

.library-tab svg {
    background-color: transparent !important;
    /* color: var(--clr-font) !important; */
}

.library-tab svg path {
    fill: var(--clr-font);
}

.library-tab button.Mui-selected path {
    fill: #fff;
}

.library-tab button,
.library-tab button.Mui-selected {
    padding: 0.5px 9.98px 4.5px 9.97px;
    color: var(--clr-font);
    text-align: center;
    font-family: Arial;
    font-size: 12.797px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px;
    /* 109.402% */
    letter-spacing: 0.66px;
    min-height: 40px;
}

.library-tab button.Mui-selected {
    color: #fff;
    background-color: #8E2BE8;
    border-radius: 15px;
}

.library-tab .MuiTabs-indicator {
    background-color: #7118B8 !important;
    height: 3px;
    display: none;
}

.library-tab-box {
    width: fit-content;
    /* border: 1px solid #8E2BE8 !important; */
    margin: auto;
    border-radius: 10px;
}

.library-tab {
    align-items: center;
    padding: 2px 4px;
}

.filter-div {
    color: #000;
    font-family: Arial;
    font-size: 15.753px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #AAA7B8;
    padding: 8px;
    border-radius: 25px;
    background-color: var(--clr-filter);
}

.library-left-div {
    display: flex;
    gap: 30px;
    align-items: end;
}

.pop-over {
    border-radius: 10px;
    border: 1.125px solid #AAA7B8;
    /* padding: 30px; */
    padding: 10px 5px;
    /* background: #FFF; */
    text-align: center;
    box-shadow: 3px 4px 30px 0px rgba(0, 0, 0, 0.15);
    background-color: var(--clr-bcg2);
}

.pop-over div {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--clr-font);
    justify-content: center;
    cursor: pointer;
}

/* 
.pop-over svg{
color: #000;
} */

.pop-over-mui div.MuiPaper-root.MuiPaper-elevation {
    background-color: var(--clr-bcg2);
}

.three-dot {
    color: var(--clr-font);
    font-size: 30px !important;
}

.pop-over a {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    fill: #000;
    color: var(--clr-font);
    background: var(--clr-active);
    box-shadow: 0px 0px 6.75px 0px rgba(0, 0, 0, 0.02);
    /* margin: 15px 0px; */
    /* border-radius: 10px; */
}

.pop-over a svg {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    fill: var(--clr-font);
}

.pop-over div.non-act:hover {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    /* box-shadow: 0px 0px 6.75px 0px rgba(0, 0, 0, 0.02); */
    color: #fff;
    /* padding: 10px; */

}

.pop-over a {
    padding: 16px 70px;
}


.pop-over div svg {
    fill: #fff;
    background: transparent !important;
}

.lib img {
    width: 100%;
}

/* .lib {
    height: 80px;
} */


.lib-grid {
    margin: 0px !important;
    padding: 0px !important;
}


/* ---> */

.feed h2 {
    text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20);
    font-size: 32.906px;
    font-style: normal;
    font-weight: 900;
    background: linear-gradient(296deg, #5B23E1 0%, #A22FEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feed-head {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    background-color: var(--clr-explore-head);
    /* position: fixed;
    width: 83%; */
}

.feed-tab .active-feed.Mui-selected {
    border-radius: 8px;
    border: 1px solid #7E1BCC;
    background: #7E1BCC;
    color: #fff;
    font-weight: 600;
    border: none;
}

.feed-tab .MuiTabs-indicator {
    background: none !important;
}

.feed-tab .active-feed {
    min-height: 0 !important;
    border-radius: 8px;
    border: 1px solid #C2C0CC;
    margin-left: 15px;
    padding: 5px 10px;
    color: #AAA7B8;
    font-weight: 600;
}

.MuiContainer-maxWidthMd {
    max-width: 1014px;
}

.feed-bg {
    background: var(--clr-explore-bg) !important;
}

.music-detail {
    display: flex;
    gap: 20px;
}

.album-detail a {
    color: #858199;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 5px;
    word-break: break-word;
}

.link-badge a {
    font-weight: 500;
}

.album-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.link-badge {
    display: flex;
    gap: 10px;
}

.link-badge img {
    width: 20px;
}

.loop-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

/* .MuiSvgIcon-fontSizeMedium {
    font-size: 15px !important;
} */

.repost-loop p {
    font-weight: 500 !important;
    color: #C2C0CC;
    display: flex;
    align-items: center;
    gap: 5px;
}

.share-btn {
    color: #c2c0cc;
    display: flex;
    gap: 25px;
}

.share-btn .MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
}

.feed-music-box {
    border-radius: 8px;
    background-color: var(--clr-explore-head);
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);
    padding: 11px 16px;
}

.music-choice-box {
    margin-bottom: 20px;
}

.tab-cnt {
    margin-top: 100px;
    padding-bottom: 150px;
}

.music-timing p {
    margin: 0;
}

.album-img {
    height: 125px;
    width: 125px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);

}

.album-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.loop-times {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.music-time {
    display: flex;
    justify-content: space-between;
}

.no-music {
    display: flex;
    justify-content: center;
}

.like-dot {
    display: flex;
    justify-content: space-between;
}

.music-timing p {
    color: #C2C0CC;
}

.music-timing .MuiSvgIcon-fontSizeMedium {
    color: #C2C0CC;
}


.favourite-album-container .MuiGrid-root {
    background: none !important;
    border: unset !important;
}

.library-album-card-title {
    font-size: 14px;
    color: var(--clr-font2);
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    opacity: 80%;
}

.lib-album-img {
    margin: 15px;

}

.album-lib-typo.MuiCardContent-root {
    padding: 0px 16px !important;
    text-align: center;
}

.lib-album-para {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: var(--clr-font2);
    opacity: 60%;
}

.album-card-follow {
    display: flex;
    justify-content: center;
    padding: 0 8px !important;
    border-top: 1px solid var(--clr-font) !important;
    opacity: 60%;
}

.album-card-follow div.MuiStack-root {
    align-items: center !important;
}

.album-card-follow span {
    padding: 8px 0px 0px;
}

.album-card-follow span svg {
    width: 20px !important;
    height: 20px !important;
}

.album-card-follow div hr {
    border-color: var(--clr-font) !important;
    opacity: 60%;
}

.library-album-card-title2 svg {
    font-size: 40px !important;
    opacity: 60%;
    font-weight: 700;
}

.create-playlist-card {
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-setting {
    background-color: var(--clr-bcg) !important;
    color: var(--clr-font) !important;
}

.library-album-card-title2 div {
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    box-sizing: border-box;
    color: rgb(194, 192, 204);
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    text-align: center !important;
    padding: 0px 20px;
}

.speed-dail button,
.speed-dail button:hover {
    background: #221bc5;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.speed-dail {
    width: 30% !important;
}

.lib-stack {
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

.feed-music-main-div {
    margin: 10px 0px;
}

.filter-div svg {
    fill: #7118B8;
    font-size: 18px;
}
.library-play{
    height: calc(100vh - 300px);
    overflow: auto;
}

@media(max-width:767.98px) {
    .feed .feed-head {
        width: 100%;
        display: block;
        text-align: center;
    }

    .feed .tab-cnt {
        margin-top: 170px;
    }
}

@media(max-width:991.98px) {
    .feed .feed-head {
        width: 100%;
    }
}