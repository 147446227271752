.frame-set-child {
    height: 346px;
    width: 656px;
    position: relative;
    border-radius: var(--br-3xs);
    background-color: var(--color-gainsboro);
    display: none;
    z-index: 0;
}

.top-level-frame {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-3xs);
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    z-index: 1;
}

.designer-info-frame {
    height: 37.4px;
    width: 37.4px;
    position: relative;
    border-radius: 77.95px;
}

.kiiara-castillo {
    position: relative;
    font-weight: 500;
    color: var(--clr-font);
}

.eatsleepdesignrepeat1 {
    position: relative;
    font-size: 7.8px;
    color: var(--clr-font);
}

.kiiara-castillo-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.designer-info-frame-parent,
.eatsleepdesignrepeat,
.frame-with-rectangle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.designer-info-frame-parent {
    gap: 0 7.8px;
}

.frame-with-rectangle {
    border-radius: 4.68px;
    background-color: #2d2d2d;
    padding: var(--padding-9xs) 26px var(--padding-9xs) var(--padding-10xs);
    z-index: 2;
}

.frame-set {
    align-self: stretch;
    border-radius: var(--br-3xs);
    background-color: var(--color-gainsboro);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 13px var(--padding-mid) 288px;
    position: relative;
    text-align: left;
    font-size: 12.5px;
    color: #f9f9f9;
    font-family: var(--font-rubik);
}

.st-prize {
    position: relative;
    line-height: 25.43px;
    text-transform: capitalize;
}

.background {
    height: 45.1px;
    width: 127.8px;
    position: relative;
    border-radius: 2.51px;
    background-color: var(--color-gray-100);
    display: none;
}

.line-separator {
    position: relative;
    line-height: 25.07px;
    z-index: 1;
}

.incentivize-to-choose {
    height: 25.1px;
    width: 1px;
    position: relative;
    border-right: 1.3px solid var(--clr-font);
    box-sizing: border-box;
    z-index: 1;
}

.dster {
    width: 100%;
    border: 0;
    outline: 0;
    display: inline-block;
    font-family: var(--font-dm-sans);
    font-size: var(--font-size-mini);
    background-color: transparent;
    height: 26px;
    flex: 1;
    position: relative;
    line-height: 25.07px;
    font-weight: 700;
    color: var(--color-white);
    text-align: right;
    min-width: 23px;
    z-index: 1;
}

.dster-frame-and-line {
    flex: 1;
    flex-direction: row;
    gap: 0 11px;
}

.dster-frame-and-line input {
    color: var(--clr-font);
}

.dster-frame-and-line,
.frame-with-lines,
.mask-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.dster-frame-and-line {
    align-items: center !important;
}

.mask-group {
    align-self: stretch;
    border-radius: 2.51px;
    background-color: var(--clr-polls);
    flex-direction: row;
    padding: 7px;
    gap: 20px;
    font-size: var(--font-size-mini);
    color: var(--secondary);
}

.frame-with-lines {
    flex: 1;
    flex-direction: column;
    gap: 12px 0;
}

.nd-prize {
    position: relative;
    line-height: 25.43px;
    text-transform: capitalize;
}

.background1 {
    height: 45.1px;
    width: 127.8px;
    position: relative;
    border-radius: 2.51px;
    background-color: var(--color-gray-100);
    display: none;
}

.b {
    line-height: 25.07px;
}

.b,
.dster1,
.frame-child {
    position: relative;
    z-index: 1;
}

.frame-child {
    height: 25.1px;
    width: 1px;
    border-right: 1.3px solid var(--color-white);
    box-sizing: border-box;
}

.dster1 {
    line-height: 25.07px;
}

.line-parent {
    flex-direction: row;
    gap: 0 10px;
    text-align: right;
    color: var(--color-white);
}

.frame-with-lines1,
.kiiara-castillo-text,
.line-parent,
.mask-group1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.mask-group1 {
    border-radius: 2.51px;
    background: var(--clr-polls);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-mini) var(--padding-4xs);
    gap: 21px;
    font-size: var(--font-size-mini);
    color: var(--clr-font);
}

.frame-with-lines1,
.kiiara-castillo-text {
    flex-direction: column;
    gap: 12px 0;
}

.kiiara-castillo-text {
    width: auto;
    flex-direction: row;
    padding: 0 1px 0 0;
    box-sizing: border-box;
    gap: 0 20px;
}

.enter-numbers-only,
.event-date-and {
    position: relative;
    line-height: 26.09px;
    text-transform: capitalize;
}

.enter-numbers-only {
    line-height: 18.64px;
}

.event-date-frame,
.rd-prize {
    display: flex;
    justify-content: flex-start;
}

.rd-prize {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 2px;
    font-size: 13px;
    color: var(--critical);
}

.event-date-frame {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: 0 4px;
    font-size: 18.6px;
}

.frame-with-two-frames1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 17px 0;
    min-width: 212px;
}

.rd-prize1 {
    position: relative;
    line-height: 25.43px;
    text-transform: capitalize;
}

.background2 {
    height: 45.1px;
    width: 127.8px;
    position: relative;
    border-radius: 2.51px;
    background-color: var(--color-gray-100);
    display: none;
}

.input {
    width: 26px;
    border: 0;
    outline: 0;
    display: inline-block;
    font-family: var(--font-dm-sans);
    font-size: var(--font-size-mini);
    background-color: transparent;
    height: 26px;
    line-height: 25.07px;
    font-weight: 700;
    color: var(--secondary);
    text-align: left;
}

.dster2,
.input,
.n-a {
    position: relative;
    z-index: 1;
}

.n-a {
    height: 25.1px;
    width: 1px;
    border-right: 1.3px solid var(--color-white);
    box-sizing: border-box;
}

.dster2 {
    line-height: 25.07px;
}

.n-a-parent {
    flex-direction: row;
    justify-content: flex-start;
    gap: 0 9px;
}

.choose-frame,
.mask-group2,
.n-a-parent {
    display: flex;
    align-items: flex-start;
}

.mask-group2 {
    align-self: stretch;
    border-radius: 2.51px;
    background-color: var(--color-gray-100);
    flex-direction: row;
    justify-content: space-between;
    padding: var(--padding-3xs) 16px var(--padding-4xs) var(--padding-mini);
    text-align: right;
    font-size: var(--font-size-mini);
    color: var(--color-white);
}

.choose-frame {
    width: 155px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 27px 0 0;
    box-sizing: border-box;
    gap: 12px 0;
}

.incentivize-to {
    position: relative;
    line-height: 25.43px;
    text-transform: capitalize;
}

.background3 {
    height: 45.1px;
    width: 127.8px;
    position: relative;
    border-radius: 2.51px;
    background-color: var(--color-gray-100);
    display: none;
}

.n-a2 {
    position: relative;
    line-height: 25.07px;
    z-index: 1;
}

.n-a3 {
    height: 26px;
    flex: 1;
    font-family: var(--font-dm-sans);
    font-weight: 700;
    font-size: 12px;
    color: var(--color-white);
}

.mask-group3 {
    align-self: stretch;
    border-radius: 2.51px;
    background-color: var(--clr-polls);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-mini) var(--padding-4xs);
    gap: 10px;
    font-size: var(--font-size-mini);
    color: var(--secondary);
}

.frame-with-mask-group,
.mask-group3,
.n-a1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.n-a1 {
    width: 128px;
    flex-direction: column;
    gap: 12px 0;
}

.frame-with-mask-group {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-flow: wrap;
}

.line-end {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 45px;

    position: relative;
}

.line-end input {
    color: var(--clr-font);
    /* border: 1px solid var(--clr-font); */
    background: var(--clr-polls);
    border-radius: 10px;


}

.frame-with-two-frames,
.numbers-only-frame {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-with-two-frames {
    flex: 1;
    flex-direction: column;
    gap: 11px 0;
    margin: 10px 0px;
}

.numbers-only-frame {
    align-self: stretch;
    flex-direction: row;
    padding: 0 12px 0 var(--padding-10xs);
    text-align: left;
    font-size: var(--font-size-lg-2);
    color: var(--secondary2);
    font-family: var(--font-dm-sans);
}

.div-dropzone-1mxhs-1 {
    width: 100%;
    border-radius: 8px;
    background-color: var(--clr-bcg);
    box-shadow: 0 2px 5px -2px rgba(133, 129, 153, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px;
    border: 1px solid var(--clr-music-track);
}

.choose-polls .MuiInputBase-input.MuiInput-input {
    color: var(--clr-font);
}



.question {
    position: relative;
    line-height: 20.29px;
    text-transform: capitalize;
}

.f-r-a-m-e1,
.t-e-x-t {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.t-e-x-t {
    border: 0.7px solid var(--color-gray-200);
    /* background-color: var(--color-gray-100); */
    background: var(--clr-polls);
    height: 118px;
    width: auto;
    color: var(--clr-font);
    outline: 0;
    border-radius: var(--br-7xs-8);
    box-sizing: border-box;
    flex-direction: row;
    padding: 10px;
    font-family: var(--font-dm-sans);

}

.f-r-a-m-e1 {
    flex-direction: column;
    gap: 8px 0;
}

.answer-type {
    position: relative;
    line-height: 20.29px;
    text-transform: capitalize;
}

.background {
    height: 35px;
    width: 361px;
    position: relative;
    border-radius: var(--br-7xs-8);
    background-color: var(--color-gray-100);
    border: 0.7px solid var(--color-gray-200);
    box-sizing: border-box;
    display: none;
}

.a,
.default-yes {
    position: relative;
    line-height: 20.29px;
    z-index: 1;
}

.f-r-a-m-e4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 0 9px;
    cursor: pointer;
}

.f-r-a-m-e5,
.l-i-n-e {
    height: 40px;
    box-sizing: border-box;
}

.f-r-a-m-e5 {
    width: 1px;
    position: relative;
    border-right: 1px solid var(--color-white);
    z-index: 1;
}

.l-i-n-e {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0 34px 0 0; */
}

.b,
.default-no {
    position: relative;
    line-height: 20.29px;
    z-index: 1;
}

.f-r-a-m-e,
.f-r-a-m-e2,
.f-r-a-m-e3 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.f-r-a-m-e3 {
    background: var(--clr-polls);
    color: var(--clr-font) !important;
    border: 0.7px solid var(--color-gray-200);
    flex-direction: row;
    align-items: flex-end;
    /* padding: 10px; */
    gap: 0 15px;
    width: max-content;
    margin: auto;
    border-radius: 8px;
}

.f-r-a-m-e,
.f-r-a-m-e2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px 0;
}

.f-r-a-m-e {
    padding: 0 var(--padding-sm);
    gap: 20px 0;
    text-align: left;
    font-size: var(--font-size-mini-5);
    color: var(--secondary2);
    font-family: var(--font-dm-sans);
    margin: 15px 0px;
}

.button {
    width: 220px;
    height: 37px;
}

.nft-tab-style.polls button {
    padding: 0px 40px;
}

.start-end-time-main input {
    color: var(--clr-font);
}

.question-type {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
}

.question-type input {
    border: 1px solid var(--clr-font);
    border-radius: 10px;
    padding: 10px;
    color: var(--clr-font);
}

.button.polls {
    position: sticky;
    bottom: 0;
}

.div-dropzone-1mxhs-1.polls {
    height: 500px;
    overflow-y: auto !important;
    justify-content: space-between !important;
}

.div-dropzone-1mxhs-1.polls::-webkit-scrollbar {
    display: none;
}

.button.addmore {
    width: 150px;
    margin-top: 10px;
}

.button.addmore svg {
    fill: #fff;
}

.div-dropzone-1mxhs-1.history {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;
    gap: 15px;

}

.div-dropzone-1mxhs-1.history:hover {
    transform: scale(1.03);
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) */
}

.div-dropzone-1mxhs-1.history.survey-attc {
    transform: none;
}


.histry-title {
    color: var(--clr-font) !important;
    max-width: 250px;
}

.histry-title span {
    margin-right: 5px;
}

.progress-yes .MuiTypography-root {
    color: var(--clr-font);
}

.progress-yes svg {
    color: #fc5aff !important;
}

.progress-yes {
    display: flex;
    align-items: center;
    gap: 10px;
}

.survey-check-main,
.survey-check-main .MuiFormControl-root {
    width: 100%;
}

.survey-check-main .MuiFormGroup-root {
    justify-content: space-evenly;
}

.survey-attc {
    margin: 10px 0px;
}

.back-icon-survey-hist {
    position: fixed;
    right: 0;
    top: 0;
}

.back-icon-survey-hist svg {
    font-size: 30px;
}


.polls-table thead {
    background: var(--clr-bcg);
}

.polls-table th {
    color: var(--clr-font);
}

.survey-questions {
    height: 500px;
    overflow: auto;
}

.survey-questions::-webkit-scrollbar {
    display: none;
}

.survey-check-main span.MuiTypography-root {
    color: var(--clr-font);
}

.disagree svg {
    fill: red !important;
}

.agree svg {
    fill: rgba(47, 255, 57, 0.863) !important;
}

.strong-agree svg {
    fill: green !important;
}

.tootip-main {
    color: var(--clr-font) !important;
    display: var(--flex);
    align-items: center;
    gap: 15px;
}

.polls-table {
    background-color: var(--clr-bcg) !important;
}

.chips .MuiChip-root {
    background-color: #ccc;
}

.chips {
    text-align: center;
    margin: 15px 0px;
}

.MuiTable-root {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid #ccc;
}

/* .MuiTable-root tr {
    background-color: #ccc;
} */

.custom-panel .MuiBox-root {
    padding: 0px !important;
}