html {
    scroll-behavior: smooth;
}

#header-middle-text a.active {
    background: linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%) !important;
    -webkit-background-clip: text !important;
    /* margin-left: 5px; */
    -webkit-text-fill-color: transparent;

}

div#header-middle-text {
    justify-content: center !important;
}

.bg-appbar,
.bg-appbar div {
    background-color: var(--clr-bcg7) !important;
    box-shadow: unset !important;
}

div#header-middle-text a {
    color: var(--clr-upload-font);
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    padding: 6px 15px;
    text-decoration: none;
}

.login-btn {
    display: flex;
    gap: 20px;
    align-items: center;
}

.login-btn a {
    min-width: 136px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    gap: -4px;
    text-transform: initial;
    color: #FC5AFF !important;
    border: 1px solid #FC5AFF;
    border-radius: 12px;
    padding: 8px;

    color: var(--Neutral-White, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}

.login-btn a.active {
    border-radius: 12px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    color: #FFF !important;
    border: 0;
}

.header-logo {
    margin: auto;
    text-align: center;
    padding: 15px 0px;
    cursor: pointer !important;
}

.header-logo img {
    width: 160px;
    height: 40px;
}

.avatar-cont2 {
    padding: 8px;
    /* margin-top: 20px; */
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: initial;
    gap: 15px;
    color: #858199;
    font-family: Arial;
    font-size: 13.344px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 149.883% */
}

.go-violet2 {
    color: #7E1BCC;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

/* 

.login-btn {
    display: flex;
    gap: 20px;
} */

.login-btn button {

    min-width: 136px;
    padding: 8px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    gap: -4px;
    text-transform: capitalize;
    color: #FC5AFF !important;
    border: 1px solid #FC5AFF;
    border-radius: 12px;
}

.login-btn button.active {
    border-radius: 12px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    color: #FFF !important;
}

.menuCloseBtn {
    display: none;
}

.home .header header::before,
.fanzone .header header::before,
.player .header header::before {
    content: '';
    position: absolute;
    /* width: 100%; */
    content: '';
    position: absolute;
    width: 186%;
    height: 173%;
    filter: blur(23px);
    top: -42px;
    background: var(--clr-header-bg);
    opacity: 0.7;
    left: -60px;
}

.header header>.MuiContainer-root {
    padding-left: 50px;
    padding-right: 50px;
}

div#header-middle-text a:hover {
    background: linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%) !important;
    -webkit-background-clip: text !important;
    /* margin-left: 5px; */
    -webkit-text-fill-color: transparent;
}

body.scrolled .home .header header::before,
body.scrolled .fanzone .header header::before,
body.scrolled .player .header header::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(0px);
    top: 0;
    background: var(--clr-header-bg);
    opacity: 1;
    left: 0;
}


.profile-popup {
    padding: 10px 0px;
    text-align: center;
    background: var(--clr-bcg);
    border-radius: 10px;
}

.profile-pop-compo .MuiPopover-paper {
    background: var(--clr-bcg) !important;
}

.popup-content-profile {
    padding: 15px 0px;
}

.popup-content-profile div {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 60px;
    margin: 15px 0px;
    cursor: pointer;
    color: var(--clr-font) !important;
}

.popup-content-profile div:hover {
    background-color: var(--clr-music-track);
}

.popup-content-profile div:hover,
.popup-content-profile div:hover svg {
    fill: #fff;
    color: #fff !important;
}

.profile-img-inner.inner-dp {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 5px;
    /* border-bottom: 1px solid var(--clr-font); */
}

.popup-content-profile div svg {
    background-color: unset !important;
    fill: var(--clr-font);
    /* color: #fff; */
}

@media (max-width:899.98px) {

    .header header .header-logo {
        margin: 0px;
    }

    .header header>.MuiContainer-root>.MuiToolbar-root {
        flex-direction: column;
        align-items: start;
    }

    .class-for-mobile-header>a,
    .class-for-mobile-header>div {
        display: block !important;
    }

    .header header .header-logo {
        text-align: left;
    }

    .class-for-mobile-header>a,
    .class-for-mobile-header>div {
        display: block !important;
    }

    .header header .header-logo {
        text-align: left;
    }

    div#header-middle-text {
        display: flex !important;
        flex-direction: column;
        align-items: start;
        margin: 20px 0 20px;
        gap: 8px;
    }

    .class-for-mobile-header {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        padding: 30px;
        background: #000 !important;
        z-index: 9999 !important;
    }

    .bg-appbar div {
        background: transparent !important;
    }

    .bg-appbar .class-for-mobile-header {
        background: var(--clr-respo-header-bg) !important;
        transition: all 0.3s ease-in-out;
        transform: translateX(-100%);
    }

    .creator .bg-appbar .class-for-mobile-header {
        background: var(--clr-bcg) !important;
        transition: all 0.3s ease-in-out;
        transform: translateX(-100%);
    }



    div#openmenuid {
        transform: translateX(0) !important;
    }

    div#header-middle-text a {
        padding-left: 0px;
    }

    .menuCloseBtn {
        display: block !important;
        text-align: right;
        width: 88%;
    }

    .menuCloseBtn svg,
    .toggle-btn {
        font-size: 30px;
        fill: var(--clr-font) !important;
        background: none !important;
    }

    .login-btn {
        flex-wrap: wrap !important;
        gap: 5px;
        /* min-width: 100px; */
    }

    .login-btn a {
        min-width: 115px;
    }

    .header header>.MuiContainer-root {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    button.MuiButtonBase-root.menuOpenBtn {
        padding: 0px !important;
    }

}

@media (min-width:900px) {

    .login-btn a {
        min-width: 90px !important;
    }

    .class-for-mobile-header {
        width: 100%;
        display: flex;
        align-items: center;
    }

}

@media (max-width:1199.98px) {

    .footer .MuiGrid-root.MuiGrid-container {
        padding: 24px;
    }

}

@media (min-width:900px) and (max-width:1199.98px) {

    div#header-middle-text a {
        padding: 8px 10px !important;
    }

}

.dark-mode svg {
    background-color: unset !important;
}




div#myprofile-head header.bg-appbar {
    background: var(--clr-bcg) !important;
}

.betalogo img {
    width: 70px;
    height: 70px;
}