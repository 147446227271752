.innermnt {
    background: var(--clr-bcg4);
    border-radius: 10px;
    padding: 15px;
    width: fit-content;
    gap: 30px;
    margin-left: auto;
}

.the {
    color: var(--clr-font) !important;
    /* font-family: Inter; */
    font-size: 14.962px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.299px;
}

.crrpr {
    color: #A7A6A4;
    font-size: 12.091px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 5px;
    letter-spacing: 0.262px;
}

.salesend {
    color: var(--clr-font);
    font-size: 14.962px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.299px;
}

.hrs {
    color: #A7A6A4;
    /* font-family: Inter; */
    font-size: 13.091px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.262px;
    margin-top: 2px;
}

.lyce {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    margin: 10px 0;
}

.lyric-liner {
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.32px;
    text-transform: capitalize;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.lyric-liner span {
    color: var(--clr-font) !important;
    -webkit-text-fill-color: var(--clr-font);
}

.asakod img {
    width: 100%;
    height: auto;
}

.prev-img-mnt img {
    width: 280px;
    height: 280px;
}

.prev-img-mnt {
    padding: 10px;
    text-align: center;
}

.cpyright {
    /* font-family: Inter; */
    font-size: 11.351px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.187px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
    width: fit-content;
}

.cpy-desc {
    color: var(--clr-font);
    /* font-family: Inter; */
    font-size: 11.351px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.187px;
}

.mint-sd {
    background: var(--clr-bcg4);
    padding: 10px;
    border-radius: 10px;
    margin: 15px;
}

.flow button {
    border-radius: 20px;
    color: var(--clr-font);
    font-size: 10px;
    font-weight: 600;
    border: 1px solid var(--clr-font);
}

.con-cov {
    align-items: flex-start;
}