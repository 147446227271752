.creator .page-cnt-center .gallery {
  text-align: center;
}

.creator .page-cnt-center p.txt-sucess {
  color: #7e1bcc;
  margin-top: 10px;
}

.errordiv {
  color: red;
  text-align: left;
  font-size: 12px;
}

.creator .page-cnt-center h2.text-white {
  margin-bottom: 0;
  color: #858199;
}

.creator .upload-card-box {
  display: flex;
  justify-content: center;
}

.creator .upload-card-box {
  max-width: 946px;
  margin: 10px auto;
  text-align: center;
}

.creator .upload-cloud {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 19.8px 8px 21px;
  align-items: center;
}

.creator button.btn.btn-outline-succes {
  border: 1px solid var(--1, #175aff) !important;
  border-radius: 4px;
  background: #fff !important;
  border: 1pxx solid;
  border-radius: 4px;
  /* background: transparent; */
  cursor: pointer;
}

.creator button.btn.btn-outline-success:hover {
  border-radius: 4px;
  background: var(--1,
      linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%)) !important;
  border: 1pxx solid;
  border-radius: 4px;
  border: 1px solid var(--1, #175aff);
  /* background: transparent; */
  cursor: pointer;
}

.creator .music-banner img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* .creator-box {
  background: var(--clr-creator-bg);
} */

.creator .card_cus-file {
  background: #fff;
  box-shadow: 10px 10px 20px -5px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 15px 15px 0 0;
}

.creator .whole-cnt,
.new-top .whole-cnt {
  max-width: 1020px;
  margin: 0 auto;
  margin-top: 80px;
}

.creator .page-cnt-center {
  margin: 92px 0;
}

.creator .music-up.border-end h5 {
  margin-bottom: 0;
  color: #858199;
  font-size: 15px;
}

.creator p.text-wrap-file {
  max-width: 410px;
  margin: 15px 30px;
  color: #858199 !important;
}

.start-music {
  color: #cc0fe0;
  font-size: 16px;
  font-weight: 600;
}

.start-music:hover {
  color: #fff;
}

.creator .link-direct {
  border-radius: 4px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  width: 40%;
}

.music-up.border-end {
  text-align: -webkit-center;
}

.creator .link-direct a {
  color: #fff;
  text-decoration: none;
}

/* @media(min-width:578px) and (max-width:767.98px){
    .upload .d-create-file input {
        width:50% !important;
    }
} */

@media (min-width: 768px) and (max-width: 991.98px) {
  .creator .link-direct {
    width: 54%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .creator .link-direct {
    width: 60%;
  }
}

/* upload */
.upload ul.de_nav {
  list-style: none;
  display: flex;
  gap: 50px;
  padding-left: 0;
  cursor: pointer;
}

.upload .de_tab.tab_methods .de_nav li.active span {
  border-radius: 32px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  padding: 9px 20px;
  color: #fff;
}

.upload .de_tab_content input {
  padding: 8px 38px 8px 13px;
  /* height: 46px !important; */
  background: transparent;
  border: 1px solid #cdcd;
  width: 85% !important;
  color: var(--clr-upload-font);
  font-size: 16px;
}

.upload .form-border textarea {
  padding: 8px 38px 8px 13px;
  background: transparent;
  border-radius: 10px;
  color: var(--clr-upload-font);
  width: 100%;
}

.upload .upload-bg {
  background: var(--clr-explore-bg) !important;
}

.upload .d-create-file.minnft-innertab {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 250px;
  /* height: 250px !important; */
  /* padding: 20px; */
  width: 250px;
}

.profile-img-outer1 {
  top: 6%;
  position: relative;
}

.album-tab {
  margin: 30px 0px;
}

h5.h5-custom2 {
  min-height: 30px;
}

.upload .d-create-file {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 530px;
  padding: 20px;
  width: 280px;
  margin: auto;
}

.upload .description-part {
  margin-bottom: 20px;
}

.upload .field-set h5 {
  font-size: 20px;
  margin-top: 6px;
  color: var(--clr-upload-font);
}

.upload .de_tab_content {
  /* max-width: 938px; */
}

.upload .de_tab_content p {
  color: var(--clr-upload-font);
  margin-top: 30px;
}

input:focus-visible {
  outline: none;
}

.upload .d-create-file input {
  width: 100%;
  border-radius: 5px;
  color: var(--clr-upload-font);
}

.upload .btn.btn-upload {
  border-radius: 32px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  color: #fff;
  margin-top: 100px;
  padding: 7px 25px;
}

.upload .de_tab_content input::placeholder {
  color: var(--clr-upload-font);
}

.upload .set-date {
  display: flex;
  flex-direction: column;
}

.upload .set-date label,
.upload .de_tab_content_2 p {
  color: var(--clr-upload-font);
}

.upload .date-input {
  width: 50%;
  background: transparent;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  padding: 10px;
  color: var(--clr-upload-font);
}

.details-collection input {
  background: transparent;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  padding: 10px;
  color: var(--clr-upload-font);
}

.upload .date-input::placeholder {
  color: var(--clr-upload-font);
}

.upload .switch-with-title {
  display: flex;
  align-items: baseline;
  gap: 50px;
}

.upload select#collectionName {
  padding: 10px 20px;
  background: transparent;
}

.upload .cancel-btn a {
  border-radius: 20px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  padding: 5px 10px;
  color: #fff;
}

.upload .cancel-btn {
  margin-top: 20px;
}

.upload .select-method {
  border-radius: 8px;
  background: var(--clr-bcg);
  box-shadow: 0px 2px 5px -2px rgba(133, 129, 153, 0.25);
  padding: 30px;
  max-width: 1400px;
  /* white-space: nowrap; */
  margin: 0 auto;
}

.upload ul.de_nav li {
  color: var(--clr-upload-font);
}

.field-set .MuiSvgIcon-fontSizeMedium {
  color: var(--clr-upload-font);
}

.upload select.form-control {
  color: var(--clr-upload-font);
}

.upload .find-collection-modal {
  position: fixed;
  background: none;
  visibility: visible;
  top: 50%;
  display: none;
  border: 1px solid blue;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 545px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  background: var(--clr-bcg);
  padding: 16px;
  z-index: 999;
  text-align: center;
  color: var(--clr-upload-font);
}

.upload .select-input {
  /* padding: 8px 38px 8px 13px; */
  background: transparent;
  width: 95%;
  color: var(--clr-upload-font);
  font-size: 16px;
  border-radius: 25px !important;
  margin-bottom: 0;
}

h5.h5-custom {
  min-height: 70px;
}

.upload .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--clr-upload-font);
  border-radius: 25px;
  border: 1px solid #cdcd;
}

.select-coin .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--clr-upload-font);
  border: 1px solid #cdcd;
  border-radius: 10px;
}

.select-coin .MuiOutlinedInput-input {
  padding: 7px 14px;
}

.upload .MuiOutlinedInput-input {
  outline: none;
  padding: 7px 14px !important;
}

.upload .MuiOutlinedInput-input li {
  padding: 0px !important;
}

.upload .MuiInputBase-root.MuiOutlinedInput-root:focus-visible {
  outline: none !important;
}

.upload .de_tab_content_2 {
  margin-top: 50px;
}

.collection-para {
  font-size: 25px;
  font-weight: 700;
  color: var(--clr-upload-font);
}

.details-collection button,
.new-collection button {
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  color: #f2f2f2;
}

.collection-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: var(--clr-bcg) !important;
  border-radius: 6px;
}

.des-para,
.des-para a {
  color: var(--clr-font);
}

@media (max-width: 767.98px) {
  .creator .upload-card-box {
    display: block;
  }

  .upload .de_tab_content p {
    font-size: 20px;
  }

  .creator .page-cnt-center {
    margin-top: 30px;
  }

  .upload .find-collection-modal {
    width: 100%;
  }

  .collection-popup {
    width: 92% !important;
  }

  .details-collection input {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .upload .d-create-file input {
    width: 100% !important;
  }

  .creator .link-direct {
    width: 66%;
    white-space: nowrap;
  }
}

.preview-img:hover .edit1 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-genre {
  opacity: 100% !important;
}

.preview-img .edit1 {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  display: none;
  background: #373636ad;
  padding: 4px;
  /* border-radius: 70px; */
  width: 220px;
  height: 250px;
  /* line-height: 4; */
  text-align: center;
}

.d-create-file.minnft {
  position: relative;
}

.profile-img-outer.minnft {
  position: absolute;
  bottom: 0 !important;
  left: 8%;
}

#file_name-preview {
  margin-top: 300px;
}

.form-control::placeholder {
  opacity: 0.38;
}

.select-input .MuiInputBase-root.MuiOutlinedInput-root {
  width: 89%;
}

.library-button button {
  border-radius: 4px;
  border: 1px solid var(--1, #175aff);
  background: #f2f2f2;
}

.library-button button:hover svg {
  fill: #fff !important;
}

.audio-div {
  margin-top: 25px;
}

/* .creator .dark-mode {
    display: none;
} */

.creator .bg-appbar,
.creator .bg-appbar a {
  background-color: var(--clr-bcg) !important;
  color: var(--clr-font) !important;
}

.creator div#header-middle-text a {
  color: #000;
}

.creator svg.MuiSvgIcon-root {
  fill: var(--clr-font);
  background-color: unset !important;
}

.t1 svg {
  fill: #cc0fe0 !important;
}

.preview-div {
  color: var(--clr-font) !important;
  margin-top: 200px;
}

.hedaer-bg header {
  background-color: var(--clr-bcg) !important;
}

.mint-nft-tab .MuiTabs-scroller.MuiTabs-fixed {
  border: 1px solid var(--clr-font);
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 20px !important;
}

.mint-nft-tab {
  width: 350px;
}

.mint-nft-tab button,
.mint-nft-tab button.MuiTab-root.Mui-selected {
  text-transform: capitalize;
  color: var(--clr-font);
  min-height: 4px;
  border-radius: 10px;
}

.mint-nft-tab button.MuiTab-root.Mui-selected svg {
  color: #fff !important;
  background-color: unset !important;
}

.mint-nft-tab button.MuiTab-root.Mui-selected {
  background-color: #cc0fe0;
  color: #fff !important;
}

.mint-nft-tab span.MuiTabs-indicator {
  display: none;
}

.upload-mint-music-tab-whole-div {
  border: 1px solid var(--clr-font);
  border-radius: 15px;
  padding: 20px 20px !important;
  /* margin-bottom: 10px; */
}

.upload-mint-music-tab-whole-div .MuiBox-root {
  padding: 0px !important;
}

.back-arrow {
  color: var(--clr-upload-font);
  position: sticky;
  top: 100px;
  z-index: 999;
  margin-left: -25px;
  background: var(--clr-upload-box-bg);
  padding: 2px;
  border-radius: 15px;
  margin-left: -25px;
}

.required-inner-fields {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.put-on-sale-div {
  color: var(--clr-font);
  padding: 10px;
  opacity: 50%;
  font-size: 14px;
}

.put-on-sale-description {
  font-size: 14px;
  opacity: 50%;
}

.radio-div span {
  color: var(--clr-font) !important;
}

.radio-div svg {
  color: #175aff !important;
}

[data-testid="RadioButtonUncheckedIcon"] {
  background: none !important;
  fill: var(--clr-font) !important;
}

[data-testid="RadioButtonCheckedIcon"] {
  background: none !important;
  fill: #175aff !important;
  /* color: #000 !important; */
}

/* .submit-btn button {
    width: auto;
} */
.submit-btn.mint button {
  width: auto;
}

.profile-banner-size.bg-bug {
  background: unset !important;
}

.creator .page-cnt-center h2.text-white.new {
  margin-bottom: 0;
  color: #858199;
  margin: 20px 50px;
  font-size: 18px;
  text-align: center;
}

.music-up.border-end li {
  text-align: left;
}

.ui-desc {
  padding: 15px 40px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 300px;
  overflow-y: scroll;
  margin: 10px;
}

.creator::-webkit-scrollbar {
  display: none !important;
}

.preview-img img {
  object-fit: contain !important;
}

.profile-img {
  width: fit-content;
  margin: auto;
}