.header-logo {
    margin: auto;
    text-align: center;
    padding: 15px 0px;
}

.header-logo img {
    width: 180px;
    height: 50px;
}

.discover a.active,
.discover a.active h4 {
    color: #CC0FE0 !important;
}

/* .sidebar .MuiPaper-root.MuiPaper-elevation,
.sidebar .MuiPaper-root.MuiPaper-elevation {
    background: transparent !important;
    color: #FFF !important;
} */

.avatar-cont {
    padding: 20px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: initial;
    gap: 15px;
    color: #858199;
    font-family: Arial;
    font-size: 13.344px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 149.883% */
}

.go-violet2 {
    color: #7E1BCC;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.discover h4 {
    color: #C2C0CC;
    font-family: Arial;
    font-size: 16.875px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    /* 130.37% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--clr-font);
}

/* .discover .activediv{
    padding: 2px 5px;
    border-left: 3px solid #FC5AFF !important;

} */

.discover {
    color: #6A677A;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 15px;
    line-height: 14px;
    /* 100% */
    padding: 0px 40px;
}

.discover a {
    color: #6A677A;
}

.discover a.active {
    color: #CC0FE0 !important;
    position: relative;
}

.discover a.active:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: -57px;
    border-radius: 4px;
    border-right: 4px solid #CC0FE0;
}

.sidebar-btn {
    display: flex;
    justify-content: end;
    height: 10vh;
    flex-direction: column;
    align-items: center;
}

.loading-div {
    height: 7vh;
}

.sidebar-btn img {
    margin-right: 5px;
}

.sidebar-btn a {
    padding: 8px 30px;
    border-radius: 4px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    color: #F2F2F2;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    /* 100% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
}


.bg-appbar2,
.bg-appbar2 div {
    background-color: var(--clr-explore-head) !important;
    box-shadow: unset !important;
}

.svg-icon {
    color: var(--clr-font);
}

/*  Header2 */

.responsive-drawer {
    display: none;
}

.dark-mode {
    padding: 0px 20px;
    /* margin-top: 25px; */
    /* text-align: center; */
}



input#search-input,
button#search-input svg {
    color: var(--clr-font) !important;
}

.dark-mode .MuiIconButton-root {
    color: var(--clr-font) !important;
}

.profile-img-inner:hover .edit {
    display: block !important;
}

.profile-img-inner img:hover {
    filter: blur(1px);
}


.profile-img-inner .edit {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #fff;
    display: none;
    background: #373636ad;
    padding: 10px;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    line-height: 4;
    text-align: center;
}




.profile-img-inner {
    position: relative;
}

/* .access-svg {
    width: fit-content;
}
.access-svg svg{
color: var(--clr-font);
} */


/* .sidebar-header-div img{
    background-color: var(--clr-font);
    border-radius: 20px;
} */
.sidebar-header-div {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
}



.pl {
    display: block;
    margin: auto;
    width: 16em;
    height: auto;
}

.pl line {
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.pl__line1,
.pl__line9 {
    animation-name: line1;
}

.pl__line2,
.pl__line8 {
    animation-name: line2;
}

.pl__line3,
.pl__line7 {
    animation-name: line3;
}

.pl__line4,
.pl__line6 {
    animation-name: line4;
}

.pl__line5 {
    animation-name: line5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 90%, 10%);
        --fg: hsl(var(--hue), 90%, 90%);
    }
}

/* Animations */
@keyframes line1 {

    from,
    8% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    18% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    28% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    38% {
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    48% {
        opacity: 1;
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    53% {
        opacity: 0;
        stroke-dashoffset: 31.99;
        transform: translate(8px, 16px);
    }

    56% {
        animation-timing-function: steps(1, start);
        opacity: 0;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    60% {
        animation-timing-function: ease-out;
        opacity: 1;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    70% {
        animation-timing-function: ease-in-out;
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    80% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    90% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    to {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }
}

@keyframes line2 {

    from,
    6% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    16% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    26% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    36% {
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    46% {
        opacity: 1;
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    51% {
        opacity: 0;
        stroke-dashoffset: 31.99;
        transform: translate(8px, 16px);
    }

    54% {
        animation-timing-function: steps(1, start);
        opacity: 0;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    58% {
        animation-timing-function: ease-out;
        opacity: 1;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    68% {
        animation-timing-function: ease-in-out;
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    78% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    88% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    98%,
    to {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }
}

@keyframes line3 {

    from,
    4% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    14% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    24% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    34% {
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    44% {
        opacity: 1;
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    49% {
        opacity: 0;
        stroke-dashoffset: 31.99;
        transform: translate(8px, 16px);
    }

    52% {
        animation-timing-function: steps(1, start);
        opacity: 0;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    56% {
        animation-timing-function: ease-out;
        opacity: 1;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    66% {
        animation-timing-function: ease-in-out;
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    76% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    86% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    96%,
    to {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }
}

@keyframes line4 {

    from,
    2% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    12% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    22% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    32% {
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    42% {
        opacity: 1;
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    47% {
        opacity: 0;
        stroke-dashoffset: 31.99;
        transform: translate(8px, 16px);
    }

    50% {
        animation-timing-function: steps(1, start);
        opacity: 0;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    54% {
        animation-timing-function: ease-out;
        opacity: 1;
        stroke-dashoffset: 32;
        transform: translate(0, 16px);
    }

    64% {
        animation-timing-function: ease-in-out;
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    74% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    84% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    94%,
    to {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }
}

@keyframes line5 {
    from {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    10% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    20% {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    30% {
        stroke-dashoffset: 0;
        transform: translate(0, 0);
    }

    40% {
        stroke-dashoffset: -16;
        transform: translate(0, 15px);
    }

    50% {
        stroke-dashoffset: -31;
        transform: translate(0, -48px);
    }

    58% {
        stroke-dashoffset: -31;
        transform: translate(0, 8px);
    }

    65% {
        stroke-dashoffset: -31.99;
        transform: translate(0, -24px);
    }

    71.99% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -31.99;
        transform: translate(0, -16px);
    }

    72% {
        animation-timing-function: ease-in-out;
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }

    82% {
        stroke-dashoffset: 16;
        transform: translate(0, 8px);
    }

    92%,
    to {
        stroke-dashoffset: 31.99;
        transform: translate(0, 16px);
    }
}


div#search-wrap {
    border-radius: 8px;
    background: rgba(40, 40, 40, 0.80);
    box-shadow: -5px 7px 12px 0px rgba(0, 0, 0, 0.15) !important;
}

.new-span {
    color: #B6B3C2;
    text-align: center;
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 200% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-radius: 8px;
    background: #F2F2F4;
    padding: 4px 6px;
}

.loading-text {
    color: var(--clr-font);
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.43px;
}

.upload-track {
    border-radius: 4px !important;
    padding: 4px 16.8px 4px 17px !important;
    background: rgba(40, 40, 40, 0.80) !important;
    box-shadow: -5px 7px 12px 0px rgba(0, 0, 0, 0.15);
    text-transform: capitalize !important;

}

.upload-track svg {
    padding-right: 5px !important;
}

.hav-acc {
    display: flex;
    gap: 20px;
}

.dark-mod-btn button svg {
    font-size: 25px !important;
}

.back-svg {
    fill: #7E1BCC !important;
    font-size: 35px !important;
    cursor: pointer;
}

.back-svg-div svg {
    display: flex;
    align-items: center;
    /* margin-top: 5px; */
}

.search-inputs {
    width: 85%;
}

.more-svg svg,
.notification-svg svg {
    font-size: 25px !important;
    padding: 3px;
    border-radius: 25px;
    background: var(--clr-notify) !important;
    cursor: pointer;
}

.more-svg svg:hover,
.notification-svg svg:hover {
    font-size: 25px !important;
    padding: 3px;
    border-radius: 25px;
    background: #CC0FE0 !important;
    fill: #fff;
    cursor: pointer;
}

.more-usdt-img img {
    width: 25px;
    padding-right: 5px;
}

.more-usdt-img {
    border-radius: 15px;
    padding: 5px;
    display: flex;
    align-items: center;

}

.playlist-more-div:hover .more-usdt-img {
    background: #fff !important;
    color: #000 !important;
}

.message-popover .MuiPaper-root.MuiPaper-elevation {
    width: 428px;
    height: 450px;
    background: var(--clr-bcg);
    border-radius: 12px;
}

.noftigy-title-fixed {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)) !important;
    color: #fff !important;
    padding: 12px !important;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 18px;
    font-weight: 900;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 15px 15px 0 0;
}

.noftigy-title-fixed svg {
    fill: #fff;
}

/* .msg-pop-inner-main-div{
    overflow-y: scroll;
    padding: 15px;
} */

.msg-content-mian-div {
    padding: 30px 20px;

}

.new-release {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #CC0FE0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
}

.new-release svg {
    fill: #CC0FE0;
}

.msg-content-div {
    /* border: 1px solid var(--clr-font); */
    border-radius: 10px;
    padding: 15px 30px;
    margin: 15px 0px;
    transition: all .12s ease-in-out;
    box-shadow: 0 0 1px -2px #8581991a, 0 1px 0 2px #e3e3e3, 1px 2px 5px #85819940;
}



.msg-content-div:hover {
    cursor: pointer;
    box-shadow: 0 0 1px -2px #8581991a, 0 1px 0 2px #e3e3e3, 1px 2px 5px #85819940;
    transform: scale3d(1.015, 1.015, 1.015);
}


.msg-avat-content-main-div {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 15px 0px;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.artist-angry-baby {
    color: #CC0FE0;
    font-size: 15px;
    font-weight: 600;

}

/* .artist-angry-baby:hover {
    text-decoration: underline;
} */


.artist-angry-baby span {
    color: #858199;
    margin-left: 8px;
}

.msg-artist-post-time {
    color: #C2C0CC;
    font-size: 12px;
    font-weight: 600;
}