.uploadnew {
    background: var(--clr-pcnew);
    border-radius: 10px;
    margin: 15px auto;
    width: 90%;
    height: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.crUpload {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    padding: 15px;
}

.dragndrop {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    padding: 5px;
    text-transform: capitalize;
}

.browseupload {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 500;
    line-height: 25.52px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: capitalize;
    margin: auto;
    width: fit-content;
    padding: 5px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.uploaded-music {
    background: var(--clr-upload);
    border-radius: 8px;
    padding: 15px;
    margin: 20px auto;
    /* width: 60%; */
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.upsongname,
.uploaded-size {
    /* font-family: Inter; */
    font-size: 13px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.dropzone {
    /* border: 2px dashed #ccc; */
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin: 20px auto;
}

.dragndrop {
    font-size: 16px;
    margin: 15px 0px;
}

.browseupload {
    font-size: 14px;
    color: #888;
}

.closeup {
    position: absolute;
    top: 0px;
    right: 2px;
    /* font-size: 24px; */
    cursor: pointer;
}

.dragndrop.cond {
    width: 40%;
    margin: 20px auto;
}

.upload-next-btn button {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    color: #FFFFFF;
    padding: 10px 25px;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    width: 200px;
    height: 48px;
    text-transform: capitalize;
}

.spinner {
    background-image: linear-gradient(rgb(186, 66, 255) 35%, rgb(0, 225, 255));
    width: 100px;
    height: 100px;

    animation: spinning82341 1.7s linear infinite;
    text-align: center;
    border-radius: 50px;
    filter: blur(1px);
    box-shadow: 0px -5px 20px 0px rgb(186, 66, 255), 0px 5px 20px 0px rgb(0, 225, 255);
}

.spinner1 {
    background-color: rgb(36, 36, 36);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    filter: blur(10px);
}

@keyframes spinning82341 {
    to {
        transform: rotate(360deg);
    }
}

.hover-img {
    position: relative;
    margin: auto;
    width: fit-content;
}


.hover-img .zoom-text {
    position: absolute;
    z-index: 100;
    left: 23%;
    top: 40%;
}

.hover-img img {
    position: absolute;
    z-index: 100;
    left: 30%;
    top: 30%;
}

.upload-next-btn {
    margin-top: 120px;
}

.zoom-text {
    display: inline-block;
    font-size: 10px;
    color: #fff;
    font-weight: 800;
    /* Adjust the base font size as needed */
    animation: zoomInOut 2s infinite ease-in-out;
}

@keyframes zoomInOut {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

.vertical-tab button.Mui-selected {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    box-shadow: 12px 11px 37.5px 0px #FA00FF33;
    color: #fff;
    /* font-family: Inter; */
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
}

.vertical-tab button {
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    background: #343333;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
}

.vertical-tab .MuiTabs-indicator {
    display: none;
}

.uploadImf img {
    width: 200px;
    height: 200px;
    border-radius: 12px;
}

.uploadImf {
    width: fit-content;
    text-align: center;
    position: relative;
    margin: auto;

}

.playlist-r-alnum {
    height: 250px;
    overflow-y: scroll;
}

.track-name {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.pick-genr {
    background: var(--clr-bcg4) !important;
    padding: 15px;
    border-radius: 10px;
    margin: 10px;
    position: relative;

}

.pick-genr.ne {
    width: 100%;
}

.ttckname-ch .MuiInput-root {
    /* font-family: Inter; */
    font-size: 26px;
    font-weight: 400;
    line-height: 31.47px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font) !important;
}


.ttckname-ch .MuiInput-root::before,
.ttckname-ch .MuiInput-root::after,
.pick-genr .MuiInputBase-root::before,
.pick-genr .MuiInputBase-root::after {
    border-bottom: unset !important;
}

/*edit btn */

.edit-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #343333;
    /* background-color: rgb(20, 20, 20); */
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    text-decoration: none !important;
    position: absolute;
    right: 10px;
}

.edit-svgIcon {
    width: 17px;
    transition-duration: 0.3s;
}

.edit-svgIcon path {
    fill: white;
}

.edit-button:hover {
    width: 120px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
}

.edit-button:hover .edit-svgIcon {
    width: 20px;
    transition-duration: 0.3s;
    transform: translateY(60%);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.edit-button::before {
    display: none;
    content: "Edit";
    color: white;
    transition-duration: 0.3s;
    font-size: 2px;
}

.edit-button:hover::before {
    display: block;
    padding-right: 10px;
    font-size: 13px;
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.3s;
}

/* end */

/* save bt*/
.bookmarkBtn {
    width: 100px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.349);
    /* background-color: rgb(12, 12, 12); */
    background: #343333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: absolute;
    right: 10px;
}

.IconContainer {
    width: 30px;
    height: 30px;
    background: linear-gradient(to bottom, rgb(255, 136, 255), rgb(172, 70, 255));
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: 0.3s;
}

.icon {
    border-radius: 1px;
}

.text {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1;
    transition-duration: 0.3s;
    font-size: 1.04em;
}

.bookmarkBtn:hover .IconContainer {
    width: 90px;
    transition-duration: 0.3s;
}

.bookmarkBtn:hover .text {
    transform: translate(10px);
    width: 0;
    font-size: 0;
    transition-duration: 0.3s;
}

.bookmarkBtn:active {
    transform: scale(0.95);
    transition-duration: 0.3s;
}

/* end*/

/* .pick-genr {} */

.MuiPopover-paper {
    background: var(--clr-bcg) !important;
    color: var(--clr-font) !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
}

.pick-genr .select-genre {
    color: var(--clr-font);
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 10px;
}

.display-2 .pick-genr {
    padding: 0px;
}

.pick-genr textarea,
.pick-genr label,
.pick-genr label.Mui-focused {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

/* .pick-genr.tag button {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);

    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    color: #fff;
} */


/* add tag */


/* Active state */
.containers {
    position: relative;
    --size-button: 40px;
    color: white;
}

.inputs {
    padding-left: 100px;
    height: var(--size-button);
    font-size: 15px;
    border: none;
    color: #fff;
    outline: none;
    width: 58px;
    transition: all ease 0.3s;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%), inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
    border-radius: 12px;
    cursor: pointer;
}

.inputs::-webkit-input-placeholder {
    color: #fff;
}

.inputs:focus,
.inputs:not(:invalid) {
    width: 300px;
    cursor: text;
    box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%), inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
}

.inputs:focus+.icon,
.inputs:not(:invalid)+.icon {
    pointer-events: all;
    cursor: pointer;
}

.containers .icon {
    position: absolute;
    width: var(--size-button);
    height: var(--size-button);
    top: 0;
    left: 0;
    padding: 8px;
    pointer-events: none;
}


.containers .icon {
    width: 100%;
    height: 100%;
}

.addegtag {
    background: #000;
    border-radius: 6px;
    width: fit-content;
    padding: 8px;
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    position: relative;
    cursor: pointer;
}

/* .addegtag svg {
    display: none;
} */

/* .addegtag:hover svg {
    display: block;
} */

.addegtag svg {
    position: absolute;
    top: 5px;
    right: -25px;
}

.tags {
    padding: 10px 0;
}

.discover-genre {
    text-transform: capitalize !important;
}

.controlswho {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    max-width: 70%;
    margin: auto;
}

.tc {
    margin-top: 40px;
    padding: 15px;
}

.tc img {
    width: 180px;
    height: 210px;
    cursor: pointer;
}

.accesstab button.Mui-selected {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    color: #fff;
    text-transform: capitalize;
    padding: 3px;
    min-height: 28px;
    border-radius: 6px;
}

.accesstab button {
    color: #fff;
    text-transform: capitalize;
    padding: 3px;
    border-radius: 6px;
    background: #494949;
    min-height: 28px;

}

.accesstab .MuiTabs-flexContainer {
    width: fit-content;
    margin: auto;
    gap: 25px;
    background: #343333;
    padding: 15px;
    border-radius: 12px;
}

.accesstab .MuiTabs-fixed {
    text-align: center;
}

.accesstab .MuiTabs-indicator {
    background-color: unset !important;
}

.allrights {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #F1F1F1;
    background: #343333;
    padding: 25px 15px;
    border-radius: 10px;
}

.songtrst img {
    width: 250px;
    height: 50px;
}

.songtrst {
    text-align: center;
    margin-top: 150px;
}

.whatsongtrst {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    width: 80%;
    margin: auto;
    padding: 15px 0px;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin-image {
    margin-right: 10px;
    animation: spin 2s linear infinite;
}

.licenseaccess {
    border: 1px solid;
    border-image-slice: 1;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    text-align: center;
    padding: 1px;
    border-radius: 12px;
    height: 330px;
    /* padding: 25px; */
    width: 280px;
}

.licinner {
    padding: 25px;
    border-radius: 12px;
    background: var(--clr-bcg4);
    height: 100%;
}

.licenseaccess img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
}

.txt-c {
    margin: 15px 0px;
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 300;
    line-height: 16.52px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
}

.pricemint button {
    /* font-family: Inter; */
    font-size: 13px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #fff;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
}

.anyone {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 300;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 15px;
}

.free {
    border-radius: 15px;
    background: #D9D9D933;
    padding: 20px 15px;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
}

.free .anyone {
    margin-top: unset !important;
}

.curre {
    /* font-family: Inter; */
    font-size: 8px;
    font-weight: 300;
    line-height: 9.68px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    margin: 20px auto;
    /* max-width: 40%; */
    width: 300px;

}

.publishtype {
    background: var(--clr-bcg4);
    margin: 15px auto;
    max-width: 80%;
    border-radius: 8px;
    padding: 25px;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    position: relative;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font) !important;
}

.publishtype svg {
    position: absolute;
    left: 6px;
    top: 17px;
}

.sheduletext .MuiInputBase-root {
    background: #868686;
    border-radius: 12px;
}


.sheduletext .MuiInputBase-root input {
    padding: 10px;

}

.sheduletext.in1 .MuiInputBase-root {
    width: 250px;
}

.sheduletext.in2 .MuiInputBase-root {
    width: 100px;
}

.sheduletext label.Mui-focused {
    left: -4px;
    top: 8px;
}

.amselect .MuiInputBase-root {
    background: #868686;
    border-radius: 12px;
    padding: 5px;
    width: 90px;
}


.amselect .MuiInputBase-root svg {
    /* display: none; */
    right: 0px !important;
    top: 5px !important;
    left: unset !important;
}


.amselect .MuiInputBase-root .MuiSelect-select {
    padding: 10px;
    min-height: 10px;
}

.shedulpick {
    position: absolute;
    right: 15px;
    top: 10px;
}

.puntext {
    /* font-family: Inter; */
    font-size: 26px;
    font-weight: 500;
    line-height: 31.47px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    margin: 15px 0px;
    padding-bottom: 30px;

}

.pundesc {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--clr-font);
    margin: 15px auto;
    max-width: 70%;
}

.upload-next-btn .cancel {
    background: #505050 !important;
    margin: 15px 0px;
}

.upload-next-btn.pun {
    margin-top: 50px !important;
}

.uploadart {
    background: #454545B2 !important;
    backdrop-filter: blur(7px) !important;
    position: absolute !important;
    bottom: 0;
    color: #fff !important;
    text-transform: capitalize !important;
    display: flex !important;
    flex-direction: column;
    width: 200px;
    border-radius: 0px 0px 12px 12px !important;
}

.anyone.up {
    margin-top: 8px;
}

.datemui-picker svg {
    top: -5px !important;
    left: -15px !important;
}

.creators-select .MuiInputBase-root {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
}