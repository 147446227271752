.recent-head-artist.beta {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.recent-head-domainbeta {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
    text-transform: capitalize;
}

.height-prop {
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.time-recent {
    /* font-family: Inter; */
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #B8B8B8;
}

.recente-head-div.beta {
    width: 100% !important;
}

.recent {
    position: sticky;
    top: 100px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.player-controls {
    display: flex;
    justify-content: center;
}

.siPopover .MuiPopover-paper {
    /* right: 200px !important; */
}

.song-categorey {
    color: #B8B8B8;
}