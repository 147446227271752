.explore-heading {
    text-align: center;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 33.609px;
    font-style: normal;
    font-weight: 900;
    position: sticky;
    top: 0;
    line-height: 46px;
    padding: 20px;
    /* 136.867% */
    color: #7227e4 !important;
    background-color: var(--clr-explore-head);
}


.explore-box {
    background: var(--clr-explore-bg) !important;
}


.explore-content-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 27.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding-top: 10px;
    /* 136.867% */
    color: #7227e4 !important;

}

.explore-box2 .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);

}

.explore-box2 .MuiGrid-root {
    padding: 10px;
    margin: 25px 0px;

    justify-content: center;
}

.description-explore {
    color: #858199;
    font-size: 14px;
    padding: 10px;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}


.explore-card1 {
    background-color: #13C65A !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card2 {
    background-color: #9ca0fe !important;
    justify-content: space-between;
    padding: 10px;
}


.explore-card3 {
    background-color: #e171e3 !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card4 {
    background-color: #8854c7 !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card5 {
    background-color: #1cd9b8 !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card6 {
    background-color: #d6437b !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card7 {
    background-color: #ff7633 !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card8 {
    background-color: #e700cb !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card9 {
    background-color: #c82cf7 !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card10 {
    background-color: #4e66ff !important;
    justify-content: space-between;
    padding: 10px;
}

.explore-card11 {
    background-color: #36de89 !important;
    justify-content: space-between;
    padding: 10px;
}

.playlist-card1 {
    background-color: #6fbaff !important;
}

.playlist-card2 {
    background-color: #6568ff !important;
}

.playlist-card3 {
    background-color: #f9b776 !important;
}

.playlist-card4 {
    background-color: #23b8a8 !important;
}

.playlist-card5 {
    background-color: #e360ea !important;
    ;
}

.content-heading {
    color: #282828;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 23.867px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    /* 134.075% */
    letter-spacing: 0.33px;
    text-transform: uppercase;
}

.content-para {
    color: #282828;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    font-family: Arial;
    font-size: 12.625px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    /* 182.178% */
    letter-spacing: 0.2px;
}

.playlist-text {
    color: #282828;
    text-align: center;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.20);
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 300% */
    letter-spacing: 0.25px;
}

.emoji-div {
    text-align: center;
}

.love-playlist {
    background-color: var(--clr-explore-head) !important;
    color: var(--clr-track-time) !important;
    text-align: center;
}

.love-display {
    display: flex;
    justify-content: space-evenly;
}


.love-display img {
    padding-right: 5px;
}

/** hot new **/

.hotnew_img_profile-img-outer img {
    width: 100%;
    height: 100%;
}

.hotnew_img_profile-img-outer {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    border: 1px solid #fc5af2;
    display: flex;
    align-items: center;
}

.hotnew_img_profile_details h2 {
    color: #858199;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}

.hotnew_img_profile_details p {
    margin: 0px;
}

p.by-Audius {
    font-size: 16px;
    color: #C2C0CC;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 15px;
    padding-top: 5px;
}

p.by-Audius span {
    color: #858199;
    font-family: Arial;
    font-size: 15.125px;
    font-style: normal;
    font-weight: 700;
}


.MuiStack-root.stack-buttons-row button {
    padding: 8px 15px !important;
    min-width: auto;
    font-size: 13.5px;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.button-color-2,
.button-color-3,
.button-color-5,
.button-color-2:hover,
.button-color-3:hover,
.button-color-5:hover {
    border-color: #DAD9E0 !important;
    background: #282828;
    color: #fff;
    color: #858199 !important;
    text-align: center;
    font-family: Arial;
    font-size: 17.297px;
    font-style: normal;
    font-weight: 700;
}

button.button-color-4,
button.button-color-4:hover {
    border: 1px solid #B80ECA;
    /* background: #282828; */
    color: #CC0FE0;
    text-align: center;
    font-family: Arial;
    /* font-size: 18px; */
    font-style: normal;
    font-weight: 700;
}

button.button-color-4:hover {
    background: #CC0FE0;
    color: #fff;
}

button.button-color-1,
button.button-color-1:hover {
    background: #CC0FE0;
}

.mdt-flex {
    display: flex;
    gap: 20px;
}

.mdt-flex-inner {
    font-size: 14px;
}

.mdt-flex-inner label {
    color: #CECDD6;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 7px;
}

.mdt-flex-inner span {
    color: #858199;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

p.description-para-music {
    color: #858199;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 10px 0;
}

.mdt-flex-inner.rf-flex-block-inner label {
    color: #858199;
}

.mdt-flex-inner.rf-flex-block-inner span {
    color: #CECDD6;
}

.mdt-flex.rf-flex-block {
    margin-bottom: 10px;
}

.stack-buttons-row {
    margin-top: 15px;
}

.mdt-flex-inner.rf-flex-block-inner svg {
    color: rgba(194, 192, 204, 1);
    width: 18px;
}

.mdt-flex-inner.rf-flex-block-inner {
    display: flex;
    align-items: center;
    gap: 3px;
}

label.playlist-label {
    color: #C2C0CC;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: inline-block;
}

.hot-and-new-music .explore-box2 .MuiGrid-root {
    padding: 10px 10px 0 10px;
    margin: 25px 0px 0;
    justify-content: center;
}

table.new-hot-table th {
    color: #858199 !important;
    font-family: Arial;
    font-size: 13.891px;
    font-style: normal;
    font-weight: 700;
}

table.new-hot-table th:hover span {
    color: #858199 !important;
}

table.new-hot-table tr td {
    color: #858199;
    font-family: Arial;
    font-size: 13.563px;
    font-style: normal;
    font-weight: 700;
    border-bottom: none;
}

table.new-hot-table th svg {
    opacity: 1 !important;
}

body.light-theme .mdt-flex.mdt-flex-outer label,
body.light-theme label.playlist-label {
    color: #6d698a;
}

body.light-theme .mdt-flex-inner.rf-flex-block-inner svg,
body.light-theme .mdt-flex-inner.rf-flex-block-inner span {
    color: #6d698a;
}

@media (max-width:991.98px) {

    .explore-box,
    .hot-and-new-music-box {
        width: 100% !important;
    }

    .stack-buttons-row {
        margin-top: 15px;
        flex-wrap: wrap;
        gap: 10px;
    }

    .hot-and-new-music .explore-box2 .MuiGrid-root {
        padding: 10px 0 0 0;
        margin: 0;
    }

    table.MuiTable-root.new-hot-table {
        margin-top: 40px;
    }

}

@media (max-width: 575.98px) {

    .mdt-flex {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        flex-direction: column;
    }

}