.react-calendar {
    width: 100% !important;
    /* max-width: 750px !important; */
    padding: 40px 20px !important;
    border-radius: 50px !important;
    background: #2C2C2C !important;
    box-shadow: -5px 4px 9px 0px rgba(0, 0, 0, 0.46) !important;
}

.react-calendar__month-view__weekdays__weekday {
    font-weight: 700 !important;
    border: none !important;
    text-decoration: none !important;
    color: #fff;
}

.react-calendar,
button.react-calendar__navigation__label {
    font-size: 18px;
    color: #fff;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__month-view__days button.react-calendar__tile.react-calendar__month-view__days__day {
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

button.react-calendar__tile.react-calendar__month-view__days__day abbr {
    display: inline-block !important;
    height: 40px;
    width: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.react-calendar__tile--active {
    background: transparent !important;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background: transparent;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day abbr {
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 50%;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth abbr {
    border: 1px solid;
    border-radius: 50%;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:nth-child(7n+7) abbr {
    /* background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)); */
    border-radius: 50%;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth abbr {
    background: transparent;
}

.days {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)) !important;

}

.days1:hover {
    background: transparent !important;

}

.days1 abbr:hover {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));

}

button.react-calendar__tile.react-calendar__month-view__days__day.days:hover {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));

}

.days:hover {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)) !important;
}

/* button.react-calendar__tile.react-calendar__month-view__days__day:hover abbr {

    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));

} */

button.react-calendar__tile.react-calendar__month-view__days__day:hover abbr {
    border: 1px solid #fff;
    border-radius: 50%;
}

button.react-calendar__navigation__label:hover {
    background: transparent !important;
}

button.react-calendar__navigation__label span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    width: 100px;
    display: flex;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    padding: 5px;
    border-radius: 20px;
}

button.react-calendar__navigation__label {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 40px; */
}

.react-calendar__viewContainer {
    padding-top: 30px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    color: #fff;
    font-size: 25px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:hover,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button:hover {
    background: transparent !important;
}

.react-calendar__navigation {
    margin: 50px 0px;
    margin-bottom: 50px !important;
}

.calender-main {
    position: relative;
}

/* .today-inner-cont.cal{
    position: absolute;
    left: 50%;
    right: 12%;
} */

.calender-backdrop-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
}