@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

#video {
    width: 100%;
    height: 100vh;
    position: absolute;
    object-fit: cover;
    opacity: 75%;

}

.home {
    width: 100%;
    overflow-x: hidden;
}

.experience {
    text-align: center;
    font-family: Anton;
    position: relative;
    font-size: 130px;
    font-weight: 800;
    line-height: 130px;
    letter-spacing: 1px;
    width: 900px;
    margin: auto;
    text-transform: uppercase;
    background: linear-gradient(131deg, #FC5AFF 50.39%, #175AFF 80.83%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.1px #fff;
}

.description {
    color: #FFF;
    position: relative;
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 160% */
    border-radius: 15px;
    background: #2D2D2D;
    width: 559px;
    margin: auto;
    padding: 15px;
}

.start-now-btn button {
    color: var(--Neutural-White, #FFF);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    border-radius: 12px;
    background: linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%);
    box-shadow: 0px 20px 90px -12px rgba(61, 199, 95, 0.60);
    padding: 8px 16px;
}

.start-now-btn {
    text-align: center;
    padding: 30px 0px;
}

.description2 {
    display: flex;
    width: 85%;
    padding: 20px 80px 30px;
    /* position: absolute; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #2e2e37;
    backdrop-filter: blur(34px);
    margin: auto;
    /* top: 80px; */
}

.description2 .text {
    color: var(--Neutural-Grey-1, #A7AAB5);
    text-align: center;
    font-feature-settings: 'liga' off;
    /* Body/20 */
    font-family: Poppins;
    font-size: 20px;
    /* position: absolute; */
    /* font-style: normal; */
    font-weight: 400;
    line-height: 32px;
    /* 160% */
}

.cont2 {
    background-image: url('../../img/dster-webp/cont2-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* height: 100vh; */

}



.laptop-img {
    text-align: center;
}


.laptop-img img {
    width: 50%;
}

.updated {
    display: flex;
    gap: 20px;
    flex-direction: column;
    color: #fff;

}

.cont3 .txt1 {
    color: var(--clr-font);
    text-align: center;
    /* font-family: Inter; */
    font-size: 50px;
    /* font-style: normal; */
    font-weight: 600;
    line-height: 60px;
    /* 120% */
}

.cont3 .txt2 {
    color: var(--clr-font);
    text-align: center;
    /* font-family: Inter; */
    font-size: 18.834px;
    /* font-style: normal; */
    /* font-weight: 400; */
    line-height: 36.16px;
    /* 173.559% */
    letter-spacing: 0.16px;
}

.txt-field {
    text-align: center;
    padding: 50px 0px;
}

.txt-field button,
.txt-field button:hover {
    padding: 13px 40px;
    border-radius: 0px 12px 12px 0px;
    /* border: 1px solid #FC5AFF; */
    background: #FC5AFF;
    font-size: 12px;
    color: #2e2e37;
    margin-left: 3px;
}

.txt-field input {
    padding: 16px 20px;
    border-radius: 10px 0px 0px 10px;
    border: unset !important;
    background: #EFEFF1;
}

/** new style **/

.banner-section-part {
    padding: 120px 0 80px;
    position: relative;
    z-index: 99;
}

.description.description-banner {
    margin-top: 10px;
}

.header-banner-sections {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.header-banner-sections::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(130.04% 61.13% at 50% 50.05%, rgba(0, 0, 0, 0.00) 18.94%, #111 81.12%), #000;
}

.MuiGrid-root.MuiGrid-container.player-offers-section {
    background: #111;
    position: relative;
}

.MuiGrid-root.MuiGrid-container.player-offers-section::before {
    left: -65px;
}

.MuiGrid-root.MuiGrid-container.player-offers-section::before {
    content: '';
    position: absolute;
    width: 120%;
    height: 100%;
    filter: blur(40px);
    top: -97px;
    background: #111;
}

.description2.player-offers-section-description {
    position: relative;
    z-index: 9;
}

.banner_moving_float_img img {
    width: 44px;
    animation: movingtop 5s infinite
}

@keyframes movingtop {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(25%);
    }

    100% {
        transform: translateY(-25%);
    }
}

.banner_moving_float_img {
    text-align: center;
}

@media (max-width:991.98px) {

    .MuiGrid-root.MuiGrid-container.player-offers-section {
        padding: 20px;
    }

    .description2.player-offers-section-description {
        padding: 24px;
    }

    .cont2 .txt2 {
        line-height: 27px;
    }

    .MuiGrid-root.MuiGrid-container.player-offers-section::after {
        display: none;
    }
}

@media (max-width:767.98px) {
    .laptop-img img {
        width: 100%;
    }
}

@media (max-width:1199.98px) {
    .banner-section-part {
        padding: 100px 0px;
    }
}

@media (min-width:992px) and (max-width:1199.98px) {
    .experience {
        font-size: 5rem;
    }
}

.how-works {
    font-family: Anton;
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-right: 250px !important; */
    color: #fff;
    padding: 30px;
    margin: 25px 0px;
}

.how-works.new {
    font-family: Anton;
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-right: 250px !important; */
    color: var(--clr-font);
    padding: 30px;
    margin: 25px 0px;
}

.h-c1 {
    padding: 25px 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px #00032D40;
    background: #FFFFFF1A;
    position: relative;
    backdrop-filter: blur(30px);
}

.how-bg {
    background: url("../../img/dster-webp/landing-how.webp");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: inherit;
    /* height: 100%; */
    width: 100%;
    /* height: 700px; */
}

.h-num {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-family: Open Sans; */
    font-size: 35px;
    font-weight: 800;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;

}

.h-c1-cont {
    color: var(--clr-font);
    /* font-family: DM Sans; */
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

}

.dog img {
    position: absolute;
    right: 5px;
    /* bottom: 20px; */
    width: 80px;
    height: 80px;
}

.how-desc {
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justified;
    color: var(--clr-font);
    /* margin-top: 30px; */
    padding: 20px;
    margin: 50px 0px;

}

.ind-style {
    margin-top: 60px
}

.ind-style2 {
    margin-top: 60px;
    margin-left: 40px;
}

/* .ind-style3 {
    max-width: 40% !important;
} */

.how-bg2 {
    background: url("../../img/dster-webp/dstrive.webp");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: contain;
    /* height: 100%; */
    width: 100%;
    /* height: 700px; */
    height: auto;
}

.how-to {
    font-family: Anton;
    color: var(--clr-font);
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: start;
    padding: 15px;
    margin-left: 20px;
}

.through-the {
    padding: 20px;
    border-radius: 10px;
    background: #FFFFFF1A;
    box-shadow: 0px 10px 15px -3px #00032D40;
    margin: 20px 30px;
    backdrop-filter: blur(10px);
}

.through-c1 {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 20px 0px;
}

.through-c2 ul li {
    /* font-family: Poppins; */
    color: var(--clr-font);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 20px 0px;
}

.how-desc2 {
    /* font-family: Poppins; */
    color: var(--clr-font);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justified;
    background: linear-gradient(89.93deg, rgba(140, 102, 165, 0.2) 1.1%, rgba(179, 178, 193, 0.2) 90.1%);
    border-radius: 10px;
    padding: 20px;
}

.join-us-main {
    margin: 50px 0px;
}

.beyond-strem {
    color: #FFF;
    text-align: center;
    /* font-family: Inter; */
    font-size: 40px;
    /* font-style: normal; */
    font-weight: 600;
    line-height: 60px;
    padding: 10px 0px 35px;

}

.accord-home {
    background-color: var(--clr-bcg) !important;
    color: var(--clr-font) !important;
    margin: 20px 0px;
    border-radius: 10px !important;
}

.faq-main {
    margin-top: 30px;
    padding: 25px;
}

.how-to.faq {
    text-align: center;
}

.product-bg {
    background: url("../../img/dster-webp/product-bg.webp");
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    background-size: inherit;
    /* height: 100%; */
    width: 100%;
    /* height: 700px; */
    position: relative;
    justify-content: center;
}

.product-bg2 {
    background: url("../../img/dster-webp/product-bg2.webp");
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    background-size: inherit;
    /* height: 100%; */
    width: 100%;
    /* height: 700px; */
    position: relative;
    justify-content: center;
}

.dummy-banner {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    height: 180px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.test-div {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);

    margin: 20px;
    border-radius: 5px;
    padding: 3px;
    position: relative;
    z-index: 999;

}

.test-inner {
    background-color: #282828;
    padding: 25px;
}

.pdt-1 {
    margin: 25px 0px;
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justified;
    color: #fff;
}

.product-title-new {
    position: absolute;
    left: 8%;
    width: 766px;
    top: -22%;
    text-align: left;
}

.how-works.product-title-new,
.how-works.product-title-new2 {
    line-height: 5rem;
}

.how-works.pdt {
    margin: 30px 0px 100px;
}

.product-title-new2 {
    position: absolute;
    right: 8%;
    width: 900px;
    top: -22%;
    text-align: right;
}

.bg-rxt {
    background: linear-gradient(272.78deg, #4F82FF 27.35%, #FC5AFF 92.42%);
    background-clip: text;
    -webkit-text-fill-color: transparent;



}

.test-inner.lan-join {
    font-size: 25px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--clr-font);
    border-radius: 20px;
}

.test-div.lan-us {
    border-radius: 20px;
}

.dtoken img {
    width: 100%;
    /* height: 700px; */
}

.dtoken-txt1 {
    font-family: "Syne", sans-serif;
    font-size: 144px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 30px;
    color: var(--clr-font);
}

.dtoken-txt2 {
    font-family: "Syne", sans-serif;
    font-size: 130px;
    font-weight: 800;
    line-height: 156px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 30px;
    color: var(--clr-font);
}

.dtoken-btn {
    border-radius: 12px;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.02) 12.72%, rgba(235, 225, 254, 0.06) 40.07%, rgba(95, 21, 251, 0.20) 79.55%);
    box-shadow: 0px 10px 15px -3px rgba(0, 3, 45, 0.25);
    backdrop-filter: blur(37.5px);
    padding: 40px 15px;
    color: var(--clr-font);
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 15px;
}

.key-feature1 {
    font-family: Anton;
    font-size: 232px;
    font-weight: 400;
    line-height: 349px;
    letter-spacing: 0em;
    text-align: center;
    background: var(--clr-font);
    background-clip: text;
    -webkit-text-stroke: 4px transparent;
    color: var(--clr-num);
}

.key-feture2 {
    font-family: Anton;
    font-size: 120px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--clr-font);
    position: absolute;
    bottom: 12%;
    left: 32%;

}

.whitepaper {
    position: relative;
}

.key-box {
    background: var(--clr-bcg);
    box-shadow: 0px 10px 15px -3px rgba(0, 3, 45, 0.25);
    position: relative;
    padding-top: 100px;
    width: 400px;
    height: 550px;
    border-radius: 10px;
    margin: 120px auto 0px
}

.bottom-color {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    height: 30px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    bottom: 0;
}

.key-icon {
    text-align: center;
    color: var(--clr-font);
    font-family: Anton;
    font-size: 50px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: 0em;
    padding: 20px;
}

.key-desc {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--clr-font);
    padding: 10px 25px;
}

.key-num-div {
    clip-path: circle(35.1% at 50% 51%);
    background: var(--clr-num);
}

.key-num {
    background: linear-gradient(271.3deg, #FFFFFF -16.01%, #FC5AFF 97.16%);
    font-family: Anton;
    font-size: 150px;
    font-weight: 400;
    line-height: 311px;
    letter-spacing: 0em;
    text-align: center;
    background-clip: text;
    -webkit-text-stroke: 4px transparent;
    letter-spacing: 5px;
    color: var(--clr-num);
}

.key-num-div {
    position: absolute;
    top: -160px;
    left: 50%;
    margin-left: -164px;
    width: 300px;
}

.feat-cont {
    margin-top: 80px;
    justify-content: center;
}

.gd-1 {
    position: relative;
    justify-content: flex-start;
}

.gd-2 {
    position: relative;
    justify-content: flex-end;
}

.buy-cryptobtn button {
    border-radius: 12px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    color: #FFF !important;
    padding: 10px 25px;
    border: 0;
}

.buy-cryptobtn {
    text-align: center;
    margin-top: 70px;
}