.fanzone .playerBanner {
    background: var(--clr-fanbg);
    width: 100%;
    height: 100vh;
    /* background: linear-gradient(0deg, rgba(17, 17, 17, 0.83) 0%, rgba(17, 17, 17, 0.83) 100%), linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0.00) 52.23%, #111 100%), url(../../img/player-banner-bg.png), lightgray 50% / cover no-repeat; */
    position: absolute;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    opacity: 75%;
    background-repeat: no-repeat;
    padding: 100px 0 ;
}
.fanzone-body{
    margin-bottom: 20px;
}
.fanzone .start-now-offer button{
    padding: 21px 48px;
    border-radius: 12px;
  
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-transform: initial; /* 140% */
  }
  .fanzone .start-now-offer button:first-child{
    border-radius: 12px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
      
  }

  .PrivateSwipeArea-root{
    display: none;
}
.fanzone .player-platform{
    text-align: left;
    margin-bottom: 20px;
}
.fanzone .player-description {
    color:var(--clr-upload-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.16px;
    letter-spacing: 0.16px;
    text-align: left;
    max-width: 650px;
    margin: 0;
}
.subimg{
    text-align: left;
}
.subimg img {
    max-width: 54%;
    height: auto;
    margin-top: 34px;
}
.brief-instruct li {
    color:var(--clr-upload-font);
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.16px;
    text-align: left;
}
.brief-instruct {
    margin-top: -47px;
}
.fanzone .process {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}
.fanzone .process-main{
    background: linear-gradient(180deg, #FC5AFF 0%, #175AFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 53.3px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.67px;
    text-align: left;
    min-width: 374px;
}
.fanzone .process h6 {
    color: var(--clr-upload-font);
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.16px;
    text-align: left;
    margin: 10px;
}
.discover-fanzone .con {
    color: var(--clr-upload-font);
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
}
.discover-fanzone{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4%;
    margin: 45px 0;
    position: relative;
}
.discover-fanzone img{
    max-width: 100%;
    height: auto;
}
.experience-process {
    position: relative;
    padding-top: 50px;
}
.fanzone .start-now-offer{
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 50px;
}