.activity-detail-img img {
    width: 600px;
    height: 600px;
}

.profile-outer-layer-activity-detail .MuiGrid-root {
    padding: 10px;
    margin: 50px 0 0 0;
    justify-content: center;
    background: var(--clr-active);
    border-radius: 10px;
    /* align-items: center; */
}


.ac-detail-title-verify,
.ac-detail-title-verify svg {
    color: #A22FEB !important;
    display:var(--flex);
    align-items: center;
    gap: 10px;
}

.ac-detail-title {
    color: var(--clr-font) !important;
    font-size: 30px;
    margin: 15px 0px;
}

.owned-by span {
    margin: 0px 5px;
    color: #A22FEB !important;
}

.owned-by {
    color: var(--clr-font);
}

.count-ac-detail {
    padding: 3px 8px;
    border-radius: 10px;
    border: 1px solid var(--clr-font);
}

.follow-ac-detail {
    display:var(--flex);
    align-items: center;
    gap: 30px;
    padding: 20px 0px;
    color: var(--clr-font);
}

.follow-ac-detail div {
    display:var(--flex);
    align-items: color;
    gap: 8px;
    cursor: pointer;
}

.sales-on {
    color: var(--clr-font);
    padding: 10px 0px;
}

.time-n-date-ac-detail {
    display:var(--flex);
    color: var(--clr-font);
    gap: 15px;
    padding: 20px 0px;
}

.current-price {
    color: var(--clr-font);
    padding: 10px 0px;
}

.value-act-detail {
    color: var(--clr-font);
    font-size: 35px;
}

.value-act-detail span {
    font-size: 15px;
    padding: 0px 10px;
}

.buy-now-act-detail {
    display:var(--flex);
    gap: 20px;
    justify-content: start;
    padding: 30px 0px;
    align-items: center;
}

.buy-now-act-detail button {
    text-transform: capitalize;
}

.buy-now-act-detail button svg {
    padding: 0px 5px;
    color: #fff !important;
}

.buy-now-act-detail .b1,
.buy-now-act-detail .b1:hover {
    background: var(--clr-music-track);
    border-radius: 8px;
    padding: 10px 90px;
    color: #fff;
}


.buy-now-act-detail .b2,
.buy-now-act-detail .b2:hover {
    background: #858199;
    border-radius: 8px;
    padding: 10px 90px;
    color: #fff;
}

.description-act-detail {
    display:var(--flex);
    align-items: center;
    gap: 10px;
    color: var(--clr-font);
    padding: 15px 10px;
    /* border: 1px solid var(--clr-font); */
    border-radius: 10px 10px 0px 0px;
    border-bottom: none !important;
    margin-top: 20px;
}

.description-content-act-detail svg {
    color: #A22FEB !important;
}

.description-content-act-detail {
    /* border: 1px solid var(--clr-font); */
    color: var(--clr-font);
    padding: 15px 15px;
}

.by {
    display:var(--flex);
    align-items: center;
    gap: 5px;
}

.details-inner-details-text {
    display:var(--flex);
    justify-content: space-between;
    padding: 8px 0px;
}

main.profile-outer-layer-activity-detail {
    padding: 0;
    margin: 0;
}