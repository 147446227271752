.msg-artist-name-main {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    max-width: 100%;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: var(--clr-font);
}

.name-msg {}

.msg-avat-content img {
    width: 56px !important;
    height: 56px !important;
}

.msg-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading-container2 {
    /* background-color: var(--clr-explore-head); */
    height: calc(100vh - 150px);
    /* margin-top: 50px; */
    /* position: sticky;
    top: 57px;
    z-index: 999; */
}

.start-a-convo-grid {
    background-color: var(--clr-explore-head);
    padding: 20px;
    border-radius: 15px;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    font-size: 24px;
    color: #858199;
}

.connect-with-auids {
    /* border-radius: 15px; */
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0px;
    /* color: #858199; */
}

.write-msg-btn {
    background-color: #D127E3 !important;
    width: 100%;
    margin: 15px 0px;
    color: #fff !important;
    cursor: pointer;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    transition: all .12s ease-in-out;
}

.write-msg-btn:hover {
    transform: scale3d(1.025, 1.025, 1.025);
}

.set-msg {
    /* position: absolute; */
    background-color: var(--clr-explore-head);
    padding: 15px;
    /* position: relative;
    top: calc(100vh - 290px); */
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
}

.set-msg input {
    color: var(--clr-font);
    height: 10px !important;
}

.msg-send-txt li {
    background: #D127E3;
    padding: 20px 15px;
    width: fit-content;
    color: #fff;
    border-radius: 15px;
    margin: 15px 10px;

}

.msg-send-txt {
    display: flex;
    justify-content: flex-end;
}

.msg-receive-txt {
    display: flex;
    justify-content: flex-start;
}

.msg-receive-txt li {
    background: #D127E3;
    padding: 20px 15px;
    width: fit-content;
    color: #fff;
    border-radius: 15px;
    margin: 15px 10px;

}

.todo {
    display: flex;
    align-items: center;
    gap: 10px;
    /* justify-content: flex-end; */
}

.todo svg {
    fill: #fff;
    cursor: pointer;
}

.msglist {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
}

.chats {
    /* font-family: Inter; */
    /* color: var(--clr-font) !important; */
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

}

.msg-list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.msg-lsit-head-svg {
    display: flex;
    align-items: center;
    gap: 20px;
}

.msg-lsit-head-svg svg {
    fill: #fff;
    background: #858199 !important;
    border-radius: 20px;
    padding: 8px;
    font-size: 40px;
}


.msg-list-input {
    /* border: 1px solid var(--clr-bcg); */
    padding: 5px;
    border-radius: 15px;
    margin: 20px 0px 0px 0px;
    background: #F0F2F5;
}

.msg-list-input input {
    padding: 0px !important;
}

.msg-list-input svg {
    fill: #000;
}

.msg-list-input .msg-input {
    width: 100%;
}

.msg-list-chat-name {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 15px;
    width: 100%;
}

.chat-desc {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;

}

.chat-desc span {
    /* font-family: Inter; */
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 30px;

}

.msg-list-chat-main {
    display: flex;
    align-items: center;
    /* gap: 20px; */
    padding: 7px 10px;
}

.msg-list-area {
    height: 400px;
    overflow-y: scroll;
    margin: 20px 0px;
    cursor: pointer;
}

.msg-list-chat-main:hover {
    background: #2D88FF1A;
    border-radius: 15px;

}

.colab-chat-area.msg {
    height: 670px;
    overflow-y: scroll;
    margin: 0px;
    background: url('../../img/dster-webp/vr-chat-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.colab-chat-area.msg2 {
    height: 400px;
    overflow-y: scroll;
    margin: 0px;
    background: url('../../img/dster-webp/vr-chat-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 15px 20px !important;
}

.poll-head-tab {
    display: flex;
    align-items: center;
}

div#sentence {
    display: flex;
    justify-content: space-between;
}

.chats {
    display: flex;
}