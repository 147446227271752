.profile-outer-layer .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);
}

.profile-outer-layer .MuiGrid-root {
    /* padding: 10px; */
    margin: 25px 0px;
    justify-content: center;
}

.profile-outer-layer .upload-grid {
    padding: 20px 0px;

}

.profile-outer-layer .inputbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

/* .profile-setting {
    margin: auto;
    max-width: 500px !important;
} */

.profile-setting .inner-box2 {
    padding: 0 !important;
    /* width: 472px; */
    border-radius: 12px;
    background: var(--clr-profile-bcg) !important;

    backdrop-filter: blur(37.5px);
}

.profile-pg-grid {
    margin-bottom: 0px !important;
    justify-content: center;
}

.profile-setting .inner-popup {
    padding: 44px 33px;
}

.inner-popup-pad {
    border: var(--clr-border);
    border-radius: 15px;
}

.profile-setting .inner-popup .MuiFormControl-root {
    /* width: 70%; */

    border-radius: 11.22px;
    border: 1px solid var(--1, #175AFF);
    background: var(--clr-profile-bcg);
    box-shadow: -5.22px 7.309px 12.529px 0px rgba(0, 0, 0, 0.15);
}

.profile-setting .inner-popup p {
    min-width: 90px;
    color: var(--clr-font) !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.97px;
    /* 135.116% */
    letter-spacing: 0.522px;
    text-transform: capitalize;
    text-align: left;
}

.profile-setting svg.MuiSvgIcon-root {
    fill: #ccc !important;
}

.profile-setting .inner-popup input {
    padding: 11px 26px 11px 26px !important;
    color: var(--clr-font) !important;
}

.profile-setting .submit-btn button {
    border: 0 !important;
    text-transform: uppercase;
    padding: 10.609px 48px !important;
}

.profile-setting .border-bg {
    border-radius: 0px 0px 12px 12px;
    background: #FC5AFF;
    /* width: 472px; */
    height: 18px;
}

.profile-setting .border-bg2 {
    border-radius: 12px 12px 0px 0px;
    background: #FC5AFF;
    /* width: 472px; */
    height: 18px;
}

.profile-banner-size {
    background: #000;
}

.profile-banner-size {
    position: relative;
}

.profile-setting .description-UploadMusic,
.profile-setting .description-UploadMusic label {
    padding: 0;
}

.mainbg img.img-fluid {
    max-width: 100%;
    height: auto;
}

.profile-banner-inner img.img-display-after,
.profile-banner {
    /* width: 473px; */
    height: 208px;
}

.profile-banner-inner {
    position: relative;
}

.profile-img-outer {
    position: absolute;
    top: 15%;
}

button#image-upload-button-banner {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #fff;
    display: none;
    background: #373636ad;
    padding: 10px;
    border-radius: 50px;
    width: 168px;
    height: 168px;
    line-height: 4;
    text-align: center;
}

button#image-upload-button-banner {
    /* display: flex; */
    z-index: 999;
    left: 11%;
    bottom: 15%;
    right: auto;
    display: none;
}

.profile-img-inner img:hover {
    display: flex !important;
    filter: blur(0px) !important;
}

.image-upload-container .profile-img-inner,
.image-upload-container .profile-img-inner img {
    height: 168px;
    width: 168px;
}

.image-upload-container .profile-img-inner {
    ooverflow: hidden;
    border-radius: 50%;
    height: 165px;
    width: 172px;
    border: 6px solid rgb(255, 255, 255);
    /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 16px; */
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
    /* position: relative; */
    z-index: 1;
    /* background: rgb(255, 255, 255); */
    left: 14px;
}

.image-upload-container .profile-img-inner img {
    position: absolute;
    right: 0;

    width: 163px;
    height: 154px;
    border-radius: 146px;
    box-shadow: -2px 6px 5.9px 0px rgba(0, 0, 0, 0.25);
}

.profile-setting .profile-outer-layer .upload-grid {
    padding: 21px 0px !important;
    border-radius: 5px;
    background: #282828;
    margin: 0 !important;
}

.profile-banner-size .MuiAvatar-root.MuiAvatar-circular {
    border-radius: unset !important;
}

.profile-img .edit {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #fff;
    display: none;
    background: #373636ad;
    padding: 3px;
    border-radius: 100px;
    width: 156px;
    height: 156px;
    line-height: 4;
    text-align: center;
}

.profile-img:hover .edit {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.profile-img img:hover {
    filter: blur(1px);
}

.profile header {
    background-color: var(--clr-bcg) !important;
}

.submit-btn2 {
    width: 100%;
    text-align: right;
    font-size: 13px;
    margin-bottom: 10px;
    background: radial-gradient(912.99% 430.41% at 50% 5.36%, #FC5AFF 5.39%, #175AFF 19.83%);
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}

.artist-explore-more.myprofile {
    left: 0px !important;
}

.profile-follow-stack-div {
    padding: 15px;
}

.profile-follow-stack-div .MuiStack-root {
    justify-content: center;
}

.profile-follow-stack-div svg:hover {
    fill: #FC5AFF;
}