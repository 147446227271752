.stack-title {
    display: block;
    float: left;
    color: var(--clr-font);
    font-weight: 900;
    font-size: 25px;
}

.stack-descript {
    font-size: 15px;
    font-weight: 300;
    width: 70%;
}

.whole-cnt2 {
    max-width: 1400px;
    margin: 0 auto
}

.stacking-table th,
.stacking-table td {
    text-align: center;
    background: var(--clr-bcg) !important;
    color: var(--clr-font) !important;
}

.stacking-table th {
    font-weight: 900;
}

.stacking-table td,
.stacking-table th {
    border: 1px solid var(--clr-font) !important;
}

.lockpay-v2-table-div {
    margin: 25px 0px;
}

.go-red {
    color: red;
    margin: 0px;
    font-weight: 900;
}

.start-staking {
    text-align: center;
    color: var(--clr-font) !important;
    font-size: 18px;
}

.connect-wallet {
    background-color: var(--clr-music-track) !important;
    color: #fff !important;
    font-size: 17px !important;
    border-radius: 50px !important;
    padding: 6px 35px !important;
    margin: 15px 0px !important;
}


.connect-wallet.tb {
    margin: 5px !important;
    border-radius: unset !important;
    font-size: 14px !important;

}

.start-staking {
    background-color: var(--clr-bcg) !important;
    border-radius: 15px;
    box-shadow: 0px -1px 7px 3px var(--clr-music-track) !important;
    padding: 20px;
    text-align: center;
    margin-bottom: 25px;
    min-height: 100px;
    border: 2px solid var(--clr-music-track);
}

.pls-connet {
    font-weight: 500;
    color: red;
    margin: 15px 0px;
}

.stack-cont .MuiGrid-root {
    padding: 10px 20px;
}

.stack-cont {
    justify-content: center;
}

.balance {
    color: var(--clr-font);
    font-size: 15px;
    padding: 10px;
}

.balance b {
    font-weight: bolder;
}

.enter-amount {
    font-size: 18px;
    padding: 10px;
    color: var(--clr-font) !important;
    font-weight: bolder;
}

.stak-input-div input {
    background: var(--clr-bcg) !important;
    box-shadow: 0px -1px 7px 3px var(--clr-music-track) !important;
    border: 2px solid var(--clr-music-track);
    padding: 15px;
    color: var(--clr-font) !important;
    width: 85%;
}

.percent {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--clr-font) !important;
    margin: 15px 0px;
    /* justify-content: center; */

}

.non-percentage {
    padding: 4px 12px;
    border-radius: 16px;
    background: var(--clr-bcg) !important;
    cursor: pointer;
    color: var(--clr-font) !important;
}

.active-percent {
    padding: 4px 12px;
    border-radius: 16px;
    background: var(--clr-music-track) !important;
    cursor: pointer;
    color: #fff !important;
}


.stack-tab .MuiTabs-indicator {
    display: none;
}

.stack-tab button.Mui-selected {
    background-color: var(--clr-music-track);
    color: #fff !important;
}

.stack-tab button {
    color: var(--clr-font) !important;
    text-transform: capitalize;
    border-radius: inherit;
}


.stack-tab .MuiTabs-flexContainer {
    border-radius: 16px;
    background-color: var(--clr-bcg);
    width: fit-content;
}

.start-staking.lockpay {
    padding: 10px;
}

.start-staking.lockpay div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0px;
    font-size: 14px !important;
}

.cust-panel .MuiBox-root {
    padding: 24px 0px;
}

/* .creator.staking .MuiGrid-root.MuiGrid-container {
    margin-top: 45px !important;
    margin-bottom: 0px !important;
} */
.stk-btn-maindiv {
    text-align: center;
}

.stk-history {
    margin: 15px 0px;
    font-size: 25px;
    font-weight: 800;

}

.newno img {
    width: 100px;
}

.newno {
    background-color: var(--clr-bcg);
    text-align: center;
}