.CreatePlayList-grid {
    justify-content: center;
}

.accord-summary {
    background: var(--clr-bcg);
    color: var(--clr-font);
    height: 250px;
    display: flex;
    align-items: stretch;
    overflow: scroll;
    flex-direction: column;
}


.add-track-createplaylist {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.add-btn-add-track button {
    padding: 2px;
    border: 1px solid var(--clr-font);
    color: var(--clr-font);
}

.add-btn-add-track button:hover {
    background-color: var(--clr-music-track);
    border: none !important;
    color: #fff;
}

.add-false-content {
    text-align: center;
    padding: 10px;
    color: var(--clr-font);
}

.tracks-createplaylist img {
    width: 40px;
    height: 40px;
}

.tracks-createplaylist {
    display: flex;
    gap: 10px;
    font-size: 15px;
}

.tracks-createplaylist p {
    margin: 0px;
    font-size: 13px;
}

.accord-style .MuiAccordionSummary-content {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    text-transform: uppercase;
    font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    opacity: 60%;
}

.loading-div {
    text-align: center;
}

.model-edit-playlist div.MuiFormControl-root {
    width: 100%;
    /* margin: 10px 0px; */
    margin-top: 15px;
}

.profile-img-outer2 {
    top: 20% !important;
}

.profile-img-outer2.minnft {
    position: absolute;
    bottom: 0 !important;
    left: 33px;
}


.allButton-div {
    display: flex;
    justify-content: space-between;

}

.allButton-div div .btn1 {
    padding: 10px;
    border: 1px solid red;
    color: var(--clr-font);
}

.allButton-div div .btn2 {
    padding: 10px;
    border: 1px solid var(--clr-font);
    color: var(--clr-font);
}

.allButton-div div .btn3 {
    padding: 10px;
    /* border: 1px solid var(--clr-font); */
    color: var(--clr-font);
    background-color: var(--clr-music-track);
    color: #fff;
}

.allButton-div div .btn1:hover {
    background: red;
    color: #fff;
}

.allButton-div div .btn2:hover {
    background: var(--clr-music-track);
    color: #fff;
    border: none;
}

.savechanges {
    display: flex;
    gap: 10px;
}


.edit-playlist-title {
    margin: 0;
    display: inline;
    font-size: 20px;
    font-size: 20px;
    font-weight: 700;
    /* font-weight: var(--font-bold); */
    color: #c2c0cc;
    /* color: var(--neutral-light-4); */
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.edit-playlist-title svg {
    fill: #c2c0cc;
}

.model-edit-playlist label,
.model-edit-playlist input,
.model-edit-playlist textarea {
    color: var(--clr-font) !important;
    /* height: 40px !important; */
    font-family: 'Poppins', sans-serif !important;
}


.h5-edit {
    margin: 10px 0px;
    font-family: 'Poppins', sans-serif !important;
    color: var(--clr-font) !important;
}

li#pick-mood {
    font-family: 'Poppins', sans-serif !important;
}

li#pick-mood.Mui-selected {
    background: rgba(25, 118, 210, 0.08) !important;

}

/* [aria-labels="Without label"] {
    font-family: 'Poppins', sans-serif !important;
} */

.pick-mood .MuiSelect-select {
    font-family: 'Poppins', sans-serif !important;
}

.pick-mood {
    color: var(--clr-font) !important;
}

.model-edit-playlist fieldset {
    border-color: var(--clr-font) !important;
}

.pick-mood fieldset {
    border-color: var(--clr-font) !important;
}

.playlist-verify {
    position: static !important;
    margin: 3px 6px !important;
    display: block !important;
}

.playlist-verify {
    color: #2081e2 !important;
    fill: #2081e2 !important;
    opacity: 100% !important;
}

.music-play-main-div svg{
position: relative  !important;
top: 0px !important;
}