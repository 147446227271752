.feed .feed h2 {
    text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20);
    font-size: 32.906px;
    font-style: normal;
    font-weight: 900;
    background: linear-gradient(296deg, #5B23E1 0%, #A22FEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feed .feed-head {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--clr-explore-head);
    position: sticky;
    top: 63px;
    /* position: fixed;
    width: 83%; */
}

.feed-tab .active-feed.Mui-selected {
    border-radius: 8px;
    border: 1px solid #7E1BCC;
    background: #7E1BCC;
    color: #fff;
    font-weight: 600;
    border: none;
}

.feed-tab .MuiTabs-indicator {
    background: none !important;
}

.feed-tab .active-feed {
    min-height: 0 !important;
    border-radius: 8px;
    border: 1px solid #C2C0CC;
    margin-left: 15px;
    padding: 5px 10px;
    color: #AAA7B8;
    font-weight: 600;
}

.feed .MuiContainer-maxWidthMd {
    max-width: 1014px;
}

.feed .feed-bg {
    background: var(--clr-explore-bg) !important;
}

.feed .music-detail {
    display: flex;
    gap: 20px;
}

.feed .album-detail a {
    color: #858199;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 5px;
}

.feed .link-badge a {
    font-weight: 500;
}

.feed .album-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feed .link-badge {
    display: flex;
    gap: 10px;
}

.feed .link-badge img {
    width: 20px;
}

.feed .loop-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

.feed .MuiSvgIcon-fontSizeMedium {
    font-size: 18px;
}

.feed .repost-loop p {
    font-weight: 500 !important;
    color: #C2C0CC;
}

.feed .share-btn {
    color: #c2c0cc;
    display: flex;
    gap: 25px;
}

.feed .share-btn .MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
    cursor: pointer;
}

.feed .feed-music-box {
    border-radius: 8px 8px 0px 0px;
    background-color: var(--clr-explore-head);
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    padding: 11px 16px;
}

.feed .music-choice-box {
    margin-bottom: 20px;
}

.feed .tab-cnt {
    margin-top: 100px;
    padding-bottom: 0px;
}

.feed .music-timing p {
    margin: 0;
}

.feed .loop-times {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feed .music-time {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.feed .like-dot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.music-timing {
    display: flex;
    align-items: center;
}

.feed .music-timing p {
    color: #C2C0CC;
}

.feed .music-timing .MuiSvgIcon-fontSizeMedium {
    color: #C2C0CC;
    cursor: pointer;
    font-size: 20px;
}



.playlist-more-div {
    padding: 5px 10px;
    font-weight: 700;
    opacity: 65%;
    cursor: pointer;
}

.explore .MuiPaper-root.MuiPaper-elevation {
    cursor: pointer;
}

.playlist-popover .MuiPaper-root.MuiPaper-elevation {
    padding: 5px !important;
    border-radius: 8px;
    background: var(--clr-bcg);
    color: var(--clr-font);
    box-shadow: 1px 1px 5px -4px #000;

}


.playlist-more-div:hover {
    color: #fff;
    opacity: 100%;
    border-radius: 8px;
    background-color: #A22FEB;
}

.playlist-more-div svg {
    background: unset !important;

}

.playlist-more-div:hover svg {
    color: #fff;
    fill: #fff;
}

.playlist-more-div {
    display: flex;
    align-items: center;
    gap: 5px;
}

/*** joshua **/

.feed .feed-bg.our-assigned-height {
    background: var(--clr-explore-bg) !important;
    margin-bottom: 0px !important;
    /* height: calc(100vh + 850px); */
}

@media(max-width:767.98px) {
    .feed .feed-head {
        width: 100%;
        display: block;
        text-align: center;
    }

    .feed .tab-cnt {
        margin-top: 170px;
    }
}

@media(max-width:991.98px) {
    .feed .feed-head {
        width: 100%;
    }
}