[aria-label="basic tabs example nft-activity"] {
    gap: 25px;
    /* border: 1px solid #fff; */
    align-items: center;
    width: fit-content;
    margin: 25px auto;
    padding: 5px;
    /* border-radius: 10px; */
    border-radius: 15px;
    background: var(--clr-bcg);
    /* box-shadow: 4px 4px 4px 0px rgba(149, 44, 161, 0.20) inset; */
}



.nft-tab-style-activity button {
    color: var(--clr-font) !important;
    padding: 5px 70px;
    border-radius: 12px;
    /* border-radius: 12px; */
    background: var(--clr-explore-bg);
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}


.nft-tab-style-activity span.MuiTabs-indicator {
    display: none;
}

.nft-tab-style-activity button.Mui-selected {
    color: #fff !important;
    background-color: #FC5AFF;
}

.box-pad .MuiBox-root {
    padding: 0px !important;
}

.nft-corner-tab .MuiBox-root {
    border-bottom: unset !important;
}

.nft-corner-tab {
    position: relative;
}




.back-btn svg {
    position: absolute;
    left: 2%;
    top: 2%;
    font-size: 30px;
}