.explore-artist header.bg-appbar {
  background-color: var(--clr-bcg) !important;
}

.card-backstage.expart {
  background-color: var(--clr-bcg) !important;
  color: var(--clr-track-time) !important;
  text-align: center;
  padding: 20px 40px;
  border-radius: 15px;
}

.backstage-artist-grid.expart {
  height: unset !important;
  overflow: unset !important;
  padding-top: 80px;
  margin-top: 70px !important;
}

.explore-more-btn2.artists {
  display: block !important;
}

.cirle-img {
  width: 220px;
  height: 250px;
}

.top-support-main {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background-color: var(--clr-bcg);
  border-radius: 12px;
  margin: 5px;
  color: var(--clr-font);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}

.top-support-main .MuiAvatar-root {
  width: 100px;
  height: 100px;
}

.top-suport-content {
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.top-suport-content button {
  border-radius: 5px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  color: #fff;
  font-size: 10px;
  /* margin-top: 100px; */
  /* position: absolute; */
  padding: 6px 0px;
  /* left: 50%;
  top: 40%;
  display: none; */
}

.date-top div {
  font-size: 10px;
  height: 18px;
  /* border-radius: 12px;
  padding: 5px;
  border: 1px solid var(--clr-font);
  width: fit-content; */
}

.top-support-main:hover {
  backdrop-filter: blur(5px);
}

.top-support-main:hover button {
  display: block !important;
}

.newno.top {
  background-color: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}