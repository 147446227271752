.UploadMusic-box3.create-virtualmeet .upload-grid {
    height: auto !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

.UploadMusic-box3.create-virtualmeet .upload-grid2 {
    height: auto !important;
    /* overflow: scroll; */
}

.UploadMusic-box3.create-virtualmeet .MuiGrid-root {
    padding: 100px 10px !important;
    margin: 0px !important;
}

.set-tumb {
    color: #fff !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(0deg, #CC0FE0, #CC0FE0) !important;
    padding: 12px !important;
}

.description-UploadMusic.create-virtual {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
}

.create-virtual-upload-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}


.select-from button,
.select-from button:hover {
    /* font-family: Arial; */
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.8392674922943115px;
    text-align: center;
    background: #CC0FE0;
    color: #fff !important;
    padding: 12px !important;
}


.select-from {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.start-end-time-main {
    display: flex;
    align-items: center;
    background: var(--clr-form-input);
    justify-content: space-around;
    flex-flow: wrap;

}


.start-time {
    display: flex;
    align-items: center;
    white-space: pre;
    gap: 3px;
    /* cursor: pointer; */
}


.start-time .MuiFormControl-root.MuiTextField-root {
    min-width: 150px !important;

}

.start-time div.MuiStack-root {
    padding-top: 0px !important;
}

.start-time input {
    padding: 12px 0px !important;
}

.start-time .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0px !important;
    background: unset !important;
}

.messageset-grid {
    background: var(--clr-bcg);
    border-radius: 15px;
}

.messageset-input input {
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
}

.message-set-table th,
tr {
    background: var(--clr-bcg);
    color: var(--clr-font);
}

.message-set-input-mian {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-evenly;
}

.msgset div {
    margin: 10px 0px;
}

.msgset div {
    font-size: 17px !important;
}