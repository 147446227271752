.enew-g1-release {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-release-mxp {
    /* color: #000; */
    color: var(--clr-font);
    font-size: 23.849px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: normal;
}

.new-release-btns {
    color: var(--branding-red-tint-300, #F3777D);
    text-align: right;
    font-size: 15.899px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}


.nr-artist {
    color: var(--neutral-900-night, #E4E7E5);
    font-size: 15.899px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 0px;
}

.song-new {
    color: var(--neutral-800-night, #AFB6B2);
    font-size: 13.912px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding: 10px; */
}

.nft-item-nr {
    text-align: start !important;
    padding: 10px;
    position: relative;
    text-align: center !important;
}

.nft-item-nr:hover .play-new2 {
    display: block;
    cursor: pointer;
}


.nft-item-nr .nr img {
    width: 150px;
    height: 150px;
}

.nr-owlcarosual {
    margin: 20px 0px 0px 0px;
}

.play-new2 {
    background: #7C8DB5BF;
    border-radius: 50px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px;
    position: absolute;
    top: 60px;
    left: 65px;
    display: none;
}

/* .lazy-load {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s ease;
} */



.carosuel-grid {
    background: url("../../img/dster-webp/mxc-bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(5px);
}

.mx-card-img {
    width: 100% !important;
    height: 230px;
}

.mx-c-head {
    color: #fff;
    font-size: 18px;
    text-align: center;
}

.mx-c-para {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.css-doq0dk div {
    opacity: 100% !important;
}