.feed-new-main-grid {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(5px);
}

.feed-new-search {
    display: var(--flex);
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
    /* width: 250px; */
}

.feed-new-search input {
    border: unset;
    /* border-right: 1px solid #000; */
    padding-right: 10px;
    width: 400px;
    font-family: inherit;
}

.feed-new-whole {
    display: var(--flex);
    align-items: center;
    justify-content: space-between;
}

.feed-new-player {
    margin-top: 350px;
    display: var(--flex);
    align-items: flex-start;
    flex-flow: wrap;
}

.feed-new-player-controls img {
    width: 70px;
    height: 70px;
    border-radius: 15px;
}

.track-feednew span.MuiSlider-rail {
    color: #fff;
    height: 4px;
    opacity: 100% !important;
}

.track-feednew span.MuiSlider-track {
    border-radius: 0px 6.98px 6.98px 0px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    box-shadow: 0px 0px 6.98px 0px rgba(255, 55, 64, 0.30);
    height: 4px;
}

.audio-timmer-feed {
    display: var(--flex);
    align-items: center;
    color: #fff;
    justify-content: space-between;
}

/* .owl-item {
    width: 180px !important;
} */
.e-news-list .feed {
    display: var(--flex);
    justify-content: space-between;
}

.fd-plps img {
    margin-top: 50px;
}

.box-shadow {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.feedn-p {
    display: var(--flex);
    gap: 10px;
    align-items: center;
}

.sk-stack {
    margin: 15px 0px;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.sk-stack.main {
    flex-flow: wrap;
    gap: 15px;
    /* justify-content: center; */
}

/* .sk-stack-inner{
    width: 100%;
} */
.hover-play {
    position: absolute;
    top: 15px;
    left: 15px;
    display: none;
}

.hover-play2 {
    position: absolute;
    top: 15px;
    left: 0px;
    cursor: pointer;
    display: none;
}

.hover-play3 {
    position: absolute;
    top: 35%;
    left: 35%;
    display: none;
    cursor: pointer;
}

.ts-c1-imgdiv:hover .hover-play,
.hover-play-div:hover .hover-play2,
.hover-play-div:hover .hover-play3 {
    display: block;
}

.hover-play-div {
    position: relative;
}