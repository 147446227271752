.History-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 33.609px;
    font-style: normal;
    font-weight: 900;
    position: sticky;
    top: 0;
    line-height: 46px;
    padding: 20px;
    /* 136.867% */
    color: #7227e4 !important;
    background-color: var(--clr-explore-head);

}


.History-box {
    background: var(--clr-explore-bg) !important;
}


.History-content-heading {
    text-align: left;
    /* text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20); */
    font-family: Arial;
    font-size: 27.609px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    padding-top: 10px;
    /* 136.867% */
    color: #7227e4 !important;

}

.History-box2 .MuiContainer-root {
    border-radius: 10px;
    background-color: var(--clr-explore-head);

}

.History-box2 .MuiGrid-root {
    padding: 10px;
    /* margin: 25px 0px; */
    justify-content: center;
}

.description-History {
    text-align: center;
    color: #858199;
    font-size: 14px;
    padding: 10px;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}


.start-listen-btn2 {
    display: flex;
    justify-content: center;
}

.start-listen-btn2 button {
    color: #CC0FE0;
    text-align: center;
    font-family: Arial;
    font-size: 14.859px;
    font-style: normal;
    padding: 14px;
    font-weight: 700;
    line-height: 18px;
    /* 100.787% */
    border-radius: 4px;
    border: 1px solid #B80ECA;
    background: var(--clr-explore-head);
    text-transform: capitalize;
}

.feed h2 {
    text-shadow: 0px 2px 4px rgba(162, 47, 235, 0.20);
    font-size: 32.906px;
    font-style: normal;
    font-weight: 900;
    background: linear-gradient(296deg, #5B23E1 0%, #A22FEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.feed-head{
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    background-color: var(--clr-explore-head);
    /* position: fixed;
    width: 83%; */
}
.feed-tab .active-feed.Mui-selected{
    border-radius: 8px;
    border: 1px solid #7E1BCC;
    background: #7E1BCC;
    color: #fff;
    font-weight: 600;
    border: none;
}
.feed-tab .MuiTabs-indicator{
    background: none !important;
}
.feed-tab .active-feed{
   min-height: 0 !important;
   border-radius: 8px;
    border: 1px solid #C2C0CC;
    margin-left: 15px;
    padding: 5px 10px;
    color: #AAA7B8;
    font-weight: 600;
}
.MuiContainer-maxWidthMd{
    max-width: 1014px;
}
.feed-bg{
    background: var(--clr-explore-bg) !important;
}
.music-detail {
    display: flex;
    gap: 20px;
}
.album-detail a {
    color: #858199;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 5px;
}
.link-badge a{
    font-weight: 500;
}
.album-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.link-badge {
    display: flex;
    gap: 10px;
}
.link-badge img {
    width: 20px;
}
.loop-part {
    display: flex;
    gap: 20px;
    align-items: center;
}
.MuiSvgIcon-fontSizeMedium {
    font-size: 15px;
}
.repost-loop p {
    font-weight: 500 !important;
    color: #C2C0CC;
}
.share-btn {
    color: #c2c0cc;
    display: flex;
    gap: 25px;
}
.share-btn .MuiSvgIcon-fontSizeMedium{
    font-size: 20px;
}
.feed-music-box {
    border-radius: 8px;
    background-color: var(--clr-explore-head);
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);
    padding: 11px 16px;
}
.music-choice-box{
    margin-bottom: 20px;
}
.tab-cnt {
    margin-top: 100px;
    padding-bottom: 150px;
}
.music-timing p{
    margin: 0;
}
.album-img{
    height: 125px;
    width: 125px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(133, 129, 153, 0.10), 0px 1px 0px 0px #E3E3E3, 0px 2px 5px 0px rgba(133, 129, 153, 0.25);

}
.album-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.loop-times{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.music-time {
    display: flex;
    justify-content: space-between;
}
.no-music {
    display: flex;
    justify-content: center;
}
.like-dot {
    display: flex;
    justify-content: space-between;
}
.music-timing p {
    color: #C2C0CC;
}
.music-timing .MuiSvgIcon-fontSizeMedium{
    color: #C2C0CC;
}






@media(max-width:767.98px){
    .feed-head{
        width: 100%;
        display: block;
        text-align: center;
    }
    .tab-cnt {
        margin-top: 170px;
    }
}
@media(max-width:991.98px){
    .feed-head{
        width: 100%;
    }
}