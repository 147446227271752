svg {
  cursor: pointer !important;
}

.dark-theme {
  --clr-bcg: #282828;
  --clr-bcg2: #000;
  --clr-respo-header-bg: #282828;
  --clr-music-player: #282828;
  --clr-font: #fff;
  --clr-primary: #ffa7c4;
  --clr-explore-bg: #000;
  --clr-music-track: #ea5aff;
  --clr-track-time: #858199;
  --clr-inner-bg: #414141;
  --clr-explore-head: #282828;
  --clr-upload-font: #fff;
  --clr-upload-box-bg: #282828;
  --clr-playerzone-card: rgba(255, 255, 255, 0.10);
  --clr-playerzone-font: #B5BBCC;
  --clr-fanzone-box: rgba(0, 0, 0, 0.87);
  --clr-per-box: #fff;
  --clr-load-box: rgba(255, 255, 255, 0.20);
  --clr-time: #B5BBCC;
  --clr-bgimg: url(./img/dster-webp/player-banner-bg.webp);
  --clr-header-bg: #000;
  --clr-tips-bg: rgba(255, 255, 255, 0.20);
  --clr-fanbg: url(./img/dster-webp/fanzone-banner-bg.webp);
  --clr-circlearrow: #fff;
  --clr-volume: #fff;
  --clr-filter: #F2F2F2;
  --clr-active: #000;
  --clr-profile-bcg: #3e3e3e;
  --clr-border: none !important;
  --clr-bckstage-bg: #111;
  --clr-cardbg: #1E1E1E;
  --clr-btn: #2D2D2D;
  --clr-search-border: #fff;
  --clr-hoisting-bg: #111111;
  --clr-form-txt: #B1B5C3;
  --clr-form-input: #282828;
  --clr-creator-bg: #414141;
  --clr-notify: transparent;
  --clr-polls: #000;
  --clr-new-exp: #fff;
  --clr-num: #000;
  --clr-skel: #414141;
  --clr-bcgnew: #111111;
  --clr-pcnew: #1D1D1D;
  --clr-upload: #343333;
  --clr-bcg3: #151515;
  --clr-bcg4: #343333;
  --clr-bcg5: #1E1E1E;
  --clr-bcg6: #3A3A3A;
  --clr-bcg7: #1d1f23;
  --clr-bcg8: #27344D;
  --clr-bck-inner: #1111114D;
}

.light-theme {
  --clr-bcg: #fff;
  --clr-respo-header-bg: #fff;
  --clr-bcg2: #070606;
  --clr-music-player: #fff;
  --clr-font: #282c35;
  --clr-primary: #d23669;
  --clr-explore-bg: #f3f0f7;
  --clr-music-track: #ea5aff;
  --clr-track-time: #000;
  --clr-inner-bg: #f3f0f7;
  --clr-explore-head: #fff;
  --clr-upload-font: #000;
  --clr-upload-box-bg: none;
  --clr-playerzone-card: rgba(0, 0, 0, 0.10);
  --clr-playerzone-font: #0043FF;
  --clr-fanzone-box: #fff;
  --clr-per-box: #4D4D4D;
  --clr-load-box: rgba(255, 0, 0, 0.20);
  --clr-time: #FF00F5;
  --clr-bgimg: url(./img/dster-webp/player-banner-bg-light.webp);
  --clr-header-bg: #fff;
  --clr-tips-bg: rgba(255, 0, 214, 0.20);
  --clr-fanbg: url(./img/dster-webp/fanzone-banner-bg-light.webp);
  --clr-circlearrow: #FF0000;
  --clr-volume: #000;
  --clr-filter: #F2F2F2;
  --clr-active: #F2F2F2;
  --clr-profile-bcg: #fff;
  --clr-border: 1px solid #CC0FE0;
  --clr-bckstage-bg: #fff;
  --clr-cardbg: #fff;
  --clr-btn: #CC0FE0;
  --clr-search-border: #282c352e;
  --clr-hoisting-bg: #f3f0f7;
  --clr-form-txt: #000;
  --clr-form-input: #dfdfdf;
  --clr-creator-bg: #8027b4;
  --clr-notify: #f3f2f2;
  --clr-polls: #e7e7e7;
  --clr-new-exp: #2E3271;
  --clr-num: #fff;
  --clr-skel: #f4f4f4;
  --clr-bcgnew: #fff;
  --clr-pcnew: #f4f4f4;
  --clr-upload: #f4f4f4;
  --clr-bcg3: #f4f4f4;
  --clr-bcg4: #ffffff;
  --clr-bcg5: #f4f4f4;
  --clr-bcg6: #dfdfdf;
  --clr-bcg7: #fff;
  --clr-bcg8: #fff;
  --clr-bck-inner: #fff;
}

.light-theme.scrolled {
  --clr-scr: #fff;
}

.hidden-cursor {
  cursor: none;
}


.App {
  font-family: 'Poppins', sans-serif !important;
  background-color: var(--clr-explore-bg) !important;
  /* height: 100vh; */

}

body {
  background: var(--clr-explore-bg) !important;
}


.App.hv {
  height: 100vh
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 12px;
  /* background: #cbcbcb; */
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background-color: #c6d8ff00;
}


svg {
  background: none !important;
}

fieldset {
  border-color: transparent !important;
}

:root {
  /* fonts */
  --font-dm-sans: "DM Sans";
  --font-rubik: Rubik;

  /* font sizes */
  --font-size-mini: 15px;
  --font-size-lg-2: 18.2px;

  /* Colors */
  --color-gray-100: #131313;
  --color-white: #fff;
  --secondary: #777e90;
  --secondary2: #b1b5c3;
  --critical: #d33535;
  --color-gainsboro: #d9d9d9;

  /* Gaps */
  --gap-xs: 12px;

  /* Paddings */
  --padding-sm: 14px;
  --padding-10xs: 3px;
  --padding-3xs: 10px;
  --padding-mini: 15px;
  --padding-4xs: 9px;
  --padding-mid: 17px;
  --padding-9xs: 4px;

  /* display */
  --flex: flex;


}

.newvolslider {
  height: 100px !important;
  padding: 0 9px !important;
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/*Cursor*/

.cursor {
  cursor: pointer;
}

.pad-10 {
  padding: 10px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.pad-5 {
  padding: 5px;
}

.display-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.display-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.display-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.margin-top {
  margin-top: 20px;
}

.green {
  color: #00B477;
}

.margin-t-100px {
  margin-top: 100px;
}

.padding-10 {
  padding: 10px;
}