.artist-name-new {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
}

.artist-tag {
  padding: 2px 6px;
  background: #ccc;
  /* -webkit-text-stroke: thick; */
  color: #000;
  width: fit-content;
  margin: 15px auto;
  font-size: 10px;
  font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  position: relative;
  border-radius: 8px;
}

.artist-name-tag {
  margin: 10px 0px;
  font-family: "Avenir Next LT Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-size: 42px;
  font-weight: 900;
  color: var(--clr-font);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  text-transform: capitalize;
}

.artist-page-pannel {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  flex-direction: column;
  top: -70px;
}

.artist-user-name {
  font-size: 12px;
}

.artist-follow-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.follow-inner-artist {
  display: flex;
  align-items: center;
  gap: 25px;
}

.artist-track {
  color: #9d9aad;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0.7px;
  margin-bottom: 4px;
  text-align: center;
  cursor: pointer;
}

.artist-track-inner {
  font-size: 14px;
}

.artist-page-pannel .follow-inner2 {
  width: 100%;
}

.artist-follow-btn button {
  display: flex;
  justify-content: center;
  flex: 0 0 132px;
  padding: 3px 16px;
  border: 1px solid var(--clr-music-track);
  border-radius: 4px;
  /* color: var(--clr-font); */
  color: var(--clr-font);
  text-transform: capitalize;
}

.artist-follow-btn button svg {
  margin-right: 10px;
  fill: var(--clr-font);
}

.artist-follow-btn button:hover {
  background-color: var(--clr-music-track);
  color: #fff !important;
}

.artist-follow-btn button:hover svg {
  fill: #fff !important;
}

.artist-follow-more-msg-mian {
  display: flex;
  /* align-items: flex-end; */
  gap: 20px;
}

.more-svg-artistpage svg {
  font-size: 30px !important;
  padding: 3px;
  /* border-radius: 25px; */
  background: var(--clr-notify) !important;
  cursor: pointer;
  border: 1px solid var(--clr-font) !important;
}

.more-svg-artistpage:hover svg {
  background: var(--clr-music-track) !important;
  fill: #fff;
}

.library-tab2 svg {
  background-color: transparent !important;
  /* color: var(--clr-font) !important; */
}

.library-tab2 svg path {
  fill: var(--clr-font);
}

.library-tab2 button.Mui-selected path {
  fill: #fff;
}

.library-tab2 button,
.library-tab2 button.Mui-selected {
  padding: 0.5px 9.98px 4.5px 9.97px;
  color: var(--clr-font);
  text-align: center;
  font-family: Arial;
  font-size: 12.797px;
  font-style: normal;
  font-weight: 900;
  line-height: 14px;
  /* 109.402% */
  letter-spacing: 0.66px;
  min-height: 40px;
}

.library-tab2 button.Mui-selected {
  color: var(--clr-font) !important;
  background-color: unset !important;
  border-radius: 15px;
}

.library-tab2 .MuiTabs-indicator {
  background-color: #7118b8 !important;
  height: 3px;
  /* display: none; */
}

.library-tab2-box {
  width: fit-content;
  border: 1px solid #8e2be8 !important;
  margin: auto;
  border-radius: 10px;
}

.library-tab2 {
  align-items: center;
  padding: 2px 4px;
}

.cust-pannel-artistpage {
  text-align: center;
}

.tire-main-div {
  display: flex;
  gap: 15px;
  align-items: center;
  background: var(--clr-bcg) !important;
  padding: 10px;
  border-radius: 5px;
}

.tire-level {
  color: var(--clr-font);
  font-weight: 700;
  /* font-size: font-s; */
  line-height: 14px;
}

.artist-stamp-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tire-medal {
  font-weight: 900;
  color: var(--clr-font);
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px 0px;
}

.art-mini-logo img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
}

.art-mini-logo2 img {
  width: 25px;
  height: 25px;
}

.avatar-div-stack {
  padding: 10px 15px;
  background: var(--clr-bcg) !important;
  border-radius: 10px;
  font-weight: 900;
  color: #dad9e0;
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.12s ease-in-out;
  box-shadow: 0 0 1px -2px #8581991a, 0 1px 0 2px #e3e3e3, 1px 2px 5px #85819940;
}

.avatar-div-stack svg {
  fill: #dad9e0;
  font-size: 16px;
}

.avatar-div-stack:hover,
.avatar-div-stack:hover svg {
  color: #ea5aff !important;
  fill: #ea5aff !important;
  /* box-shadow: 0 0 1px -2px #8581991a, 0 1px 0 2px #e3e3e3, 1px 2px 5px #85819940; */
  transform: scale3d(1.015, 1.015, 1.015);
}

.avatar-div-stack {
  margin: 10px 0px;
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
}

.vl {
  border-left: 1px solid #dad9e0;
  height: 40px;
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -3px; */
  /* top: 0; */
}

.artist-description-cont {
  font-size: 12px;
  color: #858199;
  line-height: 20px;
  padding: 10px;
}

.artist-nft-link {
  font-size: 12px;
  color: #858199;
  line-height: 20px;
  padding: 10px;
}

.artist-nft-link p {
  margin: 2px;
  color: #ea5aff;
  text-decoration: underline;
  cursor: pointer;
}

.TIP-audion-btn {
  text-align: center;
  margin: 15px 0px;
}

.TIP-audion-btn button {
  background: var(--clr-music-track);
  text-transform: inherit !important;
  color: #fff !important;
  align-items: center;
  font-weight: 900;
  line-height: 20px;
  font-size: 16px;
  gap: 10px;
  transition: all 0.12s ease-in-out;
}

.TIP-audion-btn button:hover {
  background: var(--clr-music-track);
  text-transform: inherit !important;
  color: #fff !important;
  align-items: center;
  font-weight: 900;
  line-height: 20px;
  font-size: 16px;
  gap: 10px;
  transform: scale3d(1.025, 1.025, 1.025);
}

.top-support {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #858199;
  line-height: 20px;
  padding: 10px;
  font-weight: 700;
  margin: 15px 0px;
}

.artist-sub-div-follow-stack {
  padding: 15px;
}

.artist-sub-div-follow-stack svg {
  font-size: 22px;
  cursor: pointer;
}

.artist-sub-div-follow-stack svg:hover {
  fill: #ea5aff;
}

.top-tags-content {
  padding: 15px;
  background: var(--clr-bcg) !important;
}

.top-tags-content div {
  margin: 10px 0px;
  padding: 0px 4px;
  background: #c2c0cc;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.5px;
}

.top-tags-content div:hover {
  background-color: #ea5aff !important;
}

.artist-sidebar-list {
  padding: 10px !important;
  background: var(--clr-bcg) !important;
}

.artist-explore-more {
  /* background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%); */
  color: #fff !important;
  /* position: fixed !important; */
  /* z-index: 999; */
  /* top: 250px; */
  /* left: 232px; */
  /* min-width: 50px !important; */
  /* writing-mode: vertical-rl; */
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 4px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  text-orientation: sideways-right;
  /* transition: rotate(90deg) !important; */
  /* transform: rotate(180deg) !important; */
  flex-direction: column;
  padding: 10px 5px;
}

.virtualmeet-btn button:hover {
  /* font-family: Arial; */
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  color: #fff !important;
  letter-spacing: 0.5192834138870239px;
  text-align: center;
  background: #cc0fe0;
  padding: 10px;
  border: unset !important;
}

.virtualmeet-btn button {
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  color: #cc0fe0 !important;
  letter-spacing: 0.5192834138870239px;
  text-align: center;
  /* background: linear-gradient(0deg, #282828, #282828),
    linear-gradient(0deg, #dad9e0, #dad9e0); */
  padding: 10px;
  border: 1.04px solid #dad9e0;
}

.virtualmeet-btn {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-redesign-cont {
  background: var(--clr-bcg);
  border-radius: 30px;
  z-index: 999;
  position: relative;
}

.new-prf-avt {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 1px;
  color: #7c8db5b8 !important;
  cursor: pointer;
}

.tar {
  display: flex;
  align-items: center;
  width: 96px;
}

.tar img {
  width: 40px;
  height: 40px;
}

.library-tab2 .MuiTabs-flexContainer {
  justify-content: center;
  margin-top: 30px;
}

.input-box-txt.artist input {
  border: 1px solid var(--clr-font);
  /* border-radius: 12px; */
  color: var(--clr-font);
  background-color: var(--clr-bcg);
}

.inputbox.art {
  margin-top: 15px;
}

.inputbox.art input {
  padding: 5px 10px;
  border-radius: 5px;
}

.inputbox.art p {
  color: var(--clr-font);
}

.poll-nav {
  display: var(--flex);
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.poll-nav img {
  width: 40px;
  height: 40px;
}

.poll-nav-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  /* justify-content: center; */
}

.poll-nav:hover {
  transform: scale(1.1);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) */
}

.canceltwotone svg {
  position: absolute;
  right: 0;
  top: 0;
}

.artist-side .MuiDrawer-paperAnchorLeft {
  background-color: var(--clr-bcg);
}

.shining {
  animation: shine 3s ease-in-out infinite;
}

.ar6 {
  position: relative;
}

.vertiacl-stk {
  position: absolute;
  left: -22px;
  top: 32%;
}

.vertiacl-stk svg {
  color: #fff !important;
}

.artist-sub-div-follow-stack.art {
  background-color: #b700ff;
  border-radius: 10px;
}

.artist-sub-div-follow-stack.art svg {
  font-size: 18px !important;
}


@keyframes shine {
  0% {
    opacity: 1;
    box-shadow: 0 0 10px 0 #b700ff;
  }

  50% {
    opacity: 1;
    box-shadow: 0 0 20px 10px #b700ff;
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 10px 0 #ff0015;
  }
}

.display-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-play img {
  width: 30px;
}