.albumpage-grid {
    justify-content: center;
    margin-top: 100px;
}
#pauseImage{
    width: 35px;
    height:35px
}

.play-btn-albumpage button,
.play-btn-albumpage button:hover {
    padding: 15px 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background: var(--clr-music-track);
    opacity: 100%;
    height: 30px;
    border-radius: 8px;

}

.inside-div .pad {
    padding: 15px 15px 15px 0px !important;
}

.follow-us-div2 .play-btn-albumpage,
.follow-us-div2 .play-btn-albumpage:hover {
    border: unset !important;
    background: transparent !important;
}

.play-btn-albumpage button svg {
    background-color: transparent !important;
    font-size: 25px !important;
    color: #fff !important;
    fill: #fff;
    opacity: 100%;
}

.play-btn-albumpage {
    padding: 15px 0px;
}

.follow-us-div2 {
    display: flex;
    gap: 30px;
    color: var(--clr-font);
    margin: 30px 0px;
    flex-flow: wrap;
    justify-content: center;
}

.follow-us-div2 div {
    padding: 0px 15px !important;
    border: 1px solid var(--clr-font);
    opacity: 70%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    border-radius: 30px;
}

.follow-us-div2 div:hover,
.follow-us-div2 div:hover svg {
    background-color: var(--clr-music-track) !important;
    fill: #fff !important;
    color: #fff;
    opacity: 100%;
    border: unset !important;
}

.table-div-feed th {
    color: var(--clr-font);
    border-bottom: unset;
}

.table-div-feed td svg {
    display: none;
}

.table-div-feed tr {
    position: relative;
}

.table-div-feed tr:hover svg,
.table-div-feed tr:hover .music-play2 {
    display: block !important;
    position: absolute;
    /* left: 25px; */
    top: 25px;

}

.table-div-feed td .music-play2 {
    display: none;


}

.discription-trackpage2 {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 20px 0px;
    justify-content: center;
}

.discription-trackpage2 div {
    color: var(--clr-font);
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    opacity: 60%;
}

.discription-trackpage2 div span {
    color: var(--clr-font) !important;
    opacity: 100%;
    margin-left: 15px;
    /* color: #000 !important; */
    font-weight: 400 !important;
    text-transform: capitalize;
}

.add-track-list .MuiDrawer-paper {
    background: var(--clr-bcg);
    color: var(--clr-font);
}

.bot-drawer-btn {
    color: var(--clr-font) !important;
}

.bot-drawer-btn:hover {
    color: #fff !important;
}