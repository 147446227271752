.discover-genre {
    /* font-family: Inter; */
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B8B8B8;
    text-transform: capitalize;
}

/* .card-container {
    width: 250px;
    height: 300px;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
 */

/* 
.card:hover .content {
    transform: translateY(40%);
} */

/* .card:hover .front-content {
    transform: translateY(-30%);
} */

/* .card:hover .front-content p {
    opacity: 0;
} */

.player-carosuel {
    margin-top: 20px;
}

/* .card img {
    width: 300px;
    height: 300px;
} */

.heading {
    margin: 0px;
}

.acost {
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 700;
    /* line-height: 60.78px; */
    letter-spacing: 0.1em;
    text-align: left;
    color: #DEDEDE;
    padding: 8px 0px;
    display: flex;
    cursor: pointer;
}

.acosttrack {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DEDEDE;
    padding: 8px 0px;
}

/* .owl-stage {
    width: 2500px !important;
} */

.showmoewnew {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.new-play-num {
    /* font-family: Inter; */
    font-size: 30px;
    font-weight: 500;
    line-height: 36.31px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.new-play-num.playing,
.new-play-artist.playing,
.play-new-time.playing {
    -webkit-text-fill-color: unset;
    color: var(--clr-font);

}

.new-play-artist {
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    text-transform: capitalize;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.marquee-ply {
    animation: marquee 7s linear infinite;
}

.play-new-time {
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-align: left;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.loading-wave {
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.loading-bar {
    width: 4px;
    height: 8px;
    margin: 0 1px;
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
    0% {
        height: 8px;
    }

    50% {
        height: 30px;
    }

    100% {
        height: 8px;
    }
}

.pause-new {
    fill: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
}

.play-card-new {
    border-radius: 12px;
    padding: 15px 25px;
    background: var(--clr-pcnew);
    margin: 15px 0px;
    cursor: pointer;

}

.card {
    width: 220px;
    height: 260px;
    /* background: white; */
    border-radius: 32px;
    margin: 5px auto;
    cursor: pointer;
    padding: 3px;
    position: relative;
    /* box-shadow: #604b4a30 0px 70px 30px -50px; */
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    transition: all 0.5s ease-in-out;
}

.card .mail {
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    background: transparent;
    border: none;
}

.card .mail svg {
    stroke: #B5179E;
    stroke-width: 2px;
}

.card .mail svg:hover {
    stroke: #B5179E;
}

.card .profile-pic {
    position: absolute;
    width: calc(100% - 0px);
    height: calc(100% - 6px);
    top: 3px;
    left: 0px;
    border-radius: 29px;
    z-index: 1;
    border: 0px solid #B5179E;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0.2s, z-index 0.5s ease-in-out 0.2s;
}

.card .profile-pic img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    -o-object-position: 0px 0px;
    object-position: center;
    transition: all 0.5s ease-in-out 0s;
}

.card .profile-pic svg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0px 0px;
    object-position: center;
    transform-origin: 45% 20%;
    transition: all 0.5s ease-in-out 0s;
}

.card .bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    /* background: #ffffff36; */
    /* background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%); */
    background: #454545d6;

    backdrop-filter: blur(10px);
    top: 75%;
    border-radius: 0px 0px 29px 29px;
    z-index: 2;
    box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.card .bottom .content.new {
    position: absolute;
    bottom: -15px;
    left: 1.5rem;
    right: 1.5rem;
    height: 160px;
}

.card .bottom .content.new .name {
    display: block;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
}

.card .bottom .content.new .about-me {
    display: block;
    font-size: 0.9rem;
    color: white;
    margin-top: 1rem;
}

.content.new {
    display: block !important;
}

.card .bottom .bottom-bottom {
    position: absolute;
    bottom: 0.5rem;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card .bottom .bottom-bottom .social-links-container {
    display: flex;
    gap: 1rem;
}

.card .bottom .bottom-bottom .social-links-container svg {
    height: 20px;
    fill: white;
    filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
}

.card .bottom .bottom-bottom .social-links-container svg:hover {
    fill: #B5179E;
    transform: scale(1.2);
}

.card .bottom .bottom-bottom .button {
    background: white;
    color: #B5179E;
    border: none;
    border-radius: 20px;
    font-size: 0.6rem;
    padding: 0.4rem 0.6rem;
    box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
}

.card .bottom .bottom-bottom .button:hover {
    background: #B5179E;
    color: white;
}

.card:hover {
    border-top-left-radius: 55px;
}

.card:hover .bottom {
    top: 20%;
    border-radius: 80px 29px 29px 29px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

.card:hover .profile-pic {
    width: 80px;
    height: 80px;
    aspect-ratio: 1;
    top: 20px;
    left: 10px;
    border-radius: 50%;
    z-index: 3;
    border: 3.5px solid #B5179E;
    box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px;
    transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
}

.card:hover .profile-pic:hover {
    transform: scale(1.3);
    border-radius: 0px;
}

.card:hover .profile-pic img {
    transform: scale(1.0);
    -o-object-position: center;
    object-position: center;
    transition: all 0.5s ease-in-out 0.5s;
}

.card:hover .profile-pic svg {
    transform: scale(2.5);
    transition: all 0.5s ease-in-out 0.5s;
}

.tooltip-container {
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 17px;
    border-radius: 10px;
}

.tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    border-radius: 15px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
}

.profile {
    background: #2a2b2f;
    border-radius: 10px 15px;
    padding: 10px;
    border: 1px solid #52382f;
}

.tooltip-container:hover .tooltip {
    top: -150px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.icon {
    text-decoration: none;
    color: #fff;
    display: block;
    position: relative;
}

.layer {
    width: 55px;
    height: 55px;
    transition: transform 0.3s;
}

.icon:hover .layer {
    transform: rotate(-35deg) skew(20deg);
}

.layer span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border: 1px solid #fff; */
    border-radius: 15px;
    transition: all 0.3s;
}

.layer span,
.text {
    color: #B5179E;
    border-color: #B5179E;
}

.icon:hover.layer span {
    box-shadow: -1px 1px 3px #B5179E;
}

.icon .text {
    position: absolute;
    left: 50%;
    bottom: -5px;
    opacity: 0;
    font-weight: 500;
    transform: translateX(-50%);
    transition: bottom 0.3s ease, opacity 0.3s ease;
}

.icon:hover .text {
    bottom: -35px;
    opacity: 1;
}

.icon:hover .layer span:nth-child(1) {
    opacity: 0.2;
}

.icon:hover .layer span:nth-child(2) {
    opacity: 0.4;
    transform: translate(5px, -5px);
}

.icon:hover .layer span:nth-child(3) {
    opacity: 0.6;
    transform: translate(10px, -10px);
}

.icon:hover .layer span:nth-child(4) {
    opacity: 0.8;
    transform: translate(15px, -15px);
}

.icon:hover .layer span:nth-child(5) {
    opacity: 1;
    transform: translate(20px, -20px);
}

.avatar-music2 .MuiAvatar-root {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    border: 3.5px solid #B5179E;
}

.uploadingplayerdiv {
    border: 1px solid #175AFF;
    border-radius: 15px;
    padding: 20px;
    /* border-image-source: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%); */
    margin: 20px 0;
    background: var(--clr-bcg4);

}

.uploadprogess .MuiLinearProgress-root {
    height: 23px;
    background: #999999;
    border-radius: 15px;

}

.uploadprogess .MuiLinearProgress-bar {
    border-radius: 15px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    box-shadow: 12px 11px 37.5px 0px #FA00FF33;

}

.compe {
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    margin-top: 15px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    width: fit-content;
    margin: 15px auto;
}

.pypas svg {
    color: #fff !important;
}

/* .new-play-artist {} */

.nodta img {
    width: 150px;
    height: 150px;
}

.joinask.x2 button {
    text-transform: capitalize;
    width: fit-content;
    margin: 10px auto;
}

.joinask.x2 {
    text-align: center;
}