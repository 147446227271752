.Live-tet {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B9B9B9;

}

.livechat-banner {
    height: 90vh;
    overflow-y: scroll;
    border-radius: 12px;
}

.livechat-type {
    position: sticky;
    bottom: 0px;
    padding: 15px;
    /* height: 100vh; */
    /* width: 80%; */
}

.livechat-type .MuiFormControl-root {
    background: #414141A6;
    border-radius: 15px;
    width: 100%;


}


.livechat-type input {
    color: var(--clr-font);
    padding: 10px;
}

em-emoji-picker,
[data-emoji-set="native"] {
    width: 100% !important;
}

/* .leftsidechat {} */

.chat-memname {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 500;
    line-height: 17.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    padding: 2px;
    text-transform: capitalize;
}

.chat-memname span {
    /* font-family: Inter; */
    font-size: 10px;
    font-weight: 300;
    line-height: 12.1px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0px 5px;
    color: var(--clr-font);
    text-transform: capitalize;
}

.chat-message {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    text-transform: capitalize;
    background: var(--clr-pcnew);
    padding: 10px;
    margin-top: 5px;
    border-radius: 0 12px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.mappedchat {
    margin: 20px 0px;
}

.chat-message.right {
    border-radius: 12px 0px 12px 12px !important;
}

.mappedchat.right {
    justify-content: flex-end !important;
}

.livechathead {
    background: #ffffff2a;
    position: sticky;
    padding: 15px;
    backdrop-filter: blur(5px);
    top: 0;
    z-index: 15;
}

.livechat-type {
    background: var(--clr-hoisting-bg);
    /* z-index: 15; */
    backdrop-filter: blur(5px);
}

.leftsidechat,
.rightsidechat {
    padding: 10px;
}

.nochat-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
}

.strt-convo button {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%) !important;
}

.typee img {
    width: 50px;
}

.tyinp {
    transition: opacity 0.5s ease, visibility 0.5s ease;
}