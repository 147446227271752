.musicmain {
    text-align: center;
    /* / display: flex; / */
}

.musicdiv {
    border-radius: 63px;
    border: 1px solid #FFF;
    /* / background: linear-gradient(123deg, rgba(79, 79, 79, 0.10) 1.74%, rgba(79, 79, 79, 0.10) 1.75%, rgba(255, 255, 255, 0.10) 33.05%, rgba(79, 79, 79, 0.10) 97.16%); / */
    box-shadow: 4px -5px 32px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    padding: 7px 14px;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
}


.musiccont {
    /* / display: flex; / */
    border-radius: 10px 10px 0px 0px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    align-items: center;
    /* / justify-content: space-between; / */
    /* gap: 15px; */
    padding: 5px 10px;
    /* / width: 100%; / */

    justify-content: center;
}

.musiccontx2 {

    /* / display: flex; / */
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 5px 10px;
    width: fit-content;
}

.musiccont2 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.musicconthead {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    overflow-x: hidden;
}

.musicconthead span {
    display: inline-block;
    animation: slide 8s infinite;
    /* / Adjust animation duration as needed / */
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}



.musiccontpara {
    color: #C9C9C9;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 5px 0px;
}


.musicimg img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

.musiccont3 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.musicimg {
    display: flex;
}

.musiccontainer {
    /* / background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)); / */
    padding: 15px 0px;
    justify-content: center;
    position: sticky;
    bottom: 0;
    z-index: 1001;
    background-color: unset !important;
    border: unset !important;
}

.volumeslider {
    position: absolute;
    bottom: 3px;
    right: 15px;
}


.volbox {
    position: absolute;
    bottom: 30px;
    right: -10px;
}

.stk img {
    width: 20px;
    height: 16px;
}



.stk svg,
.volumeslider svg,
.musiccont3 svg {
    color: #fff !important;
    font-size: 28px !important;
}

.newvolslider .span.MuiSlider-rail {
    border-radius: 25.5px;
    border: 0.25px solid rgba(0, 0, 0, 0.50);
    opacity: 0.6;
    background: #FFF;
}

.speed_dail button,
.speed_dail button:hover {
    background: transparent;
    width: 0px;
    height: 5px;
}



.musicslider {
    position: relative;
    display: flex;
    justify-content: center;
    /* / width: 100%; / */
    align-items: center;
    gap: 5px
}

.mini_more {
    background-color: var(--clr-bcg);
    color: var(--clr-font);
    padding: 10px 0px;
    /* / border-radius: 10px; / */
}

/* .popover div {
    background-color: var(--clr-bcg);
} */

.playpause {
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 30px;
    cursor: pointer;
}

.playpause:hover {
    background: #FC5AFF;
    color: #fff;
}



@media (max-width: 768.98px) {
    .musicdiv {
        gap: 20px !important;
        flex-direction: column !important;
        border-radius: 15px !important;
    }

    .stk {
        width: 100%;
        justify-content: space-evenly;
    }

    .volumeslider {
        position: absolute;
        right: 15px;
        top: -64px;
    }

    .musiccont {
        flex-flow: wrap;
    }

}