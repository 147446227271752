.go-violet {
  color: #fc5aff !important;
}

.footer-text {
  color: var(--clr-upload-font);
  /* font-family: Inter; */
  font-size: 15.25px;
  /* font-style: normal; */
  font-weight: 400;
  line-height: 25.6px;
  /* 167.869% */
  letter-spacing: 0.16px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
}

.follow-us a {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  gap: 10px;
  margin-top: 10px;
}

.footer {
  background: var(--clr-bcg7);
}

.go-violet {
  color: #fc5aff !important;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 10px;
}

.go-violet2 {
  line-height: 19px;
  background: linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  padding: 20px 0;
  font-weight: 700;
  font-size: 20px;
}

.footer-text {
  gap: 10px;
}

.footer .header-logo {
  text-align: left;
}

.follow-us svg path {
  fill: var(--clr-upload-font);
}

.discover-fanzone svg path {
  fill: var(--clr-circlearrow);
}

.footer-policy span {
  color: #fff;
  font-size: 13.016px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.16px;
}

.footer-policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terms {
  display: flex;
  gap: 10px;
}

.minilogo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-text a {
  text-decoration: underline;
}

.footer-text.new a {
  text-decoration: none !important;
}

.footer hr {
  border: 1px solid #494949;
  width: 100%;
  margin: 50px 0px 25px 0px;
}

.copy-rights {
  display: flex;
  justify-content: space-between;
  color: var(--clr-font);
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  flex-flow: wrap;
  line-height: 22px;
  letter-spacing: 0.15880447626113892px;
}

.off-mail {
  color: var(--clr-font);
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  margin: 15px 0;
}