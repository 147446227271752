.celebrate-c1-main {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--clr-bcg);
    padding: 15px 10px;
    justify-content: space-between;
    margin: 10px;
    border-radius: 8px;


}





.celebrate-c1-img-text-div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.celebrate-c1 img {
    width: 85px;
    height: 85px;
}

.celebrate-c1-text div {
    padding: 8px;
}

.d-100 {
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding: 0px 10px;

}

.celebrate-c1-text:first-child {

    color: var(--clr-font) !important;
    font-size: 19px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.celebrate-c1-text:last-child {

    color: var(--clr-font) !important;
    /* font-family: Roboto; */
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    /* background: #FFFFFF; */
}

.celebrate-c1-btn-div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 30%;
}

.celebrate-c1-btn-div .btn1 {
    background: linear-gradient(0deg, #51DC8E, #51DC8E),
        linear-gradient(0deg, #61FA66, #61FA66);
    color: #fff !important;
    border-radius: 4px !important;
}

.celebrate-c1-btn-div .btn2 {
    color: #175AFF !important;
    border-radius: 4px !important;
    border: 1px solid #175AFF !important;
}

.top-playlist {
    /* font-family: Arial; */
    font-size: 34px;
    font-weight: 900;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--clr-font);

}



.artist.colabrate {
    max-width: 100% !important;
}

.colab-msg-box {
    /* padding: 10px; */
    background: var(--clr-bcg);
    border-radius: 15px;
}

.colab-text-input {
    display: flex;
    gap: 15px;
    justify-content: center;
    /* background-color: var(--clr-font) !important; */
    background: #fff;
    padding: 5px;
    border-radius: 0px 0px 15px 15px !important;

}

.colab-send svg {
    padding: 2px 4px;
    border-radius: 12px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) !important;
    font-size: 30px;
    fill: #fff;
}


.smile svg {
    background: #FC5AFF80 !important;
    border-radius: 15px;
    padding: 4px;
    font-size: 30px;

}

.colab-text-input input {
    background: #ECD4FF !important;
    padding: 10px;
    border-radius: 12px;
    border: unset;
    width: 240px;

}

.colab-chat-area {

    height: 600px;
    overflow-y: scroll;
    margin: 20px 20px;

}

.colab-avatar-cont {
    padding: 15px 10px;
}

.chat-area-inner-text {
    display: flex;
    gap: 15px;
    margin: 15px 0px;
}

.chat-area-inner-text.user {
    display: flex;
    gap: 15px;
    flex-direction: row-reverse;
    margin: 15px 0px;
}

.collab-send-text-main {
    padding: 15px 10px;
    background-color: #e7e7e7;
    border-radius: 15px;
    position: relative;
    margin-top: 10px;
    margin-right: 150px;
}

.collab-send-text-main2 {
    padding: 15px 10px;
    background-color: #e7e7e7;
    border-radius: 15px;
    position: relative;
    margin-top: 10px;
    margin-left: 150px;
}

.collab-send-text-main:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: -5px;
    width: 17px;
    height: 19px;
    background-color: #e7e7e7;
    /* transform: translateY(-50%) rotate(332deg); */
    /* transform: translateY(-50%) rotate(269deg); */
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main2.user::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    right: -6px;
    width: 17px;
    height: 19px;
    background-color: #e7e7e7;
    /* transform: translateY(-50%) rotate(332deg); */
    transform: translateY(-50%) rotate(269deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main .username,
.collab-send-text-main2 .username {
    /* font-family: Eloquia Text; */
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;

}

.collab-send-text-main .txt1,
.collab-send-text-main2 .txt1 {
    /* font-family: Eloquia Text; */
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding: 5px;

}

.collab-send-text-main .time,
.collab-send-text-main2 .time {
    /* font-family: Eloquia Text; */
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
    padding: 5px;
}

div#colab {
    cursor: pointer;
    display: flex;
    width: auto !important;
    /* display: contents; */
    justify-content: flex-start !important;
    align-items: center;
    /* background: transparent; */
    /* max-width: none !important; */
    max-width: 80% !important;
}