.lognew img {
  width: 519px;
  height: 650.469px;
}

.registerNew,
.loginNew {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: hidden;
  background: linear-gradient(0deg, #000 0%, #000 100%), #fff;
}

.grd2 {
  border-radius: 14.844px;
  background: var(--G800, #19181f);
  padding: 20px;
}

.welcm {
  color: #fff;
  /* font-family: Inter; */
  font-size: 23.75px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.43px;
  /* 128.125% */
}

.welcm-desc {
  color: #8692a6;
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.555px;
  /* 156.25% */
  letter-spacing: 0.059px;
}

.usrprf {
  border-radius: 6px;
  border: 1px solid #eceffd;
  background: #fff;
  padding: 20px;
  width: 380px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.artprf {
  border-radius: 6px;
  border: 1px solid #ebedf8;
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  width: 380px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.usrprf-txt {
  color: #000;
  /* font-family: Heebo; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
}

.artprf-txt {
  color: #fff;
  /* font-family: Heebo; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
}

.useprf-ul,
.artprf-ul {
  margin: 3px !important;
  padding-left: 20px !important;
}

.useprf-ul ::marker {
  color: #fc5aff;
}

.useprf-ul li {
  color: #000;
  /* font-family: Heebo; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.artprf-ul li {
  color: #fff;
  /* font-family: Heebo; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.useico {
  border-radius: 12px;
  padding: 18px 22px;
  /* background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%)); */
  box-shadow: 12px 11px 37.5px 0px rgba(250, 0, 255, 0.2);
}

.artico {
  border-radius: 12px;
  border: 1px solid #eceffd;
  background: #fff;
  padding: 15px 20px;
  /* M1 */
  box-shadow: 12px 11px 37.5px 0px rgba(250, 0, 255, 0.2);
}

/* .grd-bcg {
    background: url('../../img/dster-webp/lognew.webp');
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
} */

/* .grd-bcgregis {
    background: url('../../img/dster-webp/regisN.webp');
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
} */

.fName {
  color: var(--G300, #cbcad7);
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.555px;
  margin: 5px auto;
  /* width: 80%; */
}

.logenew-inp.MuiFormControl-root {
  width: 100%;
  border-radius: 4.453px;
  border: 0.742px solid var(--G400, #9794aa);
}

.logenew-inp input {
  padding: 11px;
  color: #fff;
}

.logenew-inp input::placeholder {
  color: var(--G400, #9794aa);
  /* font-family: Inter; */
  font-size: 13.875px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18.555px;
  /* 156.25% */
}

.trms {
  color: var(--G300, #cbcad7);
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.555px;
  /* 156.25% */
}

.bdr {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.regis button {
  color: var(--White, #fff);
  /* font-family: Inter; */
  font-size: 13.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.555px;
  /* 156.25% */
  border-radius: 74.219px;
  background: var(--1, linear-gradient(274deg, #175aff 2.38%, #fc5aff 97.31%));
  padding: 15px;
  text-transform: capitalize;
  width: 80%;
  margin: auto;
}

.ro {
  color: var(--G300, #cbcad7);
  text-align: center;
  /* font-family: Inter; */
  font-size: 8.906px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.regis-g button {
  border-radius: 4.453px;
  background: var(--Black, #100f14);
  text-transform: capitalize;
  width: 80%;
  margin: auto;
  padding: 15px;
}

.regis-g button div {
  color: #fff;
  text-align: center;
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.555px;
  /* 156.25% */
}

.alrdy {
  color: var(--G400, #9794aa);
  text-align: center;
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}

.alrdy span {
  color: var(--White, #fff);
  /* font-family: Inter; */
  font-size: 11.875px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.verifyemail-div {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  height: 50vh;
  justify-content: center;
  overflow: hidden;
}

.check-agree {
  margin: auto;
  width: 80%;
  justify-content: flex-start !important;
  margin-top: 20px;
}

.reg-height {
  height: 450px;
  overflow-y: scroll;
}
