



.player{
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
  }
  .music-player-banner-grid{
    position: relative;
  }
  .playerBanner {
    width: 100%;
    height: 100vh;
    background:var(--clr-bgimg);
    /* background: linear-gradient(0deg, rgba(17, 17, 17, 0.83) 0%, rgba(17, 17, 17, 0.83) 100%), linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0.00) 52.23%, #111 100%), url(../../img/player-banner-bg.png), lightgray 50% / cover no-repeat; */
    position: absolute;
    object-fit: cover;
    background-position: 100% 100%;
    background-size: cover;
    /* opacity: 75%; */
}
.player-platform{
  padding-top: 100px;
  color: var(--clr-upload-font);
  text-align: center;
  font-size: 72px;
  font-style: normal;
  font-weight: 800;
  line-height: 1; /* 100% */
  margin-bottom: 10px;
}
.player-description{
  color: var(--clr-upload-font);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 782px;
  margin: 28px auto 60px;
}
  .music-player-grid{
    padding: 30px 0;
  }

  .explore-box{
    background: var(--clr-explore-bg) !important;
  }






.all-services{
  max-width: 1368px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.all-services .MuiGrid-root.MuiGrid-container{
justify-content: center;
}
  .surprise-offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.surprise-offer h5, .tips h5 {
  color: var(--clr-upload-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  margin-bottom: 8px;
  margin-top: 0;
  text-align: left;
}
.surprise-offer .offer-left span{
  color: var(--clr-playerzone-font);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
}
.player .start-now-offer button{
  padding: 21px 48px;
  border-radius: 12px;

  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: initial; /* 140% */
}
.player .start-now-offer button:first-child{
  border-radius: 12px;
  background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    
}
.player .start-now-offer button:last-child{
  border-radius: 12px;
  background: var(--clr-load-box);
  color: var(--clr-upload-font);
}
.start-now-offer {
  display: flex;
  gap: 12px;
  justify-content: center;
}
.all-services .card{
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 0.41px 24px;
}
.all-services .card.cln{
  padding: 24px 24px 24px 24px !important;
  min-height: 207px;
}
.all-services .card h2{
  color: var(--clr-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  /* min-height: 100px; */
}
.tips .card-con-tip h2 {
  min-height: 100px;
}

.all-services .card p{
  color: var(--clr-playerzone-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.rights-tips p.min-time{
  color: var(--clr-time);
}
.inner-card-img img {
  max-width: 100%;
  height: auto;
  width: auto;
  
}
.inner-card-img {
  margin-top: 16px !important;
  position: relative;
  bottom: -5px;
}
.card-con{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.card.cln img {
  width: 29px;
  height: 30px;
}
.all-services .card p span{
  background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.soon{
  border-radius: 100px;
background: rgba(255, 255, 255, 0.30);

color: var(--clr-font);
text-align: center;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 160% */
letter-spacing: 0.5px;
text-transform: uppercase;
padding: 1.5px 8px;
}
.preference{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.pwr-back{
  max-width: 1025px;
  margin: 0 auto;
  padding-top: 0;
  /* line-height: 62px; */
}
.creator .card p{
color: var(--clr-playerzone-font);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
margin-bottom: 33px;
}
/* .creator .MuiGrid-root.MuiGrid-container{
margin-top: 45px;
margin-bottom: 45px;

} */
.creator.MuiBox-root{
  margin-top: 30px;
}
.per{
  border-radius: 100px;
  background: var(--clr-per-box);
  padding: 19.34px 25.09px 17.66px 25.1px;
  color: #000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
}
.create span {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.winamp span{
  color: #B5BBCC;
}
.winamp > div{
  border-radius: 6px;
  background: var(--clr-load-box);
  width: 170.34px;
  padding: 15px;
  color: var(--clr-upload-font);
}
.create, .winamp {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  text-align: center;
}
.total-per{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  /* height: 173px; */
}
.total-per.fanzone{
  height: 142px;
}
.tips-title a {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}
.tips-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tips.MuiBox-root{
  gap: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.rights-tips span{
  border-radius: 6px;
  background: var(--clr-tips-bg);
  padding: 2px 12px;
  color: var(--clr-upload-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}
.duration{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.rights-tips{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}
.card-con-tip{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 10px;
}
.tips .card {
  padding: 0 !important;
  background: rgb(255 255 255 / 1%) !important;
  /* box-shadow: 0 40px 24px -12px rgb(0 0 0) !important; */
  border-radius: 12px !important;
}

.tips .card img {
  width: 315px;
}
.card.player-fanzone {
  min-height: 420px;
}
.card.player-fanzone .inner-card-img img{
position: relative;
bottom: -7px;
}