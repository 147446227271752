.explore.dashboard {
    overflow-x: hidden;
}

.explore-box {
    margin-bottom: 0 !important;
}

.dashboard .explore-card1 {
    border-radius: 12px;
    background: linear-gradient(91deg, #13C65A 0%, #16A653 100%);
    box-shadow: 0px 2px 8px -2px rgba(196, 81, 193, 0.35);
    height: 200px;
}

.dashboard .explore-card1 img {
    width: 215px;
}

.dashboard .explore-box2 .MuiContainer-root {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    max-width: 1214px;
}

.dashboard .explore-box2 .MuiGrid-root {
    margin: 0 !important;
    /* padding: 0 !important; */
}

.dashboard .dash-spc .dash-spc-contain {
    padding: 0 !important;
}

.dashboard main.explore-box2.MuiBox-root {
    padding: 0px;
}

.dash-btn {
    display: flex;
    width: 159px;
    height: 42px;
    padding: 7px 0px;
    justify-content: center;
    align-items: center;
}

.dash-btn a {
    color: #FFF !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.dash-btn.lib {
    border-radius: 12px;
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.dash-btn.add {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.20);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashboard .btn-flex {
    display: flex;
    margin: 35px 0 25px;
    gap: 20px;
    align-items: center;
}

.dash-btn.add a {
    font-weight: 500 !important;
}

.explore.dashboard .explore-card2 {
    cursor: pointer;
    border-radius: 12px;
    background: linear-gradient(292deg, #57ABFF 0%, #CD98FF 100%);
    box-shadow: 0px 2px 8px -2px rgba(87, 170, 255, 0.35);
    padding: 0;
}

.explore.dashboard .explore-card3 {
    cursor: pointer;
    border-radius: 12px;
    background: linear-gradient(292deg, #BA27FF 0%, #EF8CD9 100%);
    box-shadow: 0px 2px 8px -2px rgba(242, 87, 255, 0.35);
    padding: 0;
}

.dashboard .explore-card3 img {
    padding: 10px;
}

.dashboard .explore-card2::after,
.explore.dashboard .explore-card3::after {
    position: absolute;
    content: "";
    background-image: url("../../img/dster-webp/music-exp.webp");
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    /* right: -30px; */
    left: 133px;
    z-index: 1;
}

.explore.dashboard .explore-card3::after {
    background-image: url("../../img/dster-webp/exp3.webp") !important;
    top: 10px;
}

.explore.dashboard .explore-card2,
.explore.dashboard .explore-card3 {
    position: relative;
    z-index: 0;
    height: 200px;
}

.explore.dashboard .explore-card2 .MuiCardContent-root:last-child,
.explore.dashboard .explore-card3 .MuiCardContent-root:last-child {
    max-width: 332px;
}

.explore.dashboard .content-heading {
    font-size: 18px !important;
}

/* .trend{
    width: 504px;
    height: 200px;
} */
.creator_card {
    border-radius: 14.913px;
    background: var(--1, linear-gradient(294deg, #175AFF 0%, #FC5AFF 100%));
    /* background-color: #175AFF; */
    height: 200px;
    position: relative;
    width: 510px;
    height: 433px;
    /* height: 373px; */
    padding: 35px 20px;
}

/* .creator_card::after{
    position:absolute;
    content:"";
    border-radius: 367.849px;
    background: #F95AFF;
    filter: blur(119.30233001708984px);
    top: 0;
    left: 0;
    width: 367.849px;
    height: 367.849px;
    z-index: 1;
} */
.creator_card::before {
    position: absolute;
    content: "";
    background-image: url("../../img/dster-webp/creator-avatar.webp");
    background-repeat: no-repeat;
    width: 282px;
    height: 310px;
    bottom: 0;
    left: 0;
}

.token_creator {
    position: relative;
    left: 51%;
    top: -17px;
    border-radius: 9.942px;
    border: 0.994px solid var(--1, #175AFF);
    background: rgba(30, 30, 30, 0.50);
    backdrop-filter: blur(61.8565788269043px);
    padding: 20px 14px;
    /* max-width: fit-content; */
    max-width: 236px;
    /* width: 350px; */
}

.user_profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user_profile p {
    margin: 0;
}

.token_creator h6 {
    color: #F9F9F9;
    font-size: 13.919px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 0 12px;
}

.token_creator p {
    color: #F9F9F9;
    font-size: 15.907px;
    font-style: normal;
    font-weight: 500;
    line-height: 0.65;
}

.token_creator span {
    color: #FFF;
    font-size: 7.942px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user_profile {
    border-radius: 5.965px;
    background: #2D2D2D;
    padding: 5px;
    width: fit-content;
    padding-right: 31px;
}

.user_profile.counts {
    background-color: transparent !important;
    margin: 30px 0 16px;
    justify-content: space-between;
    padding-right: 0;
}

.user_profile.counts h6 {
    margin: 5px 20px 5px 0;
    padding: 0;
}

.counts p {
    background: var(--1, linear-gradient(274deg, #175AFF 2.38%, #FC5AFF 97.31%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.counts h6 {
    color: #F9F9F9;
    font-size: 15.907px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dashboard .copyright {
    display: flex;
    align-items: center;
    gap: 9px;
}

.dashboard .outer_copyright p {
    color: #F9F9F9;
    font-size: 15.907px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.318px;
    padding: 0;
    margin: 0;
}

.copyright img {
    width: 30px;
}

.buy {
    text-align: right;
    border-radius: 5.102px;
    border: 1.275px solid var(--1, #175AFF);
    background: #CC0FE0;
    position: relative;
    right: 0;
    position: relative;
    left: 52%;
    width: 49%;
}

.buy a {
    font-size: 12.754px;
    font-style: normal;
    font-weight: 700;
}

.content-btn.dash-btn.lib {
    margin: 30px 0;
}

.dashboard .explore-content-heading {
    color: var(--clr-font) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.758px;
}

.dash-btn.play {
    border-radius: 6.377px;
    background: #FC59FF;
}

.love-playlist {
    display: flex;
    max-width: none !important;
    padding: 8px 8px;
    border-radius: 7.971px !important;
    background: #292D32;
}

.dashboard .explore-box2 .love-playlist .MuiGrid-root {
    /* justify-content: flex-start !important; */
}

.dashboard .cirle-img img {
    width: auto;
    height: auto;
    clip-path: none !important;

}

.dashboard .cirle-img {
    /* margin: 8px 8px 0; */
}

.dashboard .love-playlist .MuiCardContent-root .love-txt {
    color: var(--clr-font);
    font-size: 10.628px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.942px;
    text-align: left;
}

.dashboard .love-playlist .dash-btn.play {
    border-radius: 6.377px;
    background: #FC59FF;
    width: 140px;
    height: 34px;
    margin: 30px 0 0;
}

.dashboard .love-playlist .dash-btn.play a {
    font-weight: 500;
    text-transform: uppercase;
}

.dashboard .love-playlist img {
    border-radius: 6.377px;
}

.dashboard .love-playlist:hover {
    transform: none !important;
}

.dashboard .explore-box2 .whole-flex-spc.MuiGrid-root {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.artist {
    border-radius: 5.023px;
    background: #1E1E1E;
}

.explore.dashboard .artist .artist-spc.MuiGrid-root {
    justify-content: flex-start !important;
}

.explore.dashboard .artist {
    cursor: pointer;
    display: flex;
    width: auto !important;
    /* display: contents; */
    justify-content: flex-start !important;
    align-items: center;
    /* background: transparent; */
    /* max-width: none !important; */
    max-width: 46% !important;
}

.artist-img {
    width: 45.206px !important;
    height: 45.206px !important;
}

.explore.dashboard .artist .MuiPaper-root.MuiPaper-elevation {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    background-color: #1e1e1e;
    box-shadow: none;
    border: none;
}

.explore.dashboard .artist .artist-txt {
    padding: 10px !important;
    width: 100%;
}

.explore.dashboard .artist .artist-spc {
    /* justify-content: flex-start !important; */
    padding: 0 !important;
}

.explore.dashboard .artist-spc {
    justify-content: flex-start !important;
}

.artist-follow {
    border-radius: 3.014px;
    border: 1.005px solid rgba(221, 221, 221, 0.10);
    background: rgba(0, 0, 0, 0.10);
    width: fit-content;
    text-align: right;
    position: relative;
    left: 74%;
    margin-top: 6px !important;

    padding: 5px;
    color: #FFF;
    text-align: center;
    font-family: Arial;
    font-size: 5.525px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.explore.dashboard .artist .artist-txt .love-txt1 {
    color: #FFF;
    font-size: 7.041px;
    font-style: normal;
    font-weight: 500;
    line-height: 12.055px;
}

.artist_top_cards {
    gap: 5px !important;
}

.explore.dashboard .artist .artist-txt .love-txt2 {
    color: #FFF;
    font-family: Roboto;
    font-size: 7.032px;
    font-style: normal;
    font-weight: 300;
    line-height: 10.046px;
}

.Card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-between;
    width: 100%;
}

.explore.dashboard .artist {
    padding: 8px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.artist_top_cards.css-mhc70k-MuiGrid-root {
    justify-content: flex-start !important;
    width: 100%;
}

.explore.dashboard.playlist .artist {
    max-width: 100% !important;
}

.explore.dashboard.playlist .creator_card {
    width: 100%;
}

.explore.dashboard.playlist .token_creator {
    position: absolute;
    right: 20px !important;
    top: 20px;
    left: auto;
}

.explore.dashboard.playlist .buy {
    right: 0;
    position: absolute;
    width: 53%;
    right: 20px;
    bottom: 50%;
    left: auto;
    margin-bottom: -133px;
}

.creator_card.creator_card-inner {
    height: 350px;
}

.explore.dashboard.playlist .creator_card::before {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: 0;
    left: 0;
    background-size: contain;
}

.explore.dashboard.playlist .participate-card {
    margin-left: 0;
    margin-top: 0;
}

.card-body.card-body-title-card {
    flex-direction: row;
}

.card-body.card-body-title-card .card-title p {
    font-size: 14px;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root {
    margin: 0 !important;
    padding: 6px !important;
}

.explore.dashboard.playlist .card-participate {
    width: 100%;
    padding: 10px;
}

.explore.dashboard.playlist .card-participate .img-card img {
    width: 100%;
}

.explore.dashboard.playlist .card-participate {
    width: 100%;
    padding: 10px;
}

.explore.dashboard.playlist .card-participate .img-card img {
    width: 100%;
}

.explore.dashboard.playlist .join-btn {
    padding: 5px;
    align-items: center;
}

.explore.dashboard.playlist .join-btn a {
    font-size: 14px;
}

.explore.dashboard.playlist .join-icon {
    display: flex;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root {
    align-items: flex-start !important;
}

.explore.dashboard.playlist .explore-box2 .top-playlist-items-list .MuiGrid-root {
    align-items: flex-start !important;
    margin: 5px 0 !important;
}

.top-playlist-items-list {
    width: 100%;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root.artist_top_cards {
    padding-top: 0px !important;
}

.card-title p:last-child {
    font-size: 7px;
}

.explore.dashboard.playlist .explore-card2::after {
    display: none;
}

.explore.dashboard.playlist .explore-card2 .MuiCardContent-root:last-child {
    max-width: 100%;
}

.explore.dashboard.playlist .explore-card2 {
    width: 100% !important;
    justify-content: center;
}

.explore.dashboard.playlist .content-btn.dash-btn.lib {
    width: 100%;
    margin-bottom: 10px;
    background: #CC0FE0;
    margin-top: 10px;
}

.explore.dashboard.playlist .follow-us-grid>.MuiPaper-root {
    max-width: 100%;
    width: 100%;
    justify-content: center;
    border-radius: 12px;
    background: linear-gradient(292deg, #57ABFF 0%, #CD98FF 100%);
    box-shadow: 0px 2px 8px -2px rgba(87, 170, 255, 0.35);
}

.btn-flex.Choose-from-album {
    margin: 10px 0px;
    width: 100%;
}

.btn-flex.Choose-from-album .dash-btn.lib {
    width: 100%;
    background: #cc0fe0;
}

.select-tract-box {
    width: 100%;
}

.explore-card1.explore-card1-new .img-card img {
    width: 90px !important;
    height: 90px !important;
}

.select-tract-box-right {
    width: 170px;
    height: 170px;
}

.explore.dashboard.playlist .explore-card1 {
    height: auto !important;
}

.explore.dashboard.playlist .card-participate label {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.explore.dashboard.playlist .card-participate p {
    margin: 0px;
    font-size: 11px;
    margin-top: 5px;
}

.explore.dashboard.playlist .artist .artist-txt {
    padding: 0px 10px !important;
    width: 100%;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root .MuiCardContent-root {
    padding-top: 10px;
}

.token_creator.token_creator-inner {
    padding: 10px;
}

.token_creator.token_creator-inner h6 {
    margin-bottom: 5px;
}

.token_creator.token_creator-inner .user_profile.counts {
    margin: 10px 0;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root.artist_top_cards .explore-content-heading {
    padding-top: 0px;
    font-size: 25px;
    margin-bottom: 15px;
}

.explore.dashboard.playlist .explore-box2 .MuiGrid-root.artist_top_cards .artist_top_cards-grid {
    padding: 0px !important;
    padding-top: 5px !important;
}

.your-top-mix-classname .explore-content-heading {
    padding-top: 0px;
    font-size: 25px;
}

.explore.dashboard.playlist .MuiGrid-root.dash-spc.your-top-mix-classname {
    padding: 0px !important;
}

.dash-spc-contain-mid-part {
    position: relative;
}

.addbutton {
    position: absolute;
    left: 50%;
    margin-left: -74px;
    top: 50%;
    margin-top: -50px;
    /* z-index: -1; */
}

.dash-spc-left,
.dash-spc-right {
    position: relative;
    z-index: 9;
}

.your-top-mix-classname .card-title p:last-child {
    font-size: 8px !important;
}

.your-top-mix-classname .card-body.card-body--card .toggle-icon img {
    width: 15px;
}

.your-top-mix-classname .card-body.card-body--card {
    margin-left: 0px;
}

.your-top-mix-classname .card-body.card-body-title-card {
    margin-left: 0px;
}

.your-top-mix-classname .card-title p {
    margin-top: 0px !important;
}

.your-top-mix-classname .join-btn a {
    font-size: 12px !important;
}

.your-top-mix-classname .join-btn .join-icon img {
    width: 14px;
}

.creatorAvatar {
    display: none;
}

.explore.dashboard.playlist .explore-box2 .MuiContainer-root {
    max-width: 100% !important;
    padding-right: 15px !important;
}

.explore.dashboard.playlist .artist .artist-txt .love-txt1 {
    font-size: 10.041px;
}

.explore.dashboard.playlist .btn.artist-follow {
    font-size: 8.525px;
}

.explore.dashboard.playlist .artist .artist-txt .love-txt2 {
    font-size: 10.032px;
    margin-top: 4px;
}

@media (max-width: 767.98px) {
    .token_creator {
        left: 0 !important;
    }

    .artist_top_cards {
        display: inline !important;
    }

    .explore.dashboard .artist {
        max-width: 97% !important;
        margin-bottom: 10px !important;
    }

    .explore-box {
        margin-bottom: 0 !important;
    }

    .dashboard main.explore-box2.MuiBox-root {
        padding: 24px !important;
        padding-left: 13px !important;
    }

    .dashboard .btn-flex {
        flex-direction: column;
    }

    .dashboard .explore-card1 {
        height: auto;
    }

    .dashboard .explore-box2 .whole-flex-spc.MuiGrid-root {
        padding: 0 !important;
    }

    .dashboard .explore-box2 .MuiGrid-root {
        padding-left: 0 !important;
    }

    .creator_card {
        width: auto !important;
    }

    .explore.dashboard .artist {
        padding-left: 10px !important;
    }

    .love-playlist {
        flex-direction: column;
    }


    .explore.dashboard.playlist .MuiStack-root.select-tract-box {
        flex-direction: column;
        align-items: center;
    }

    .explore.dashboard.playlist .MuiStack-root.select-tract-box .MuiCardContent-root.select-tract-box-left {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .addbutton {
        position: relative;
        left: 0;
        margin: 0px !important;
        text-align: center;
    }

    .creatorAvatar {
        display: block !important;
    }

    .creator_card::before {
        display: none;
    }

    .explore.dashboard.playlist .token_creator {
        position: relative;
        top: 0;
        right: 0px !important;
        width: 100%;
        max-width: 100%;
    }

    .explore.dashboard.playlist .buy {
        position: relative;
        margin: 0px;
        bottom: 0px;
        width: 100%;
        right: 0px;
        margin-top: 20px;
    }

    .explore.dashboard.playlist .creator_card.creator_card-inner {
        height: auto;
    }

    .creatorAvatar img {
        width: 100%;
    }

    .creatorAvatar {
        margin-top: 20px;
    }

    .explore.dashboard.playlist .explore-box2 .MuiGrid-root {
        align-items: flex-start !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .explore.dashboard.playlist .explore-box2 .MuiGrid-root {
        max-width: 100%;
        justify-content: center !important;
        margin: 0 auto !important;
    }


    .explore.dashboard.playlist .MuiStack-root.select-tract-box {
        flex-direction: column;
        align-items: center;
    }

    .explore.dashboard.playlist .MuiStack-root.select-tract-box .MuiCardContent-root.select-tract-box-left {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .addbutton {
        position: relative;
        left: 0;
        margin: 0px !important;
        text-align: center;
    }

    .creatorAvatar {
        display: block !important;
    }

    .creator_card::before {
        display: none;
    }

    .explore.dashboard.playlist .token_creator {
        position: relative;
        top: 0;
        right: 0px !important;
        width: 100%;
        max-width: 100%;
    }

    .explore.dashboard.playlist .buy {
        position: relative;
        margin: 0px;
        bottom: 0px;
        width: 100%;
        right: 0px;
        margin-top: 20px;
    }

    .explore.dashboard.playlist .creator_card.creator_card-inner {
        height: auto;
    }

    .creatorAvatar img {
        width: 100%;
    }

    .creatorAvatar {
        margin-top: 20px;
    }

    .explore.dashboard.playlist .explore-box2 .MuiGrid-root {
        align-items: flex-start !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .creatorAvatar img {
        height: 250px;
        width: auto;
    }

    .top-playlist-items-list {
        width: 100%;
    }

    .creatorAvatar {
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .explore.dashboard.playlist .explore-box2 .MuiGrid-root {
        max-width: 100%;
        justify-content: center !important;
        margin: 0 auto !important;
    }

    .top-playlist-items-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: space-between;
    }

    .top-playlist-items-list>.MuiGrid-root {
        max-width: 50% !important;
    }

    .top-playlist-items-list .MuiGrid-root.MuiGrid-item {
        max-width: 50% !important;
        width: 50% !important;
    }

    .explore.dashboard.playlist .explore-box2 .top-playlist-items-list .MuiGrid-root {
        max-width: 45% !important;
    }

    .addbutton {
        position: relative;
        left: 0;
        margin: 0px !important;
        text-align: center;
    }

    .explore.dashboard.playlist .buy {
        width: 30%;
    }
}

@media (min-width: 1200px) and (max-width: 1459.98px) {
    .creator_card {
        width: 423px;
    }

    .token_creator {
        position: relative;
        left: 40%;
        top: -17px;
    }



    .explore.dashboard.playlist .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.dash-spc-contain.dash-spc-contain-mid-part {
        flex-direction: column;
    }

    .explore.dashboard.playlist .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.dash-spc-contain.dash-spc-contain-mid-part>div {
        max-width: 100% !important;
        flex-basis: 100% !important;
        width: 100%;
    }

    .explore.dashboard.playlist .top-playlist-items-list .Card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* white-space: nowrap; */
        overflow: hidden;
    }

    .explore.dashboard.playlist .top-playlist-items-list .Card .btn.artist-follow {
        width: 100%;
        left: 0;
    }

    .explore.dashboard.playlist .top-playlist-items-list .Card .love-txt1 {
        white-space: nowrap;
        word-break: break-all;
        word-wrap: break-word;
    }

    .addbutton {
        position: relative;
        left: 0;
        margin-left: 0;
        top: 0;
        margin-top: 0;
        text-align: center;
    }

    .creatorAvatar {
        display: block !important;
    }

    .creator_card::before {
        display: none;
    }

    .explore.dashboard.playlist .creator_card.creator_card-inner {
        height: auto;
    }

    .creatorAvatar img {
        height: 240px;
    }

    .creatorAvatar {
        text-align: center;
    }

    .explore.dashboard.playlist .token_creator {
        position: relative;
        right: 0px !important;
        top: 0;
        left: auto;
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .explore.dashboard.playlist .buy {
        right: 0;
        position: relative;
        width: 100%;
        right: 0;
        bottom: 0;
        left: auto;
        margin-bottom: 0;
    }
}