/* Container for the fixed bottom player */
.fixed-bottom-player {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--clr-bcg);
    padding: 16px;

    z-index: 2000;
    /* Ensures it's on top of other content */
}

.inner-fixed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 80%; */
    gap: 20px;
    margin: auto;
}

/* Player content styles */
.fixed-bottom-player .player-content {
    display: flex;
    align-items: center;
}

/* Player controls styles */
.fixed-bottom-player .player-controls {
    display: flex;
    align-items: center;
}

/* Progress bar container */
.fixed-bottom-player .progress-container {
    display: flex;
    align-items: center;
    width: 200px;
}

/* Progress bar */
.fixed-bottom-player .progress-bar {
    height: 4px;
    background-color: #6a1b9a;
    /* Primary color */
    border-radius: 2px;
    flex-grow: 1;
    margin: 0 8px;
}

.avatar-music {
    position: relative;
    display: inline-block;
}

.avatar-music .MuiAvatar-root {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    border: 2.5px solid #B5179E;
}


.pasue-play {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
}

.pasue-play svg {
    fill: #fff !important;
}

.subtitle1 {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font) !important;
    padding-bottom: 5px;
    text-transform: capitalize;
    display: flex;
    display: inline-block;
    width: 100%;
    /* Inline block for smooth scrolling */
    /* animation: marquee 7s linear infinite; */
    /* Animation properties */
}

.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.marquee-container2 {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}


@keyframes marquee {
    0% {
        transform: translateX(0);
        /* Start at the beginning */
    }

    100% {
        transform: translateX(-100%);
        /* Move left by half the width of the text */
    }
}

.subtitle2 {
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
}

.control-timer .MuiSlider-track {
    background: linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%);
}

.control-timer .MuiSlider-rail {
    background: #464646;
}

.control-timer {
    height: 7px !important;
}

.control-timer .MuiSlider-thumb {
    background-color: #B5179E;
}

.mobile-avatar {
    text-align: center;
}



.mobile-avatar .avatar-music .MuiAvatar-root {
    width: 250px;
    height: 250px;
    border-radius: 20%;
    position: relative;
    z-index: 1;
    border: 2.5px solid #B5179E;
}

.mobile-box {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.repeat-mode {
    fill: green !important;
}

.normal-repeat {
    fill: #ccc !important;
}

.cursor-block svg path {
    cursor: not-allowed !important;
}