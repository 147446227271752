.register-page {
  background: url(../../img/dster-webp/register-bg.webp),
    lightgray 0px -15.429px / 100% 102.857% no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.register-page .inner-box {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 15px -3px rgba(35, 32, 61, 0.1);
  padding: 0px 28px;
  padding-bottom: 60px;
  width: 448px;
  height: 544px;
  overflow-y: scroll;
  outline: unset !important;
}

.inner-popup-pad.register {
  border: unset !important;
}

.meta-google {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
  margin: 20px 0px;
}

.meta-google button {
  border-radius: 10px;
  /* border: 1px solid #000; */
  padding: 5px 5px;
  width: 125px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.fan-art svg {
  fill: #000 !important;
}

.text-center {
  text-align: center;
  padding: 5px 0px;
}