.track {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0;
  z-index: 999;
}

.audios {
  cursor: pointer;
}

.music-track-grid {
  display: flex;
  justify-content: center;
  background: var(--clr-music-player);
  padding: 0px 50px;
  align-items: center;
}


.music-stack {
  justify-content: center;
  align-content: center;
  align-items: center;
  /* padding: 20px; */
}

.track span.MuiLinearProgress-bar {
  background-color: var(--clr-music-track);
}

.track-time {
  display: flex;
  justify-content: center;
  color: var(--clr-track-time);
  /* font-family: Arial; */
  font-size: 12px;
  /* font-style: normal; */
  font-weight: 700;
  gap: 20px;
  line-height: 12px;
  /* 120% */
}

.track-time span.MuiSlider-root {
  color: var(--clr-music-track);
}

.track .MuiGrid-root.MuiGrid-container,
.track .MuiGrid-root {
  margin: 0px !important;
  border: none !important;
}



.slider-class span.MuiSlider-track,
.slider-class span.MuiSlider-rail {
  height: 8px;
  color: var(--clr-music-track);

}

.slider-class {
  padding: 0px !important;
}

.audio-timmer {
  display: flex;
  justify-content: space-between;
}

.slider-class span.MuiSlider-thumb {
  display: none;
}

.music-stack .mui-svg {
  font-size: 22px !important;
  color: var(--clr-music-track) !important;
}

.slider-class {
  height: 10px !important;
}

.track-timer {
  height: 6px !important;
}