.divalbum-cover-icon,
.frame-child {
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    /* position: absolute; */
}

/* .frame-child {
    background-image: url('../../img/vrmeet-bg.png');
    background-repeat: no-repeat;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
} */

.divalbum-cover-icon {
    flex-shrink: 0;
    z-index: 1;
    object-fit: contain;
    left: -43px;
    top: 38px;
    transform: scale(1.512);
}

.wrapper-divalbum-cover {
    height: 246px;
    width: 246px;
    position: relative;
    border-radius: 15px;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .frame-parent,
.main {
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
} */

.frame-parent {
    width: 1590px;
    align-items: center;
    justify-content: space-between;
    padding: 32px 132px;
    gap: 20px;
    max-width: 100%;
}

/* .main {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 47px 20px 357px;
    letter-spacing: normal;
} */

@media screen and (max-width: 1525px) {
    .frame-parent {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1225px) {
    .frame-parent {
        padding-left: 66px;
        padding-right: 66px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 850px) {
    .frame-parent {
        padding-left: 33px;
        padding-right: 33px;
        box-sizing: border-box;
    }
}

.vr-inner img {
    width: 150px;
    height: 150px;
    margin-top: 70px;
    border-radius: 15px;
}

.colab-chat-area.vr {
    height: 400px;
    overflow-y: scroll;
    margin: 0px;
    background: url("../../img/dster-webp/vr-chat-bg.webp");
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: contain; */
}

.colab-avatar-cont.vr {
    background: #fff !important;
    border-radius: 15px 15px 0px 0px;
}

.chat-area-inner-text.vr,
.chat-area-inner-text.user.vr {
    margin: 15px 20px !important;
}


.virtualmeet-music-main {
    background: var(--clr-bcg);
    border-radius: 15px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.vr-music-track {
    width: 100%;
}

.slider-class2 span.MuiSlider-track,
.slider-class2 span.MuiSlider-rail,
.slider-class2 span {
    color: var(--clr-music-track);
}

.vrmeet-bg img {
    position: absolute;
    z-index: -1;
    height: 85%;
    width: 100%;
    border-radius: 15px;
}